import { styled } from '@mui/material/styles';
import { colors } from 'styles/globalStyles';

export const ModalContent = styled('div')({
  margin: '25px 20px 40px 20px',
});

export const ActionsBar = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  gap: '20px',
  padding: '14px 16px',
  backgroundColor: colors.backgroundGray,
});
