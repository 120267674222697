import { Select as MuiSelect, FormControl } from '@mui/material';
import { styled } from '@mui/material/styles';

import { colors, fieldsWidthValues } from 'styles/globalStyles';
import { WidthType } from 'types';

interface StyledFormControlProps {
  width: WidthType,
  styles?: {
    width?: string,
  }
}

export const StyledFormControl = styled(FormControl)(({ width, styles }: StyledFormControlProps) => ({
  '&': {
    width: fieldsWidthValues[width],
    ...styles,
  },
}));

export const StyledSelect = styled(MuiSelect)({
  '&': {
    width: '100%',
  },
  '& .MuiInputBase-input': {
    padding: '8px 12px',
    fontSize: '14px',
    lineHeight: 'normal',
    textAlign: 'left',
  },
  '& .MuiInputBase-input:focus': {
    backgroundColor: colors.white,
  },
  '&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
    border: '1px solid',
    borderColor: colors.primaryBlue,
  },
  '&.Mui-error fieldset.MuiOutlinedInput-notchedOutline': {
    border: '1px solid',
    borderColor: colors.hoveredTextRed,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: '1px solid',
    borderColor: colors.borderGray,
  },
  '&.Mui-disabled fieldset': {
    borderColor: colors.disabledBorderGray,
  },
  '&.Mui-disabled .MuiInputBase-input': {
    backgroundColor: colors.inputDisabledGray,
    WebkitTextFillColor: colors.black,
  },
  '& svg': {
    zIndex: 1,
  },
});
