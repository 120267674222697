import { useParams } from 'react-router-dom';

import { ICompensationGridDetails } from 'interfaces/CompensationGrid/ICompensationGridDetails';

import { PageContainer, PageHeader, ActionsBar } from 'components/base';
import {
  TextButton,
  CustomIcon,
  OutlinedButton,
} from 'components/UIComponents';

import useData from 'hooks/useData';
import useAuth from 'hooks/useAuth';

import { CompensationGridAPI } from 'api/CompensationGridAPI';

import {
  engagementTypeOptions,
  clinicianTitleOptions,
  contractTypeOptions,
} from 'constants/compensationGridsConstants';
import { IContractedHour } from 'interfaces/CompensationGrid/IContractedHour';
import CompensationGridInfo from './CompensationGridInfo';
import CompensationScalesInfo from './CompensationScaleInfo';
import CompensationTermsInfo from './CompensationTermsInfo';
import CallTermsInfo from './CallTermsInfo';
import StipendsInfo from './StipendsInfo';
import AdditionalCompensationsInfo from './AdditionalCompensationsInfo';
import { IFacility } from 'interfaces/Facility/IFacility';

const CompensationGridDetails = () => {
  const { user } = useAuth();
  const editAllowed = user?.allowed_write_resources.includes('compensation_grids');
  const { compensationGridId } = useParams();
  const contractedHours = useData(
    CompensationGridAPI.getContractedHours,
    'all'
  ) as IContractedHour[];

  const compensationGrid = useData(
    CompensationGridAPI.getDetails,
    Number(compensationGridId)
  ) as ICompensationGridDetails;

  if (!compensationGrid) return null;

  const facilityName = compensationGrid.facility?.name;
  const clinicianTitle = clinicianTitleOptions.find(
    (option) => option.id === compensationGrid.clinician_title
  )?.name;
  const contractType = contractTypeOptions.find(
    (option) => option.id === compensationGrid.contract_type
  )?.name;
  const engagement = engagementTypeOptions.find(
    (option) => option.id === compensationGrid.engagement
  )?.name;

  const pageTitle = `${facilityName}, ${clinicianTitle}, ${contractType}, ${engagement}`;
  return (
    <PageContainer style={{ marginBottom: '66px' }}>
      <PageHeader pageTitle={pageTitle}>
        {editAllowed &&
          <TextButton
            color="blue"
            href={`${compensationGridId}/edit?redirectTo=/compensation_grids/${compensationGridId}`}
          >
            <CustomIcon size='normal' name='edit'/>
            Edit
          </TextButton>
        }
      </PageHeader>

      <CompensationGridInfo
        facilityName={facilityName}
        clinicianTitle={clinicianTitle}
        contractType={contractType}
        engagement={engagement}
      />
      {contractedHours &&
        <CompensationScalesInfo
          compensationGrid={compensationGrid}
          contractedHours={contractedHours}
        />
      }

      <CompensationTermsInfo
        compensationGrid={compensationGrid}
        facility={compensationGrid.facility as IFacility}
      />

      <CallTermsInfo compensationGrid={compensationGrid} />

      <StipendsInfo compensationGrid={compensationGrid} />

      <AdditionalCompensationsInfo compensationGrid={compensationGrid} />

      <ActionsBar>
        <OutlinedButton href="/compensation_grids">Back</OutlinedButton>
      </ActionsBar>
    </PageContainer>
  );
};

export default CompensationGridDetails;
