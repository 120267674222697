import { ICompensationGridDetails } from 'interfaces/CompensationGrid/ICompensationGridDetails';
import { IContractedHour } from 'interfaces/CompensationGrid/IContractedHour';

import { SectionContainer, DetailsContainer, DetailsWrapper } from 'components/base';

import { rateTypeOptions } from 'constants/compensationGridsConstants';

import CompensationRatesInfo from 'containers/CompensationGrids/CompensationGridDetails/CompensationRatesInfo';

import { CompScaleWrapper, SectionName } from '../styledComponents';

interface ICompensationScaleInfo {
  compensationGrid: ICompensationGridDetails;
  contractedHours: IContractedHour[];
}

const CompensationScalesInfo = ({ compensationGrid, contractedHours }: ICompensationScaleInfo) => {

  return (
    <SectionContainer title="Comp Scale">
      <CompScaleWrapper>
        {compensationGrid.compensation_scales.map((scale, index) => (
          <DetailsWrapper key={index}>
            {
              compensationGrid.engagement === 'full_time' &&
              <SectionName>
                {scale.staff_category}
              </SectionName>
            }
            <DetailsContainer
              name="rate_type"
              label="Rate Type"
              skipPermissions
              width="wide"
            >
              {
                rateTypeOptions.find((option) => option.id === scale.rate_type)?.name
              }
            </DetailsContainer>
            <DetailsContainer
              name="contracted_hour_title"
              label="Contracted Hours"
              skipPermissions
              width="wide"
            >
              {scale.contracted_hour_title}
            </DetailsContainer>
            {compensationGrid.contract_type === 'pc' && compensationGrid.engagement === 'full_time' && (
              <DetailsContainer
                name="weeks_of_service"
                label="Weeks of Service"
                skipPermissions
                width="wide"
              >
                {scale.weeks_of_service}
              </DetailsContainer>
            )}
            {scale.rate_type === 'tiered' && (
              <DetailsContainer
                name="step_increase"
                label="Step Increase"
                skipPermissions
                width="wide"
              >
                {scale.step_increase}
              </DetailsContainer>
            )}
            <CompensationRatesInfo
              scale={scale}
              compensationGrid={compensationGrid}
              contractedHours={contractedHours}
              width='wide'
              paddingTop='4px'
            />
          </DetailsWrapper>
        ))}
      </CompScaleWrapper>
    </SectionContainer>
  );
};

export default CompensationScalesInfo;
