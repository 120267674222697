import { useLayoutEffect, useRef, useCallback, useState } from 'react';

const DATA_LEFT_TRIGGER_TO_LOAD = 0.25;

const useInfiniteScroll = (loadMoreData: () => void) => {
  const scrollElementRef = useRef<HTMLTableSectionElement>(null);

  const [triggerHeight, setDistanceBottom] = useState(0);

  const scrollListener = useCallback(() => {
    if (scrollElementRef?.current) {
      const currentElement = scrollElementRef.current;
      let bottom = currentElement.scrollHeight - currentElement.clientHeight; // End of scrollable container

      if (!triggerHeight) {
        setDistanceBottom(Math.round(bottom * DATA_LEFT_TRIGGER_TO_LOAD));  // Height equivalent to 25% of items
      }
      if (currentElement.scrollTop > bottom - triggerHeight) {
        loadMoreData();
      }
    }
  }, [loadMoreData, triggerHeight]);

  useLayoutEffect(() => {
    const currentElement = scrollElementRef.current;
    if (currentElement) {
      currentElement.addEventListener('scroll', scrollListener);
      return () => {
        currentElement.removeEventListener('scroll', scrollListener);
      };
    }
  }, [scrollListener]);
  return [scrollElementRef];
};


export default useInfiniteScroll;
