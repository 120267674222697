import { Select as MuiSelect, FormControl } from '@mui/material';
import { styled } from '@mui/material/styles';
import { colors } from 'styles/globalStyles';

export const SectionWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  gap: '16px',
  marginBottom: '16px',
});

export const StyledFormControl = styled(FormControl)(() => ({
  '&': {
    width: '100%',
  },
}));

export const StyledSelect = styled(MuiSelect)({
  '&': {
    width: '100%',
  },
  '& .MuiInputBase-input': {
    padding: '8px 12px',
    fontSize: '14px',
    lineHeight: 'normal',
    textAlign: 'left',
  },
  '& .MuiInputBase-input:focus': {
    backgroundColor: colors.white,
  },
  '&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
    border: '1px solid',
    borderColor: colors.primaryBlue,
  },
  '&.Mui-error fieldset.MuiOutlinedInput-notchedOutline': {
    border: '1px solid',
    borderColor: colors.hoveredTextRed,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: '1px solid',
    borderColor: colors.borderGray,
  },
  '&.Mui-disabled fieldset': {
    borderColor: colors.disabledBorderGray,
  },
  '&.Mui-disabled .MuiInputBase-input': {
    backgroundColor: colors.inputDisabledGray,
    WebkitTextFillColor: colors.black,
  },
  '& svg': {
    zIndex: 1,
  },
});

export const OptionWrapper = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});
