import { ICompensationGridDetails } from 'interfaces/CompensationGrid/ICompensationGridDetails';

import {
  SectionContainer,
  DetailsContainer,
  DetailsWrapper,
} from 'components/base';

import { IFacility } from 'interfaces/Facility/IFacility';

import formatCurrency from 'helpers/formatCurrency';
import { compensationTerms } from 'constants/compensationGridsConstants';
import ItalicContainer from 'components/base/ItalicContainer';

interface ICompensationTermsInfo {
  compensationGrid: ICompensationGridDetails;
  facility: IFacility;
}

const CompensationTermsInfo = ({
  compensationGrid,
  facility,
}: ICompensationTermsInfo) => {
  return (
    <SectionContainer title="Comp Terms">
      <DetailsWrapper>
        {compensationTerms
          .filter(compensationTerm => [compensationGrid.engagement, 'both'].includes(compensationTerm.showOn))
          .map((compensationTerm, index) => {
            if (['limit_per_event', 'limit_yearly'].includes(compensationTerm.fieldName)) {
              return (
                <DetailsContainer
                  key={compensationTerm.fieldName}
                  name={compensationTerm.fieldName}
                  label={compensationTerm.title}
                  skipPermissions
                  width="wide"
                >
                  {formatCurrency(facility?.[(compensationTerm.fieldName as 'limit_per_event' | 'limit_yearly')], true)}
                </DetailsContainer>
              );
            } else {
              return (
                <div key={compensationTerm.fieldName}>
                  <DetailsContainer
                    name={compensationTerm.fieldName}
                    label={compensationTerm.title}
                    skipPermissions
                    width="wide"
                  >
                    {compensationGrid[compensationTerm.fieldName as keyof ICompensationGridDetails] as string}
                  </DetailsContainer>
                  {compensationGrid[`${compensationTerm.fieldName}_notes` as keyof ICompensationGridDetails] && (
                    <ItalicContainer
                      name={`${compensationTerm.fieldName}_notes`}
                      skipPermissions
                      width="wide"
                    >
                      {compensationGrid[`${compensationTerm.fieldName}_notes` as keyof ICompensationGridDetails] as string}
                    </ItalicContainer>
                  )}
                </div>
              );
            }
          })
        }
      </DetailsWrapper>
    </SectionContainer>
  );
};

export default CompensationTermsInfo;
