import { styled } from '@mui/material/styles';

export const SectionTitle = styled('div')({
  marginBottom: '4px',
  fontWeight: '600',
});

export const ContentWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
});
