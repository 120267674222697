import { styled } from '@mui/material/styles';
import { colors } from 'styles/globalStyles';

interface Props {
  type: 'amendmentForm' | 'PerDiemContractDetails' | 'FullTimeContractDetails'
};

const typeDependentStyles = {
  oldValueWidth: {
    'amendmentForm': '280px',
    'PerDiemContractDetails': '280px',
    'FullTimeContractDetails': '110px',
  },

  propertyNameWidth: {
    'amendmentForm': '165px',
    'PerDiemContractDetails': '320px',
    'FullTimeContractDetails': '320px',
  },
};

export const StyledDetailsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
});

export const PropertyName = styled('div')(({ type }: Props) => ({
  minWidth: typeDependentStyles.propertyNameWidth[type],
  fontSize: '14px',
  color: colors.textGray,
}));

export const NewValue = styled('div')(({ type }: Props) => ({
  fontSize: '14px',
  color: colors.black,
  display: 'flex',
  width: `calc(100% - ${typeDependentStyles.oldValueWidth[type]} - ${typeDependentStyles.propertyNameWidth[type]})`,
}));

export const OldValue = styled('div')(({ type }: Props) => ({
  fontSize: '14px',
  width: typeDependentStyles.oldValueWidth[type],
  marginRight: type === 'amendmentForm' ? '65px' : '10px',
  color: colors.disabledGray,
  textDecoration: 'line-through',
  display: 'flex',
}));
