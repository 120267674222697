import { useContext } from 'react';
import { Modal } from 'components/base';

import { ModalContext } from 'context/ModalContext';
import { confirmModalTitle } from 'constants/contractsConstants';
import { OutlinedButton, SubmitButton } from 'components/UIComponents';
import { ModalContent, ActionsBar } from './styledComponents';
import { IClinicianContractForm } from 'interfaces/ClinicianContract/IClinicianContractForm';

interface Props {
  fieldProps: {
    fieldName: string | number,
    prevValue: string | number,
  },
  values: IClinicianContractForm
  setFieldValue: any,
  resetFormCallback: any
  setFieldTouched: any,
  setValues: any,
};

const ConfirmModal = ({ fieldProps, values, setFieldValue, setFieldTouched, setValues, resetFormCallback }: Props) => {
  const { onModalClose } = useContext(ModalContext);
  const {fieldName, prevValue} = fieldProps;

  const closeModal = () => {
    setFieldValue(fieldName, prevValue);
    onModalClose(confirmModalTitle);
  };

  const applyChanges = () => {
    resetFormCallback(values, fieldName, setFieldTouched, setFieldValue, setValues);
  };

  return(
    <Modal
      title={confirmModalTitle}
      onModalClose={closeModal}
    >
      <ModalContent>
        We'll need to look for another comp grid. The payroll details will be cleaned up.
      </ModalContent>
      <ActionsBar>
        <OutlinedButton onClick={closeModal}>
          Cancel
        </OutlinedButton>
        <SubmitButton onClick={applyChanges}>
          Proceed
        </SubmitButton>
      </ActionsBar>
    </Modal>
  );
};

export default ConfirmModal;
