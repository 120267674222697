import { useCallback, useEffect, useMemo, useState } from 'react';
import { useField } from 'formik';
import { useDebouncedCallback } from 'use-debounce';
import { Editable, withReact, Slate, ReactEditor, RenderElementProps, RenderLeafProps } from 'slate-react';
import { createEditor, Transforms, Editor } from 'slate';
import Toolbar from './Toolbar';
import Leaf from './Leaf';
import Element from './Element';
import { transfromFromHtml, transformToHtml } from './Serializer';
import { EditorWrapper } from './styledComponents';
import { initialMemo } from 'constants/contractsConstants';

interface Props {
  name: string;
}

const DEBOUNCE_DELAY = 200;

const TextEditor = ({ name }: Props) => {
  const [field, meta, { setValue }] = useField(name);
  const [visibleValue, setVisibleValue] = useState(field.value || '<p></p>');
  const [initialized, setInitialized] = useState(false);

  const renderElement = useCallback(
    (props: RenderElementProps) => <Element {...props} />,
    []
  );
  const renderLeaf = useCallback(
    (props: RenderLeafProps) => <Leaf {...props} />,
    []
  );
  const editor = useMemo<ReactEditor>(
    () => withReact(createEditor()),
    []
  );

  useEffect(() => {
    Transforms.select(editor, Editor.start(editor, []));
  }, [editor]);

  const debouncedChange = useDebouncedCallback(
    (value) => {
      setValue(value);
    },
    DEBOUNCE_DELAY
  );

  useEffect(() => {
    if (field.value && field.value !== visibleValue) {
      editor.children = transfromFromHtml(field.value);
    }
  }, [field.value]);

  useEffect(() => {
    if (!initialized && field.value !== initialMemo && field.value !== '') {
      setVisibleValue(transformToHtml(field.value));
      debouncedChange(field.value);
      setInitialized(true);
    };
  }, [field.value]);

  return (
    <EditorWrapper>
      <Slate
        editor={editor}
        initialValue={transfromFromHtml(visibleValue)}
        onChange={(value) => {
          setVisibleValue(transformToHtml(value));
          debouncedChange(transformToHtml(value));
        }}
      >
        <Toolbar />
        <Editable
          id={name}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          spellCheck
          style={{outline: 'none', padding: '10px 0'}}
        />
      </Slate>
    </EditorWrapper>
  );
};

export default TextEditor;
