import iconsMap from './iconsMap';


type IconSize = 'x-small' | 'small' | 'normal' | 'x-normal' | 'big' | 'large'; //Add more sizes

interface Props {
  name: string,
  size?: IconSize,
  color?: string,
  hoverColor?: string
}

const sizes = {
  'x-small': {
    width: 11,
    height: 11,
  },
  'small': {
    width: 14,
    height: 14,
  },
  'normal': {
    width: 16,
    height: 16,
  },
  'x-normal': {
    width: 18,
    height: 18,
  },
  'big': {
    width: 22,
    height: 22,
  },
  'large': {
    width: 24,
    height: 24,
  },
};

// NOTE: If you want to use color argument, make sure that your svg file uses currentColor argument. (i.e. `Search` icon)

const CustomIcon = ({ name, color = 'inherit', size = 'small', hoverColor = color }: Props) => {
  const Icon = iconsMap[name];

  return (
    <Icon
      className='svg-icon'
      data-testid={`${name}-img`}
      style={{ color: color, '--hover-color': hoverColor }}
      width={sizes[size].width}
      height={sizes[size].height}
    />
  );
};

export default CustomIcon;
