const filterObjectKeys = (object: any, allowedFieldsList: Array<string>) => {
  if (!allowedFieldsList) return object;

  return Object.keys(object)
    .filter(key => allowedFieldsList.includes(key))
    .reduce((result: any, key: string) => {
      result[key] = object[key];
      return result;
    }, {});
};

export default filterObjectKeys;
