import { ReactNode, createContext, useMemo, useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import { IAuthUserData } from 'interfaces/User/IAuthUserData';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { AuthAPI } from 'api/AuthAPI';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from 'authConfig';


interface IAuthContextProps {
  user: IAuthUserData | null;
  login: () => void;
  logout: () => void;
}

export const AuthContext = createContext<IAuthContextProps>({
  user: null,
  login: () => {},
  logout: () => {},
});

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const { instance } = useMsal();

  const [token, setToken] = useLocalStorage('accessToken', null);
  const [user, setUser] = useState<IAuthUserData | null>(token ? jwtDecode(token) : null);

  const login = async () => {
    await instance.initialize();
    instance.handleRedirectPromise().then((response) => {
      if (response?.accessToken) {
        instance.setActiveAccount(response?.account);
        AuthAPI.login(response.accessToken).then((loginResponse) => {
          setToken(loginResponse.headers.authorization);
          const userData = jwtDecode(loginResponse.headers.authorization) as IAuthUserData;
          setUser({
            ...userData,
          });
        });
      }
    });
    const activeAccount = instance.getActiveAccount();
    if (!activeAccount) {
      await instance.loginRedirect(loginRequest);
    }
    if (activeAccount) {
      instance.acquireTokenSilent({scopes: ['user.read'], account: activeAccount}).then((response) => {
        AuthAPI.login(response.accessToken).then((loginResponse) => {
          setToken(loginResponse.headers.authorization);
          const userData = jwtDecode(loginResponse.headers.authorization) as IAuthUserData;
          setUser({
            ...userData,
          });
        });
      });
    }
  };

  const logout = () => {
    AuthAPI.logout().then(() => {
      setUser(null);
      setToken(null);
      instance.logoutRedirect({ postLogoutRedirectUri: '/' });
    });
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(user)]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
