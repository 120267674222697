import { FieldArray } from 'formik';
import { TableBody, TableHead } from '@mui/material';

import { ICompensationGridDetails } from 'interfaces/CompensationGrid/ICompensationGridDetails';

import { SectionContainer } from 'components/base';
import { BodyTableRow, HeadTableCell, HeadTableRow, NoOverflowBodyTableCell,
  StyledTable } from 'components/base/TableWithLazyLoad/styledComponents';
import { CustomIcon, IconButton, InlineInput, TextButton, Select } from 'components/UIComponents';

import { payMethodOptions } from 'constants/compensationGridsConstants';
import { colors } from 'styles/globalStyles';

interface Props {
  values: ICompensationGridDetails,
}
const AdditionalCompensationsSubform = ({ values }: Props) => {
  return (
    <SectionContainer title='Additional Compensations'>
      <FieldArray name='additional_compensations'>
        {({ remove, push }) => (
          <>
            { values.additional_compensations.length > 0 &&
              <StyledTable>
                <TableHead>
                  <HeadTableRow>
                    <HeadTableCell key='title' style={{width: '168px'}}>
                      Title
                    </HeadTableCell>
                    <HeadTableCell key='amount' style={{width: '72px'}}>
                      Amount
                    </HeadTableCell>
                    <HeadTableCell key='frequency' style={{width: '178px'}}>
                      Frequency
                    </HeadTableCell>
                    <HeadTableCell key='method' style={{width: '81px'}}>
                      Method
                    </HeadTableCell>
                    <HeadTableCell key='notes' style={{width: '191px'}}>
                      Notes
                    </HeadTableCell>
                    <HeadTableCell style={{width: '30px'}}/>
                  </HeadTableRow>
                </TableHead>
                <TableBody>
                  { values.additional_compensations.map((additional_compensation, index) => (
                    <BodyTableRow key={`additional_compensations[${index}]`}>
                      <NoOverflowBodyTableCell>
                        <InlineInput
                          type='text'
                          name={`additional_compensations.${index}.title`}
                        />
                      </NoOverflowBodyTableCell>
                      <NoOverflowBodyTableCell>
                        <InlineInput
                          type='currency'
                          name={`additional_compensations.${index}.amount`}
                        />
                      </NoOverflowBodyTableCell>
                      <NoOverflowBodyTableCell>
                        <InlineInput
                          type='text'
                          name={`additional_compensations.${index}.frequency`}
                          multiline
                        />
                      </NoOverflowBodyTableCell>
                      <NoOverflowBodyTableCell>
                        <Select
                          name={`additional_compensations.${index}.pay_method`}
                          label=''
                          options={payMethodOptions}
                          allowEmpty={false}
                          width='full-width'
                          skipPermissionsCheck
                        />
                      </NoOverflowBodyTableCell>
                      <NoOverflowBodyTableCell>
                        <InlineInput
                          type='text'
                          name={`additional_compensations.${index}.notes`}
                          multiline
                        />
                      </NoOverflowBodyTableCell>
                      <NoOverflowBodyTableCell>
                        <IconButton onClick={() => remove(index)}>
                          <CustomIcon size='normal' color={colors.red} name='trash' />
                        </IconButton>
                      </NoOverflowBodyTableCell>
                    </BodyTableRow>
                  ))}
                </TableBody>
              </StyledTable>
            }
            <div>
              <TextButton color='blue' onClick={() => push({
                title: '',
                amount: '',
                frequency: '',
                pay_method: '',
                notes: '',
              })}>
                + Add Term
              </TextButton>
            </div>
          </>
        )}
      </FieldArray>
    </SectionContainer>
  );
};

export default AdditionalCompensationsSubform;
