import { PageContainer, PageHeader } from 'components/base';
import AmendmentForm from 'containers/ClinicianContracts/Amendments/AmendmentForm';

const NewAmendment = () => {
  return (
    <PageContainer type="normal" style={{marginBottom: '66px'}}>
      <PageHeader pageTitle='Adding Amendment'/>
      <AmendmentForm />
    </PageContainer>
  );
};

export default NewAmendment;
