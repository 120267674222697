import React from 'react';
import { NumberFormatBaseProps, NumberFormatBase } from 'react-number-format';


interface CustomIBANProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const IbanFormatInput = React.forwardRef<NumberFormatBaseProps, CustomIBANProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumberFormatBase
        {...other}
        type="text"
        format={(value) =>
          value
            .replace(/\s+/g, '')
            .replace(/([a-z0-9]{4})/gi, '$1 ')
            .trim()
            .toLocaleUpperCase()
        }
        removeFormatting={(value) => value.replace(/\s+/gi, '')}
        isValidInputCharacter={(char) => /^[a-z0-9]$/i.test(char)}
        getCaretBoundary={(value) =>
          Array(value.length + 1)
            .fill(0)
            .map((v) => true)
        }
        onValueChange={(values, { event }) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        onKeyDown={(e) =>
          !/^(?:[a-z0-9]|Backspace|Delete|Home|End|ArrowLeft|ArrowRight|Shift|CapsLock|Control|NumLock|Tab|Paste|Redo|Undo)$/i.test(
            e.key
          ) && e.preventDefault()
        }
      />
    );
  },
);

export default IbanFormatInput;
