import { useContext } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Breakpoint } from '@mui/material';
import IconButton from '@mui/material/IconButton';

import { ModalContext } from 'context/ModalContext';

import { StyledDialog, StyledDialogTitle, StyledDialogContent } from './styledComponents';

interface ModalProps {
  children: React.ReactNode;
  title: string;
  onModalClose: (id: string) => void;
  size?: Breakpoint | false;
}

const Modal = ({ children, title, onModalClose, size = 'sm' }: ModalProps) => {
  const { modalOpen } = useContext(ModalContext);

  return (
    <StyledDialog maxWidth={size} onClose={() => onModalClose(title)} open={modalOpen[title] || false}>
      <StyledDialogTitle>
        {title}
        <IconButton aria-label="close" color="inherit" onClick={() => onModalClose(title)} sx={{ padding: 0 }}>
          <CloseIcon />
        </IconButton>
      </StyledDialogTitle>
      <StyledDialogContent>
        {children}
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default Modal;
