import { useEffect, useState } from 'react';
import { useField } from 'formik';
import { InputAdornment } from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';
import NumericFormatWithDecimal from 'components/UIComponents/input/TextField/NumericFormatWithDecimal';

import { DisplayContainer, StyledInput, ErrorContainer } from './styledComponents';
import formatCurrency from 'helpers/formatCurrency';

interface InlineInputProps {
  name: string;
  type?: 'currency' | 'text' | 'experience';
  onChange?: any;
  multiline?: boolean;
  defaultValue?: string;
  disabled?: boolean;
};

const DEBOUNCE_DELAY = 200;

const inputProps = {
  'currency': { startAdornment: <InputAdornment position="start">$</InputAdornment>,
    inputComponent: NumericFormatWithDecimal as any },
  'text': undefined,
  'experience': undefined,
};


const InlineInput = ({
  name,
  type = 'text',
  onChange,
  multiline = false,
  defaultValue = undefined,
  disabled = false,
}: InlineInputProps) => {
  const [field, meta, { setValue, setTouched }] = useField(name);
  const [visibleValue, setVisibleValue] = useState(field.value);
  const [showInput, setShowInput] = useState(false);

  useEffect(() => {
    if ((!field.value || field.value === 'n/a') && typeof defaultValue !== 'undefined') {
      setValue(defaultValue);
      setVisibleValue(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    setVisibleValue(field.value);
  }, [field.value]);

  const setDefaultValue = () => {
    if (!field.value && defaultValue) {
      setValue(defaultValue);
      setVisibleValue(defaultValue);
    }
  };

  const debouncedChange = useDebouncedCallback(
    (value) => {
      setValue(value);
    },
    DEBOUNCE_DELAY
  );

  const handleKeyClick = (event: any) => {
    if (event.key === 'Enter') {
      setShowInput(false);
    }
  };

  const displayValue = () => {
    switch(type) {
    case 'currency':
      return formatCurrency(field.value);
    case 'experience':
      return field.value !== '' ? `${field.value}+` : '';
    default:
      return field.value;
    };
  };

  return(
    <>
      {
        showInput ?
          <StyledInput
            fullWidth
            multiline={multiline}
            {...field}
            inputRef={input => input && input.focus()}
            value={(visibleValue || visibleValue === 0) ? visibleValue : ''}
            InputProps={inputProps[type]}
            disabled={disabled}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onChange?.(e.target.value);
              switch(type) {
              case 'experience':
                if (/^[0-9]{0,2}$/.test(e.target.value) || e.target.value === '') {
                  setVisibleValue(e.target.value);
                  debouncedChange(e.target.value);
                };
                break;
              default:
                setVisibleValue(e.target.value);
                debouncedChange(e.target.value);
              }
            }}
            onBlur={() => {
              setTouched(true);
              setShowInput(false);
              setDefaultValue();
            }}
            onKeyDown={handleKeyClick}
          />
          :
          (meta.touched && meta.error ?
            <ErrorContainer onClick={() => setShowInput(true)}>{meta.error}</ErrorContainer> :
            <DisplayContainer disabled={disabled} onClick={() => !disabled && setShowInput(true)}>{displayValue()}</DisplayContainer>
          )
      }
    </>
  );
};

export default InlineInput;
