import { IClinicianContractForm } from 'interfaces/ClinicianContract/IClinicianContractForm';

const prepareClinicianContractFormData = (values: IClinicianContractForm) => {
  let data: any = {
    ...values,
    ...values.payroll_details,
  };

  delete data.payroll_details;
  delete data.compensation_grid_name;
  delete data.experience;
  delete data.grad_date;
  delete data.amendment_notes;
  data.effective_date = data.effective_date.format('YYYY-MM-DD');
  data.execution_date = data.execution_date ? data.execution_date.format('YYYY-MM-DD') : '';
  data.resign_date = data.resign_date ? data.resign_date.format('YYYY-MM-DD') : '';
  data.last_day_of_service = data.last_day_of_service ? data.last_day_of_service.format('YYYY-MM-DD') : '';
  return data;
};

export default prepareClinicianContractFormData;
