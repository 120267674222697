import { useContext } from 'react';
import { DetailsContainer, DetailsWrapper, Modal } from 'components/base';
import { TextButton } from 'components/UIComponents';
import CompensationRatesInfo from 'containers/CompensationGrids/CompensationGridDetails/CompensationRatesInfo';

import { ModalContext } from 'context/ModalContext';
import { selectStaffCategoryModalTitle } from 'constants/contractsConstants';

import { ICompensationGridDetails } from 'interfaces/CompensationGrid/ICompensationGridDetails';
import { ICompensationScale } from 'interfaces/CompensationGrid/ICompensationScale';
import { IContractedHour } from 'interfaces/CompensationGrid/IContractedHour';

import { setFieldsValues } from 'helpers/ClinicianConracts/applyGridTerms';

import { SectionName, ScaleContainer, ScalesWrapper, ScaleContainerWrapper } from './styledComponents';
import { IClinicianContractForm } from 'interfaces/ClinicianContract/IClinicianContractForm';

interface Props {
  compensationScales: ICompensationScale[],
  compensationGrid: ICompensationGridDetails,
  contractedHours: IContractedHour[],
  values: IClinicianContractForm,
  setFieldValue: any,
  setSelectedContractedHour: any,
  setCompensationScale: any,
  setSuitableRate: any,
  setGridTermsApplied: any,
}

const SelectStaffCategoryModal = ({
  compensationScales,
  compensationGrid,
  contractedHours,
  values,
  setFieldValue,
  setSelectedContractedHour,
  setCompensationScale,
  setSuitableRate,
  setGridTermsApplied,
}: Props) => {
  const { onModalClose } = useContext(ModalContext);

  const selectCategory = (scale: ICompensationScale) => {
    const contractedHour = contractedHours.find((el: IContractedHour) => el.id === scale.contracted_hour_id) as IContractedHour;
    setSuitableRate(scale.compensation_rates[0]);
    setFieldsValues(
      values,
      contractedHour,
      scale,
      scale.compensation_rates[0],
      compensationGrid.time_off,
      setFieldValue,
      setSelectedContractedHour,
      setCompensationScale,
      setGridTermsApplied
    );
    onModalClose(selectStaffCategoryModalTitle);
  };

  return (
    <Modal
      title={selectStaffCategoryModalTitle}
      onModalClose={onModalClose}
    >
      <ScalesWrapper>
        {
          compensationScales.map((scale, index) => (
            (
              <ScaleContainerWrapper key={index}>
                <ScaleContainer>
                  <SectionName>
                    {scale.staff_category}
                    <TextButton
                      color='blue'
                      onClick={() => {
                        selectCategory(scale);
                      }}
                    >
                      Select
                    </TextButton>
                  </SectionName>
                  <DetailsWrapper>
                    <DetailsContainer
                      name="contracted_hours"
                      label="Contracted Hours"
                      skipPermissions
                      width="normal"
                    >
                      {scale.contracted_hour_title}
                    </DetailsContainer>
                    {compensationGrid.contract_type === 'pc' &&
                      <DetailsContainer
                        name="weeks_of_service"
                        label="Weeks of Service"
                        skipPermissions
                        width="normal"
                      >
                        {scale.weeks_of_service}
                      </DetailsContainer>
                    }
                    <CompensationRatesInfo
                      scale={scale}
                      compensationGrid={compensationGrid}
                      contractedHours={contractedHours}
                      width='normal'
                      paddingTop='13px'
                    />
                  </DetailsWrapper>
                </ScaleContainer>
              </ScaleContainerWrapper>
            )
          ))
        }
      </ScalesWrapper>
    </Modal>
  );
};

export default SelectStaffCategoryModal;
