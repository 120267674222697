import { StyledIconButton } from './styledComponents';

interface IconButtonProps {
  onClick?: Function,
  children: React.ReactNode,
  disabled?: boolean,
  href?: string,
  styles?: {
    root?: React.CSSProperties
    hover?: React.CSSProperties,
  }
}

const IconButton = ({ onClick, children, disabled, href, styles }: IconButtonProps) => {
  return (
    <StyledIconButton {...(href ? { component: 'a', href } : {})} disabled={disabled} styles={styles} onClick={(e) => onClick?.(e)}>
      {children}
    </StyledIconButton>
  );
};

export default IconButton;
