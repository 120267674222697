import { Button, Popover } from '@mui/material';
import { styled } from '@mui/material/styles';
import { colors } from 'styles/globalStyles';

export const StyledSaveSection = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
});

export const StyledTextButton = styled(Button)(() => ({
  '&': {
    color: colors.primaryBlue,
    gap: '5px',
    padding: 0,
  },
  '&:hover': {
    color: colors.hoveredBlue,
    backgroundColor: 'inherit',
    boxShadow: 'none',
    textDecoration: 'underline',
  },
  '&.Mui-disabled': {
    color: colors.disabledGray,
  },
  '&& .MuiTouchRipple-child': {
    backgroundColor: 'inherit',
  },
}));

export const StyledPopover = styled(Popover)(() => ({
  '& .MuiPopover-paper': {
    boxShadow: 'none',
    border: `1px solid ${colors.primaryBlue}`,
    width: '558px',
  },
}));

export const FormWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  gap: '14px',
  margin: '14px',
});
