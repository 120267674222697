import { request } from 'api/instance';

export const PermissionsAPI = {
  getAllowedAttributes: async (model: string) => {
    try {
      const response = await request({
        url: 'v1/permissions/allowed_attributes',
        method: 'GET',
        params: {
          model,
        },
      });
      return response.data.data;
    } catch (error: any) {
      return error?.response;
    };
  },
};
