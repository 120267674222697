import { AppBar } from '@mui/material';
import { styled } from '@mui/material/styles';
import { colors } from 'styles/globalStyles';

export const AuthDiv = styled('div')({
  display: 'flex',
});

export const Username = styled('div')({
  display: 'flex',
  alignSelf: 'center',
  height: '20px',
  padding: '0 16px',
  marginRight: '9px',
  fontSize: '16px',
  textTransform: 'capitalize',
  color: colors.white,
  borderRight: '1px solid',
});

export const LogoImage = styled('img')({
  width: '104px',
});

export const StyledAppBar = styled(AppBar)(() => ({
  '&': {
    height: '50px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    padding: '0 24px 0 30px',
    boxShadow: '0px 2px 7px 0px rgba(0, 77, 161, 0.15)',
  },
}));
