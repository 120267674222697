import { IconButton } from '@mui/material';

import logo from 'assets/images/PAYROLL.svg';

import { colors } from 'styles/globalStyles';
import { CustomIcon } from 'components/UIComponents';
import useAuth from 'hooks/useAuth';

import NavBar from './NavBar';

import { AuthDiv, Username, LogoImage, StyledAppBar } from './styledComponents';

const Header = () => {
  const { user, logout } = useAuth();

  return (
    <StyledAppBar>
      <LogoImage src={logo} alt="Payroll Logo" />
      <NavBar />
      <AuthDiv>
        <Username>{user?.username}</Username>
        <IconButton onClick={logout}>
          <CustomIcon color={colors.white} name="exit" size="normal"/>
        </IconButton>
      </AuthDiv>
    </StyledAppBar>
  );
};

export default Header;
