import { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { AuthenticatedTemplate } from '@azure/msal-react';
import router from 'router';
import { setUpInterceptors } from 'api/setUpInterceptors';
import useAuth from 'hooks/useAuth';

function App() {
  const { login, user } = useAuth();

  setUpInterceptors();

  useEffect(() => {
    login();
  }, []);

  return (
    <AuthenticatedTemplate>
      {user && <RouterProvider router={router} />}
    </AuthenticatedTemplate>
  );
}

export default App;
