import { styled } from '@mui/material/styles';
import { colors } from 'styles/globalStyles';

export const FilterChipsWrapper = styled('div')({
  zIndex: 2,
  // marginBottom: '15px',
  color: colors.primaryBlue,
  fontWeight: 500,
  display: 'flex',
  flexDirection: 'row',
  gap: '10px',
  alignItems: 'center',
  flexWrap: 'wrap',
  marginTop: '12px',
});

export const ValueWrapper = styled('span')({
  color: colors.primaryBlue,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});
