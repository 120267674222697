import { ErrorContainer, TitleContainer, ErrorTextContainer } from './styledComponents';
import { ReactComponent as Search } from 'assets/icons/errors/search.svg';
import { ReactComponent as Alert } from 'assets/icons/errors/alert.svg';
import { ReactComponent as Lock } from 'assets/icons/errors/lock.svg';

interface Props {
  title: string;
  errorText: string;
  iconName: string;
};

const iconsMap: { [key: string]: any } = {
  notFound: Search,
  systemError: Alert,
  forbidden: Lock,
};

const ErrorPage = ({ title, errorText, iconName }: Props) => {
  const Icon = iconsMap[iconName];

  return(
    <ErrorContainer>
      <Icon
        className='svg-icon'
        data-testid={`${iconName}-img`}
      />
      <TitleContainer>
        {title}
      </TitleContainer>
      <ErrorTextContainer>
        {errorText}
      </ErrorTextContainer>
    </ErrorContainer>
  );
};

export default ErrorPage;
