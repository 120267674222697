import { styled } from '@mui/material/styles';
import { colors } from 'styles/globalStyles';

export const StyledDetailsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
});

export const PropertyName = styled('div')(({ width }: { width: 'normal' | 'wide' }) => ({
  minWidth: width === 'normal' ? '184px' : '320px',
  fontSize: '14px',
  color: colors.textGray,
  fontStyle: 'italic',
}));

export const PropertyValue = styled('div')({
  fontSize: '14px',
  color: colors.textGray,
  fontStyle: 'italic',
});