import { Popper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { colors } from 'styles/globalStyles';

export const StyledPopper = styled(Popper)(() => ({
  '&': {
    zIndex: 1,
  },
}));

export const PopperContainer = styled('div')({
  backgroundColor: colors.infoAlertBlue,
  color: colors.primaryBlue,
  padding: '5px 13px',
  borderRadius: '4px',
});
