import { ICompensationGridDetails } from 'interfaces/CompensationGrid/ICompensationGridDetails';

import {
  SectionContainer,
  DetailsContainer,
  DetailsWrapper,
} from 'components/base';

import formatCurrency from 'helpers/formatCurrency';
import ItalicContainer from 'components/base/ItalicContainer';
import { TitleContainter, ListWrapper, TitleWrapper } from '../styledComponents';
import { paidInFullOptions } from 'constants/compensationGridsConstants';
import { TextButtonWithPopover } from 'components/UIComponents';

interface Props {
  compensationGrid: ICompensationGridDetails,
  addToMemoCallback?: any,
}

const StipendsInfo = ({
  compensationGrid,
  addToMemoCallback,
}: Props) => {
  return (
    <SectionContainer title="Stipends">
      <ListWrapper>
        {compensationGrid.stipends.map((stipend, index) => (
          <DetailsWrapper key={index}>
            <TitleWrapper>
              <TitleContainter data-testid="stipends_title">
                {stipend.title}
              </TitleContainter>
              {addToMemoCallback &&
                <TextButtonWithPopover
                  onClick={() => addToMemoCallback(stipend)}
                  color='blue'
                  popoverText='Added'
                >
                  Add to Memo
                </TextButtonWithPopover>
              }
            </TitleWrapper>
            <DetailsContainer
              name="stipends_paid_in_full"
              label="Paid in Full"
              skipPermissions
              width="wide"
            >
              {
                paidInFullOptions.find(
                  (option) => option.id === stipend.paid_in_full?.toString()
                )?.name
              }
            </DetailsContainer>
            <DetailsContainer
              name="stipends_amount"
              label="Amount"
              skipPermissions
              width="wide"
            >
              {formatCurrency(stipend.amount)}
            </DetailsContainer>
            <DetailsContainer
              name="stipends_frequency"
              label="Frequency"
              skipPermissions
              width="wide"
            >
              {stipend.frequency}
            </DetailsContainer>
            <DetailsContainer
              name="stipends_pay_method"
              label="Method"
              skipPermissions
              width="wide"
            >
              {stipend.pay_method}
            </DetailsContainer>
            {stipend.notes && (
              <ItalicContainer
                name="stipends_notes"
                skipPermissions
                width="wide"
              >
                {stipend.notes}
              </ItalicContainer>
            )}
          </DetailsWrapper>
        ))}
      </ListWrapper>
    </SectionContainer>
  );
};

export default StipendsInfo;
