import { request } from '../instance';
import { IFilterParams } from 'interfaces/IFilterParams';
import { IClinicianContractFilterQueryParams } from 'interfaces/ClinicianContract/IClinicianContractFilterQueryParams';
import { IPayrollDetails } from 'interfaces/ClinicianContract/IPayrollDetails';

export const ClinicianContractAPI = {
  getAll: async (params: IFilterParams & IClinicianContractFilterQueryParams) => {
    const response = await request({
      url: 'v1/clinician_contracts',
      method: 'GET',
      params,
    });

    return response.data;
  },

  createContract: async (formData: object) => {
    try {
      const response = await request({
        url: 'v1/clinician_contracts',
        method: 'POST',
        data: {
          data: {
            clinician_contract: formData,
          },
        },
      });

      return response;
    } catch (error: any) {
      return error?.response;
    };
  },

  updateContract: async (contractId: number, formData: object) => {
    try {
      const response = await request({
        url: `v1/clinician_contracts/${contractId}`,
        method: 'PUT',
        data: {
          data: {
            clinician_contract: formData,
          },
        },
      });

      return response;
    } catch (error: any) {
      return error?.response;
    };
  },

  getDetails: async (contractId: number) => {
    const response = await request({
      url: `v1/clinician_contracts/${contractId}`,
      method: 'GET',
    });

    return response.data.data;
  },

  createAmendment: async (contractId: number, formData: IPayrollDetails) => {
    try {
      const response = await request({
        url: `v1/clinician_contracts/${contractId}/amendments`,
        method: 'POST',
        data: {
          data: {
            amendment: formData,
          },
        },
      });

      return response;
    } catch (error: any) {
      return error?.response;
    };
  },

  updateAmendment: async (contractId: number, amendmentId: number, formData: IPayrollDetails) => {
    try {
      const response = await request({
        url: `v1/clinician_contracts/${contractId}/amendments/${amendmentId}`,
        method: 'PUT',
        data: {
          data: {
            amendment: formData,
          },
        },
      });

      return response;
    } catch (error: any) {
      return error?.response;
    };
  },

  checkAmendmentCreateAvailable: async (contractId: number) => {
    try {
      const response = await request({
        url: `v1/clinician_contracts/${contractId}/amendments/check_unapplied`,
        method: 'GET',
      });

      return response;
    } catch (error: any) {
      return error?.response;
    };
  },
};
