import { DialogContent, Dialog, DialogTitle } from '@mui/material';
import { styled } from '@mui/material/styles';
import { colors } from 'styles/globalStyles';

export const StyledDialog = styled(Dialog)(() => ({
  '.MuiDialog-paper': {
    width: '100%',
    maxHeight: '720px',
    borderRadius: '4px',
  },
}));

export const StyledDialogTitle = styled(DialogTitle)(() => ({
  '&.MuiDialogTitle-root': {
    alignItems: 'center',
    backgroundColor: colors.primaryBlue,
    color: colors.white,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px 20px',
    position: 'sticky',
    fontSize: '16px',
    lineHeight: 1,
    zIndex: 1,
    top: 0,
  },
}));

export const StyledDialogContent = styled(DialogContent)(() => ({
  '&.MuiDialogContent-root': {
    padding: 0,
    paddingTop: '15px',
  },
}));
