import { Typography } from '@mui/material';
import { StyledPageHeader, StyledBarActions } from './styledComponents';

interface PageHeaderProps {
  pageTitle: string;
  children?: React.ReactNode;
  isSticky?: boolean;
};

const PageHeader = ({
  pageTitle,
  children,
  isSticky = true,
}: PageHeaderProps) => {
  return(
    <StyledPageHeader isSticky={isSticky}>
      <Typography variant='h1'>{pageTitle}</Typography>
      <StyledBarActions>
        {children}
      </StyledBarActions>
    </StyledPageHeader>
  );
};

export default PageHeader;
