import { Accordion, AccordionDetails, AccordionSummary, FormControlLabel, Button, Popover } from '@mui/material';
import { styled } from '@mui/material/styles';
import { colors } from 'styles/globalStyles';

export const ModalContent = styled('div')({
  margin: '0 20px 15px 20px',
  height: '572px',
  display: 'flex',
  flexDirection: 'column',
});

export const ActionsBar = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  gap: '20px',
  padding: '14px 16px',
  backgroundColor: colors.backgroundGray,
});


export const AccordionWrapper = styled('div')({
  marginTop: '15px',
  flexGrow: '1',
  overflow: 'auto',

  '.MuiAccordion-rounded:last-child': {
    borderBottom: `1px solid ${colors.tableBorderGray}`,
  },
});

export const StyledSummaryLabel = styled(FormControlLabel)({
  '.MuiFormControlLabel-label': {
    fontSize: '14px',
    fontWeight: 'bold',
  },
});

export const StyledDetailsLabel = styled(FormControlLabel)({
  '.MuiFormControlLabel-label': {
    fontSize: '14px',
  },
});

export const StyledAccordion = styled(Accordion)({
  '&': {
    border: `1px solid ${colors.tableBorderGray}`,
    boxShadow: 'none',
    borderBottom: 'none',
  },

  '&:before': {
    display: 'none',
  },

  '&.Mui-expanded': {
    margin: 0,
  },
});

export const StyledAccordionSummary = styled(AccordionSummary)({
  '&': {
    minHeight: '40px',
    padding: '0 12px',
  },

  '&.Mui-expanded': {
    minHeight: '40px',
  },

  '& .MuiAccordionSummary-content': {
    margin: 0,
  },

  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: 0,
  },
});

export const StyledAccordionDetails = styled(AccordionDetails)({
  '&': {
    borderTop: `1px solid ${colors.tableBorderGray}`,
    padding: '2.5px 30px',
  },
});
