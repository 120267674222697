import { useState, useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useField } from 'formik';
import { InputAdornment, IconButton } from '@mui/material';
import { useFieldsPermissions } from 'context/FieldsPermissionsContext';
import { StyledTextField } from './styledComponents';
import { CustomIcon } from 'components/UIComponents';
import { colors } from 'styles/globalStyles';
import formatSSN from 'helpers/formatSSN';
import { WidthType } from 'types';

interface TextFieldProps {
  label: string;
  name: string;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  onChange?: any;
  onBlur?: () => any;
  styles?: {};
  helperText?: string;
  width?: WidthType;
  type?: 'ssn' | 'text';
};

const DEBOUNCE_DELAY = 200;

const ssnRegex = /^(([0-9]{3}-[0-9]{2}-[0-9]{3,4})|([0-9]{0,9}))$/;


const TextField = ({
  label,
  name,
  required = false,
  disabled = false,
  onChange,
  onBlur,
  styles = {},
  width = 'normal',
  type = 'text',
}: TextFieldProps) => {
  const [field, meta, { setValue, setTouched }] = useField(name);
  const [visibleValue, setVisibleValue] = useState(field.value);
  const { fieldsPermissions } = useFieldsPermissions();
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const debouncedChange = useDebouncedCallback(
    (value) => {
      setValue(value);
    },
    DEBOUNCE_DELAY
  );

  useEffect(() => {
    setVisibleValue(field.value);
  }, [field.value]);

  return(
    <>
      {fieldsPermissions.read_allowed.includes(name) &&
      <StyledTextField
        styles={styles}
        width={width}
        error={meta.touched && !!meta.error}
        {...field}
        value={visibleValue || ''}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          onChange?.(e);
          switch(type) {
          case 'ssn':
            if (ssnRegex.test(e.target.value)) {
              setVisibleValue(formatSSN(e.target.value));
              debouncedChange(formatSSN(e.target.value));
            };
            break;
          default:
            setVisibleValue(e.target.value);
            debouncedChange(e.target.value);
          }
        }}
        type={showPassword ? 'text' : 'password'}
        InputProps={{endAdornment:
          <InputAdornment position='end'>
            <IconButton onClick={toggleShowPassword}>
              <CustomIcon size='big' name={showPassword ? 'eye' : 'crossedEye'} color={colors.primaryBlue} />
            </IconButton>
          </InputAdornment>,
        }}
        disabled={disabled || !(fieldsPermissions.update_allowed.includes(name))}
        required={required}
        variant="outlined"
        label={label}
        helperText={meta.touched && meta.error}
        size="small"
        onBlur={() => {
          setTouched(true); // To validate/show errors for field after trimming
          const trimmedValue = field.value?.trim();
          setVisibleValue(trimmedValue);
          setValue(trimmedValue);
          onBlur?.();
        }}
      />
      }
    </>
  );
};

export default TextField;
