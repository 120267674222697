import { useNavigate } from 'react-router-dom';
import { TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { CustomIcon, IconButton } from 'components/UIComponents';
import { colors } from 'styles/globalStyles';

import { ITableColumn } from 'interfaces/ITableColumn';
import TableSkeleton from './TableSkeleton';
import BodyTableCellWithData from './BodyTableCellWithData';
import NoResultsBlock from './NoResultsBlock';

import { StyledTable, HeadTableCell, BodyTableRow, BodyTableCell, Actions } from './styledComponents';

interface Props {
  name: string;
  columns: ITableColumn[];
  data: any[];
  editAction?: boolean
  rowsAsLinks?: boolean;
  tableRef?: React.RefObject<any>;
  showSkeleton?: boolean;
  withActions?: boolean;
  search?: string;
}

const TableWithLazyLoad = ({
  name,
  columns,
  data,
  editAction,
  rowsAsLinks = false,
  tableRef,
  search,
  showSkeleton,
  withActions = true,
}: Props) => {
  const navigate = useNavigate();

  const handleRowClick = (name: string, row: any) => {
    return navigate(`/${name}/${row.id}`);
  };

  return(
    <TableContainer ref={tableRef}>
      <StyledTable stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <HeadTableCell key={index} style={column.headStyle}>
                {column.text}
              </HeadTableCell>
            ))}
            {withActions && <HeadTableCell style={{width: '30px'}}/>}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <BodyTableRow
              key={index}
              rowsAsLinks={rowsAsLinks}
              onClick={rowsAsLinks ? () => handleRowClick(name, row) : undefined}
            >
              {columns.map((column, index) => (
                <BodyTableCellWithData
                  search={search}
                  key={index}
                  content={row[column.dataField]}
                />
              ))}
              {withActions && (
                <BodyTableCell onClick={(e) => e.stopPropagation()}>
                  <Actions className='table-actions'>
                    {editAction && (
                      <IconButton href={`/${name}/${row.id}/edit?redirectTo=/${name}`}>
                        <CustomIcon size='normal' color={colors.primaryBlue} name='edit' />
                      </IconButton>
                    )}
                  </Actions>
                </BodyTableCell>
              )}
            </BodyTableRow>
          ))}
          {showSkeleton ? <TableSkeleton columns={columns} withActions={withActions} /> : ''}
        </TableBody>
      </StyledTable>
      {data.length === 0 && !showSkeleton ? <NoResultsBlock searchText={search} /> : ''}
    </TableContainer>
  );
};

export default TableWithLazyLoad;
