import { PageContainer, PageHeader } from 'components/base';
import { useSearchParams } from 'react-router-dom';
import { engagementTypeOptions } from 'constants/compensationGridsConstants';
import CompensationGridForm from 'containers/CompensationGrids/CompensationGridForm';

const NewCompensationGrid = () => {
  const [searchParams] = useSearchParams();
  const engagementType = engagementTypeOptions.find(option => option.id === searchParams.get('engagement'));
  if (!engagementType) return null;

  return (
    <PageContainer type="normal" style={{marginBottom: '66px'}}>
      <PageHeader pageTitle={`Adding ${engagementType.name} Compensation Grid`}/>
      <CompensationGridForm />
    </PageContainer>
  );
};

export default NewCompensationGrid;
