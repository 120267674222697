import {
  SectionContainer,
  DetailsContainer,
  DetailsWrapper,
} from 'components/base';

interface ICompensationGridInfo {
  facilityName: string | undefined;
  clinicianTitle: string | undefined;
  contractType: string | undefined;
  engagement: string | undefined;
}

const CompensationGridInfo = ({
  facilityName,
  clinicianTitle,
  contractType,
  engagement,
}: ICompensationGridInfo) => {
  return (
    <SectionContainer title="Comp Grid Info">
      <DetailsWrapper>
        <DetailsContainer
          name="facility_name"
          label="Facility"
          skipPermissions
          width="wide"
        >
          {facilityName}
        </DetailsContainer>
        <DetailsContainer
          name="clinician_title"
          label="Clinician Title"
          skipPermissions
          width="wide"
        >
          {clinicianTitle}
        </DetailsContainer>
        <DetailsContainer
          name="contract_type"
          label="Contract Type"
          skipPermissions
          width="wide"
        >
          {contractType}
        </DetailsContainer>
        <DetailsContainer
          name="engagement"
          label="Engagement"
          skipPermissions
          width="wide"
        >
          {engagement}
        </DetailsContainer>
      </DetailsWrapper>
    </SectionContainer>
  );
};

export default CompensationGridInfo;
