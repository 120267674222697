import { styled } from '@mui/material/styles';

import { colors } from 'styles/globalStyles';
import { IconButton, Link, Menu } from '@mui/material';

export const StyledActionsMenuContainer = styled(Menu)(() => ({
  '& .MuiPopover-paper': {
    border: `1px solid ${colors.borderGray}`,
    boxShadow: 'none',
  },

  '.MuiMenuItem-root': {
    width: '179px',
    height: '36px',
    fontSize: '14px',
    fontWeight: 400,
    color: colors.pressedBlue,
  },

  '& div:not(:last-child)': {
    borderBottom: `1px solid ${colors.borderGray}`,
  },
}));

export const StyledIconButton = styled(IconButton)(() => ({
  '&:hover': {
    borderRadius: '4px',
    backgroundColor: colors.lightGrey,
  },
}));

export const StyledLink = styled(Link)(() => ({
  '&': {
    textDecoration: 'none',
    width: '100%',
  },
}));
