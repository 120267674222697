import { useMemo } from 'react';
import { FieldArray, useFormikContext } from 'formik';

import { ICompensationGridDetails } from 'interfaces/CompensationGrid/ICompensationGridDetails';
import { IContractedHour } from 'interfaces/CompensationGrid/IContractedHour';

import { CustomIcon, Select, TextButton, Input } from 'components/UIComponents';
import { InputFieldsWrapper, SectionContainer } from 'components/base';

import { staffCategoryOptions, rateTypeOptions, stepIncreaseOptions } from 'constants/compensationGridsConstants';

import { SectionsWrapper, DeleteCaterogyWrapper } from './styledComponents';
import CompensationRateSubform from '../CompensationRateSubform';

interface Props {
  contractedHours: IContractedHour[],
};

const CompensationScaleSubform = ({ contractedHours }: Props) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext<ICompensationGridDetails>();
  const contractedHoursOptions = useMemo(() => {
    return contractedHours?.map((conractedHour: IContractedHour) => ({ id: conractedHour.id, name: conractedHour.title })) ?? [];
  }, [contractedHours]);

  return(
    <SectionContainer title='Comp Scale'>
      <FieldArray name='compensation_scales'>
        {({ remove, push }) => (
          <>
            <SectionsWrapper>
              {(values.compensation_scales && values.compensation_scales.length > 0) && (
                values.compensation_scales.map((compensationScale, index) => (
                  // TODO: make a research: change index to uid just to remove needed item instead of rerendering whole component
                  <div key={index}>
                    <InputFieldsWrapper>
                      {values.engagement === 'full_time' &&
                        <Select
                          name={`compensation_scales.${index}.staff_category`}
                          label='Staff Category'
                          options={staffCategoryOptions}
                          width='normal'
                          skipPermissionsCheck
                          allowEmpty={false}
                        />
                      }
                      <Select
                        name={`compensation_scales.${index}.rate_type`}
                        label='Rate Type'
                        options={rateTypeOptions}
                        width='narrow'
                        skipPermissionsCheck
                        allowEmpty={false}
                        onChange={() => {
                          setFieldValue(`compensation_scales.${index}.step_increase`, stepIncreaseOptions[0].id);
                          setFieldValue(`compensation_scales.${index}.compensation_rates`,
                            [{ experience: '', annual_rate: '', notes: '', hourly_rate: '' }]);
                        }}
                      />
                      <Select
                        name={`compensation_scales.${index}.contracted_hour_id`}
                        label='Contracted Hours'
                        options={contractedHoursOptions}
                        width='wide'
                        skipPermissionsCheck
                        allowEmpty={false}
                      />
                      { values.contract_type === 'pc' && values.engagement === 'full_time' &&
                        <Input
                          name={`compensation_scales.${index}.weeks_of_service`}
                          label='Weeks of service'
                          width='narrow'
                          skipPermissionsCheck
                          type='twoDigits'
                        />
                      }
                      { compensationScale.rate_type === 'tiered' &&
                        <Select
                          name={`compensation_scales.${index}.step_increase`}
                          label='Step Increase'
                          options={stepIncreaseOptions}
                          width={values.engagement === 'full_time' ? 'wide' : 'ultra-wide'}
                          skipPermissionsCheck
                          allowEmpty={false}
                        />
                      }
                    </InputFieldsWrapper>
                    {contractedHours &&
                      <CompensationRateSubform
                        values={values}
                        compensationScale={compensationScale}
                        compensationScaleIndex={index}
                        contractedHours={contractedHours}
                      />
                    }
                    {values.compensation_scales.length > 1 &&
                      <DeleteCaterogyWrapper>
                        <TextButton color='red' onClick={() => remove(index)}>
                          <CustomIcon size='small' name='trash'/>
                          Delete Category
                        </TextButton>
                      </DeleteCaterogyWrapper>
                    }
                  </div>
                ))
              )}
            </SectionsWrapper>
            {values.compensation_scales.length < staffCategoryOptions.length && values.engagement === 'full_time' &&
            <div>
              <TextButton color='blue' onClick={() => {
                push({
                  staff_category: staffCategoryOptions[values.compensation_scales.length].id,
                  rate_type: 'flat',
                  contracted_hour_id: contractedHoursOptions[0].id,
                  weeks_of_service: '',
                  step_increase: stepIncreaseOptions[0].id,
                  compensation_rates: [{
                    annual_rate: '',
                    hourly_rate: '',
                    notes: '',
                    experience: '',
                  }],
                });
                setFieldTouched(`compensation_scales.${values.compensation_scales.length}.staff_category`, true);
              }}>
                + Add Category
              </TextButton>
            </div>
            }
          </>
        )}
      </FieldArray>
    </SectionContainer>
  );
};

export default CompensationScaleSubform;
