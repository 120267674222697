import { TableBody, TableHead } from '@mui/material';

import { ICompensationGridDetails } from 'interfaces/CompensationGrid/ICompensationGridDetails';
import { IFacility } from 'interfaces/Facility/IFacility';

import { HeadTableCell, HeadTableRow, StyledTable, BodyTableRow,
  NoOverflowBodyTableCell } from 'components/base/TableWithLazyLoad/styledComponents';
import { InlineInput } from 'components/UIComponents';
import { SectionContainer } from 'components/base';

import { compensationTerms } from 'constants/compensationGridsConstants';
import formatCurrency from 'helpers/formatCurrency';
import { useEffect, useState } from 'react';

interface Props {
  values: ICompensationGridDetails
  facility: IFacility
}

const CompensationTermsSection = ({values, facility}: Props) => {
  const [defaultValues, setDefaultValues] = useState({});

  useEffect(() => {
    const filteredCompensationTerms = compensationTerms
      .filter(compensationTerm => [values.engagement, 'both'].includes(compensationTerm.showOn));

    const result = {} as any;
    filteredCompensationTerms.forEach(item => {
      if (values.contract_type === 'pc' && item.changing_default_value) {
        result[item.fieldName] = 'n/a';
      } else {
        result[item.fieldName] = '';
      }
    });
    setDefaultValues(result);
  }, [values.contract_type]);

  return (
    <SectionContainer title='Comp Terms'>
      <StyledTable>
        <TableHead>
          <HeadTableRow>
            <HeadTableCell key={'title'} style={{width: '33%'}}>
              Title
            </HeadTableCell>
            <HeadTableCell key={'definition'} style={{width: '33%'}}>
              Definition
            </HeadTableCell>
            <HeadTableCell key={'notes'} style={{width: '33%'}}>
              Notes
            </HeadTableCell>
          </HeadTableRow>
        </TableHead>
        <TableBody>
          {compensationTerms
            .filter(compensationTerm => [values.engagement, 'both'].includes(compensationTerm.showOn)).map((compensationTerm, index) => {
              if (['limit_per_event', 'limit_yearly'].includes(compensationTerm.fieldName)) {
                return (<BodyTableRow key={index}>
                  <NoOverflowBodyTableCell>
                    {compensationTerm.title}
                  </NoOverflowBodyTableCell>
                  <NoOverflowBodyTableCell>
                    {formatCurrency(facility?.[(compensationTerm.fieldName as 'limit_per_event' | 'limit_yearly')], true)}
                  </NoOverflowBodyTableCell>
                  <NoOverflowBodyTableCell />
                </BodyTableRow>
                );
              } else {
                return (<BodyTableRow key={index}>
                  <NoOverflowBodyTableCell>
                    {compensationTerm.title}
                  </NoOverflowBodyTableCell>
                  <NoOverflowBodyTableCell>
                    <InlineInput
                      type='text'
                      name={compensationTerm.fieldName}
                      defaultValue={defaultValues[compensationTerm.fieldName as keyof typeof defaultValues]}
                      multiline
                    />
                  </NoOverflowBodyTableCell>
                  <NoOverflowBodyTableCell>
                    <InlineInput
                      type='text'
                      name={`${compensationTerm.fieldName}_notes`}
                      multiline
                    />
                  </NoOverflowBodyTableCell>
                </BodyTableRow>
                );
              }
            })
          }
        </TableBody>
      </StyledTable>
    </SectionContainer>
  );
};

export default CompensationTermsSection;
