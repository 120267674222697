import { FormEvent, useState, useEffect } from 'react';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';

import { FacilityAPI } from 'api/FacilityAPI';
import { CompensationGridAPI } from 'api/CompensationGridAPI';

import { OutlinedButton, SubmitButton, Select, SelectWithSearch } from 'components/UIComponents';
import { SectionContainer, ActionsBar, InputFieldsWrapper } from 'components/base';

import { ICompensationGridDetails } from 'interfaces/CompensationGrid/ICompensationGridDetails';
import { ISelectOption } from 'interfaces/ISelectOption';
import { IFacility } from 'interfaces/Facility/IFacility';

import { ContractType } from 'types';

import { useToast } from 'context/ToastContext';

import { engagementTypeOptions, clinicianTitleOptions, contractTypeOptions,
  staffCategoryOptions, stepIncreaseOptions, rateTypeOptions } from 'constants/compensationGridsConstants';
import VALIDATION_MESSAGES from 'constants/validationMessages';

import { compensationGridValidationSchema } from 'helpers/validation/compensationGridValidationSchema';
import prepareCompensationGridFormData from 'helpers/prepareCompensationGridFormData';
import handleFormSubmitResponse from 'helpers/handleFormSubmitResponse';
import useData from 'hooks/useData';

import CompensationScaleSubform from './CompensationScaleSubform';
import CompensationTermsSection from './CompensationTermsSection';
import CallTermsSubform from './CallTermsSubform';
import StipendsSubform from './StipendsSubform';
import AdditionalCompensationsSubform from './AdditionalCompensationsSubform';

import { ErrorContainer } from './styledComponents';
import { ICompensationScale } from 'interfaces/CompensationGrid/ICompensationScale';
import { cloneDeep } from 'lodash';

const CompensationGridForm = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { compensationGridId } = useParams();
  const { onToastOpen } = useToast();

  const facilities = useData(FacilityAPI.getAll, { relation: 'CompensationGrid' })?.data.objects;

  const [uniquenessError, setUniquenessError] = useState<string | false>(false);
  const [facilitiesOptions, setFacilitiesOptions] = useState<Array<ISelectOption>>([]);
  const [compensationGridFormInformation, setCompensationGridFormInformation] = useState<ICompensationGridDetails>({
    facility_id: '',
    clinician_title: '',
    contract_type: '',
    engagement: compensationGridId ? '' : engagementTypeOptions.find(option => option.id === searchParams.get('engagement'))?.id as string,
    time_off: '',
    time_off_notes: '',
    time_off_cme: '',
    time_off_cme_notes: '',
    overtime: '',
    overtime_notes: '',
    malpractice: '',
    malpractice_notes: '',
    health_benefits: '',
    health_benefits_notes: '',
    dental_benefits: '',
    dental_benefits_notes: '',
    vision_benefits: '',
    vision_benefits_notes: '',
    li_benefits: '',
    li_benefits_notes: '',
    ltd_benefits: '',
    ltd_benefits_notes: '',
    std_benefits: '',
    std_benefits_notes: '',
    voluntary_benefits: '',
    voluntary_benefits_notes: '',
    match: '',
    match_notes: '',
    state_licenses: '',
    state_licenses_notes: '',
    cme_pdme: '',
    cme_pdme_notes: '',
    workers_comp: '',
    workers_comp_notes: '',
    termination_notice: '',
    termination_notice_notes: '',
    miscellaneous: '',
    miscellaneous_notes: '',
    compensation_scales: [{
      staff_category: staffCategoryOptions[0].id,
      rate_type: rateTypeOptions[0].id,
      contracted_hour_id: '',
      weeks_of_service: '',
      step_increase: stepIncreaseOptions[0].id,
      compensation_rates: [{
        annual_rate: '',
        hourly_rate: '',
        notes: '',
        experience: '',
      }],
    }],
    call_terms: [],
    stipends: [],
    additional_compensations: [],
  });
  const contractedHours = useData(CompensationGridAPI.getContractedHours,
    searchParams.get('engagement') || compensationGridFormInformation.engagement );

  useEffect(() => {
    if (contractedHours && !compensationGridId) {
      setCompensationGridFormInformation({
        ...compensationGridFormInformation,
        compensation_scales: [{
          ...compensationGridFormInformation.compensation_scales[0],
          contracted_hour_id: contractedHours[0].id,
        }],
      });
    }
  }, [contractedHours]);

  useEffect(() => {
    if (facilities) {
      setFacilitiesOptions(facilities.map((facility: IFacility) => ({ id: facility.id, name: facility.name })));
    };
  }, [facilities]);

  useEffect(() => {
    if (compensationGridId && facilitiesOptions) {
      CompensationGridAPI.getDetails(Number(compensationGridId)).then((data: ICompensationGridDetails) => {
        const formData = cloneDeep(data);
        formData.compensation_scales.forEach((compensaitonScale: ICompensationScale) => {
          if (compensaitonScale.compensation_rates.length === 0) {
            compensaitonScale.compensation_rates.push({
              annual_rate: '',
              hourly_rate: '',
              notes: '',
              experience: '',
            });
          };
        });
        setCompensationGridFormInformation(formData);
      });
    };
  }, [compensationGridId, facilitiesOptions]);

  const checkUniqueness = async (
    facility_id: number | '',
    clinician_title: string,
    contract_type: ContractType | '',
    engagement: string) => {
    if (facility_id && clinician_title && contract_type && engagement) {
      setUniquenessError(false);
      const compensationGrid = await CompensationGridAPI.findGrid({ facility_id, clinician_title, contract_type, engagement });
      if (Object.keys(compensationGrid).length !== 0) {
        setUniquenessError(VALIDATION_MESSAGES.COMP_GRID_ALREADY_EXISTS);
      }
    };
  };

  const onContractTypeChange = (contractType: string, compensationScales: ICompensationScale[], setFieldValue: any ) => {
    if (contractType === 'w2') {
      compensationScales.forEach((comensationScale, index) => {
        setFieldValue(`compensation_scales.${index}.weeks_of_service`, '');
      });
    }
  };

  if (!facilities) return null;

  return(
    <Formik
      initialValues={compensationGridFormInformation}
      enableReinitialize={true}
      validationSchema={compensationGridValidationSchema}
      validateOnBlur
      onSubmit={async (values, { setFieldError }) => {
        const data = prepareCompensationGridFormData(values);
        let response;
        if (compensationGridId) {
          response = await CompensationGridAPI.updateCompensationGrid(Number(compensationGridId), data);
        } else {
          response = await CompensationGridAPI.createCompensationGrid(data);
        }
        handleFormSubmitResponse(response, setFieldError, onToastOpen, navigate, searchParams.get('redirectTo') as string);
      }}
    >
      {({ errors, handleSubmit, dirty, values, setFieldValue }) => (
        <Form>
          <SectionContainer title='Comp Grid Info'>
            <InputFieldsWrapper>
              <SelectWithSearch
                name='facility_id'
                label='Facility'
                options={facilitiesOptions}
                width='ultra-wide'
                required
                disabled={!!compensationGridId}
                onChange={(newValue: ISelectOption) =>
                  checkUniqueness(newValue.id as number, values.clinician_title, values.contract_type, values.engagement)
                }
                skipPermissionsCheck
              />
            </InputFieldsWrapper>
            <InputFieldsWrapper>
              <Select
                name='clinician_title'
                label='Clinician Title'
                options={clinicianTitleOptions}
                required
                width='narrow'
                disabled={!!compensationGridId}
                onChange={(clinician_title: any) =>
                  checkUniqueness(values.facility_id, clinician_title, values.contract_type, values.engagement)
                }
                skipPermissionsCheck
              />
              <Select
                name='contract_type'
                label='Contract Type'
                options={contractTypeOptions}
                required
                width='narrow'
                disabled={!!compensationGridId}
                onChange={(contract_type: any) => {
                  checkUniqueness(values.facility_id, values.clinician_title, contract_type, values.engagement);
                  onContractTypeChange(contract_type, values.compensation_scales, setFieldValue);
                }}
                skipPermissionsCheck
              />
              <Select
                name='engagement'
                label='Engagement'
                options={engagementTypeOptions}
                required
                disabled
                width='narrow'
                skipPermissionsCheck
              />
            </InputFieldsWrapper>
            { uniquenessError &&
              <ErrorContainer>{uniquenessError}</ErrorContainer>
            }
          </SectionContainer>
          <CompensationScaleSubform
            contractedHours={contractedHours}
          />
          <CompensationTermsSection
            values={values}
            facility={facilities.find((facility: IFacility) => facility.id === values.facility_id)}
          />
          <CallTermsSubform values={values} />
          <StipendsSubform values={values} />
          <AdditionalCompensationsSubform values={values} />
          <ActionsBar>
            <OutlinedButton
              onClick={() => navigate(-1)}
            >
              Cancel
            </OutlinedButton>
            <SubmitButton
              disabled={!dirty || !!Object.values(errors).length || !!uniquenessError}
              onClick={(values: FormEvent<HTMLFormElement>) => handleSubmit(values)}
            >
              Save Compensation Grid
            </SubmitButton>
          </ActionsBar>
        </Form>
      )}
    </Formik>
  );
};

export default CompensationGridForm;

