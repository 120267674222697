import { FieldArray } from 'formik';
import { TableBody, TableHead } from '@mui/material';

import { SectionContainer } from 'components/base';
import { BodyTableRow, HeadTableCell, HeadTableRow, NoOverflowBodyTableCell,
  StyledTable } from 'components/base/TableWithLazyLoad/styledComponents';
import { CustomIcon, IconButton, InlineInput, TextButton } from 'components/UIComponents';

import { ICompensationGridDetails } from 'interfaces/CompensationGrid/ICompensationGridDetails';
import { colors } from 'styles/globalStyles';

interface Props {
  values: ICompensationGridDetails,
}
const CallTermsSubform = ({ values }: Props) => {
  return (
    <SectionContainer title='Call Terms'>
      <FieldArray name='call_terms'>
        {({ remove, push }) => (
          <>
            { values.call_terms.length > 0 &&
              <StyledTable>
                <TableHead>
                  <HeadTableRow>
                    <HeadTableCell key='title' style={{width: '168px'}}>
                      Title
                    </HeadTableCell>
                    <HeadTableCell key='hours' style={{width: '64px'}}>
                      Hours
                    </HeadTableCell>
                    <HeadTableCell key='amount' style={{width: '72px'}}>
                      Amount
                    </HeadTableCell>
                    <HeadTableCell key='call-in' style={{width: '61px'}}>
                      Call-In
                    </HeadTableCell>
                    <HeadTableCell key='notes' style={{width: '325px'}}>
                      Notes
                    </HeadTableCell>
                    <HeadTableCell style={{width: '30px'}}/>
                  </HeadTableRow>
                </TableHead>
                <TableBody>
                  { values.call_terms.map((callTerm, index) => (
                    <BodyTableRow key={`call_terms[${index}]`}>
                      <NoOverflowBodyTableCell>
                        <InlineInput
                          type='text'
                          name={`call_terms.${index}.title`}
                        />
                      </NoOverflowBodyTableCell>
                      <NoOverflowBodyTableCell>
                        <InlineInput
                          type='text'
                          name={`call_terms.${index}.hours`}
                        />
                      </NoOverflowBodyTableCell>
                      <NoOverflowBodyTableCell>
                        <InlineInput
                          type='currency'
                          name={`call_terms.${index}.amount`}
                        />
                      </NoOverflowBodyTableCell>
                      <NoOverflowBodyTableCell>
                        <InlineInput
                          type='text'
                          name={`call_terms.${index}.call_in`}
                        />
                      </NoOverflowBodyTableCell>
                      <NoOverflowBodyTableCell>
                        <InlineInput
                          type='text'
                          name={`call_terms.${index}.notes`}
                          multiline
                        />
                      </NoOverflowBodyTableCell>
                      <NoOverflowBodyTableCell>
                        <IconButton onClick={() => remove(index)}>
                          <CustomIcon size='normal' color={colors.red} name='trash' />
                        </IconButton>
                      </NoOverflowBodyTableCell>
                    </BodyTableRow>
                  ))}
                </TableBody>
              </StyledTable>
            }
            <div>
              <TextButton color='blue' onClick={() => push({
                title: '',
                hours: '',
                amount: '',
                call_in: '',
                notes: '',
              })}>
                + Add Term
              </TextButton>
            </div>
          </>
        )}
      </FieldArray>
    </SectionContainer>
  );
};

export default CallTermsSubform;
