const VALIDATION_MESSAGES = {
  REQUIRED: 'Required field',
  INTEGER: 'Must be an integer',
  POSITIVE: 'Must be positive',
  MAX_NUMBER: (limit: number) => `Enter a positive whole number below ${String(limit+1).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
  MIN_NUMBER: (limit: number) => `Enter a positive number above or equal to ${limit}`,
  STRING_MAX_LENGTH: (stringMaxLength: number) =>
    `Must be less than ${stringMaxLength} characters`,
  EMAIL: 'Ensure the email address is valid',
  PHONE_EXACT_LENGTH: 'Ensure your entry consists of 10 digits',
  SSN_EXACT_LENGTH: 'Ensure your entry consists of 9 digits',
  DATE_MUST_BE_PRESENT: 'Ensure your selected date is not in future',
  NOT_UNIQUE_COMP_SCALE_STAFF_CATEGORY: 'Each category can be added to a comp grid only once',
  NOT_HIGHER: 'Value must be higher than the previous row value',
  NOT_BETWEEN: 'Value must be between the previous and following row values',
  COMP_GRID_ALREADY_EXISTS:
    "Comp grid for the selected combination of 'Facility', 'Clinician Title', 'Contract Type', 'Engagement' already exists",
  COMP_GRID_NOT_EXISTS:
    "Comp grid for the selected combination of 'Facility', 'Clinician Title', 'Contract Type', 'Engagement' does not exist",
  GRAD_DATE_NOT_SPECIFIED: 'The rate cannot be defined. Specify the Grad Date on the Clinician',
  NO_SUITABLE_TIER_FOUND: 'There is no compensation tier, which corresponds to the clinician experience',
  NO_RATES_FOUND: 'The comp grid does not have the rates specified. Add rates to the comp grid first',
  CLINICIAN_NO_TITLE: 'Specify title in the clinician record for the comp grid selection',
  MIN_EFFECTIVE_DATE: 'Ensure the date is later than the effective date of the contract or the previous amendment',
};

export default VALIDATION_MESSAGES;
