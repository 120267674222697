import { styled } from '@mui/material/styles';
import { colors } from 'styles/globalStyles';

export const StyledContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '60px',
});;

export const StyledBox = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  fontSize: '16px',
  fontWeight: 400,
  color: colors.black,
});
