import dayjs from 'dayjs';

const formatDate = (value: string) => {
  if (value) {
    return dayjs(value).format('MM/DD/YYYY');
  } else {
    return '';
  }
};

export default formatDate;
