import { styled } from '@mui/material/styles';
import { colors } from 'styles/globalStyles';

export const SectionName = styled('div')({
  fontSize: '18px',
  fontWeight: '600',
  marginBottom: '15px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

export const ScaleContainerWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '14px',
  '&:hover': {
    backgroundColor: colors.hoverGray,
  },
});

export const ScaleContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: '30px',
  borderBottom: `1px solid ${colors.tableBorderGray}`,
  margin: '0 20px',
});

export const ScalesWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});
