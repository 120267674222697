import { useState } from 'react';
import { Timeout } from 'react-number-format/types/types';
import Fade from '@mui/material/Fade';
import { StyledTextButton } from 'components/UIComponents/input/TextButton/styledComponents';
import { PopperContainer, StyledPopper } from './styledComponents';

interface TextButtonProps {
  onClick?: Function;
  disabled?: boolean;
  children: React.ReactNode;
  popoverText: string;
  color?: 'red' | 'blue';
  href?: string;
}

const closeDelay = 3000;

const TextButtonWithPopover = ({ onClick, children, disabled = false, color='red', href, popoverText }: TextButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [timeoutId, setTimeoutId] = useState<Timeout>();
  const [buttonDisabled, setButtonDisabled] = useState(disabled);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onClick?.();
    setAnchorEl(event.currentTarget);
    setButtonDisabled(true);
    clearTimeout(timeoutId);
    setTimeoutId(setTimeout(handleClose, closeDelay));
  };

  const handleClose = () => {
    setAnchorEl(null);
    setButtonDisabled(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-Popper' : undefined;

  return (
    <>
      <StyledTextButton
        href={href}
        variant="text"
        disabled={buttonDisabled}
        onClick={(e) => handleClick(e)}
        buttoncolor={color}
        aria-describedby={id}
      >
        {children}
      </StyledTextButton>
      <StyledPopper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement='top'
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={200}>
            <PopperContainer>
              {popoverText}
            </PopperContainer>
          </Fade>
        )}
      </StyledPopper>
    </>
  );
};


export default TextButtonWithPopover;
