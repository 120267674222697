import { Menu, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledMenu = styled(Menu)(() => ({
  marginTop: '2px',
  right: 0,
}));

export const StyledMenuItem = styled(MenuItem)(() => ({
  paddingLeft: '20px',
  paddingRight: '20px',
}));
