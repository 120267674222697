import { IToastParams } from 'interfaces/IToastParams';
import { IconButton } from '@mui/material';
import ToastType from 'constants/enums/ToastType';
import CloseIcon from '@mui/icons-material/Close';
import { StyledAlert, StyledSnackbar } from './styledComponents';

interface Props {
  open: boolean;
  params: IToastParams;
  onClose: () => void;
}

const Toast = ({ open, params, onClose }: Props) => {
  const { type, message }: { type: ToastType; message: string } = params;
  const action = (
    <IconButton aria-label="close" color="inherit" onClick={onClose} sx={{ padding: 0 }}>
      <CloseIcon />
    </IconButton>
  );

  return (
    <StyledSnackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      open={open}
      autoHideDuration={3000}
      onClose={onClose}
      action={action}
    >
      <StyledAlert onClose={onClose} severity={type}>
        {message}
      </StyledAlert>
    </StyledSnackbar>
  );
};

export default Toast;
