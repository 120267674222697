/* eslint-disable no-magic-numbers */
import { ssnRegex } from 'constants/fieldsLimits';

const formatSSN = (value: string): string => {
  if (/^[0-9]{9}$/.test(value)) {
    return `${value.slice(0, 3)}-${value.slice(3, 5)}-${value.slice(5)}`;
  } else if (ssnRegex.test(value)) {
    return value;
  } else {
    return value.replace(/-/g, '');
  }
};

export default formatSSN;
