import { useFieldsPermissions } from 'context/FieldsPermissionsContext';
import {
  StyledDetailsContainer,
  PropertyName,
  PropertyValue,
} from './styledComponents';

interface DetailsContainerProps {
  name: string;
  label: string;
  children: React.ReactNode;
  width?: 'normal' | 'wide';
  skipPermissions?: boolean;
}

const DetailsContainer = ({
  name,
  label,
  children,
  skipPermissions = false,
  width = 'normal',
}: DetailsContainerProps) => {
  const { fieldsPermissions } = useFieldsPermissions();
  return (
    <>
      {(skipPermissions || fieldsPermissions.read_allowed.includes(name)) && (
        <StyledDetailsContainer>
          <PropertyName width={width}>{label}</PropertyName>
          <PropertyValue data-testid={name}>{children}</PropertyValue>
        </StyledDetailsContainer>
      )}
    </>
  );
};

export default DetailsContainer;
