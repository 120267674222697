import { ICompensationGridDetails } from 'interfaces/CompensationGrid/ICompensationGridDetails';

import {
  SectionContainer,
  DetailsContainer,
  DetailsWrapper,
} from 'components/base';

import formatCurrency from 'helpers/formatCurrency';
import ItalicContainer from 'components/base/ItalicContainer';
import { TitleContainter, ListWrapper, TitleWrapper } from '../styledComponents';
import { TextButtonWithPopover } from 'components/UIComponents';

interface Props {
  compensationGrid: ICompensationGridDetails;
  addToMemoCallback?: any,
};

const AdditionalCompensationsInfo = ({
  compensationGrid,
  addToMemoCallback,
}: Props) => {
  return (
    <SectionContainer title="Additional compensations">
      <ListWrapper>
        {compensationGrid.additional_compensations.map(
          (addititonal_compensation, index) => (
            <DetailsWrapper key={index}>
              <TitleWrapper>
                <TitleContainter data-testid="additional_compensations_title">
                  {addititonal_compensation.title}
                </TitleContainter>
                {addToMemoCallback &&
                  <TextButtonWithPopover
                    onClick={() => addToMemoCallback(addititonal_compensation)}
                    color='blue'
                    popoverText='Added'
                  >
                    Add to Memo
                  </TextButtonWithPopover>
                }
              </TitleWrapper>
              <DetailsContainer
                name="additional_compensations_amount"
                label="Amount"
                skipPermissions
                width="wide"
              >
                {formatCurrency(addititonal_compensation.amount)}
              </DetailsContainer>
              <DetailsContainer
                name="additional_compensations_frequency"
                label="Frequency"
                skipPermissions
                width="wide"
              >
                {addititonal_compensation.frequency}
              </DetailsContainer>
              <DetailsContainer
                name="additional_compensations_pay_method"
                label="Method"
                skipPermissions
                width="wide"
              >
                {addititonal_compensation.pay_method}
              </DetailsContainer>
              {addititonal_compensation.notes && (
                <ItalicContainer
                  name="additional_compensations_notes"
                  skipPermissions
                  width="wide"
                >
                  {addititonal_compensation.notes}
                </ItalicContainer>
              )}
            </DetailsWrapper>
          )
        )}
      </ListWrapper>
    </SectionContainer>
  );
};

export default AdditionalCompensationsInfo;
