import { request } from '../instance';

export const RoleAPI = {
  getAll: async () => {
    const response = await request({
      url: 'v1/roles',
      method: 'GET',
    });

    return response.data.data;
  },
};
