import { styled } from '@mui/material/styles';
import { colors } from 'styles/globalStyles';

interface Props {
  isSticky: boolean;
};

export const StyledPageHeader = styled('div')(({ isSticky }: Props ) => ({
  width: '100%',
  boxSizing: 'border-box',
  display: 'flex',
  background: colors.backgroundGray,
  paddingTop: '16px',
  paddingBottom: '16px',
  marginBottom: '-16px',
  alignItems: 'center',
  justifyContent: 'space-between',
  ...(isSticky ? {
    position: 'sticky',
    top: '50px',
    zIndex: '2',
  }: {}),
}));


export const StyledBarActions = styled('div')({
  display: 'flex',
  gap: '20px',
});
