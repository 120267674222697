import dayjs from 'dayjs';

import { ContractEngagementType, ContractType } from 'types';
import { IClinicianContractForm } from 'interfaces/ClinicianContract/IClinicianContractForm';

import { frequencyMapper, confirmModalTitle } from 'constants/contractsConstants';

import findCompensationGrid from 'helpers/ClinicianConracts/findCompensationGrid';
import calculateExperience from 'helpers/calculateExperience';
import { IClinician } from 'interfaces/Clinician/IClinician';
import { ISelectOption } from 'interfaces/ISelectOption';

export const changeEngagementHandler = (
  engagement: ContractEngagementType,
  prevValue: ContractEngagementType | '',
  values: IClinicianContractForm,
  setFieldValue: any,
  setFieldTouched: any,
  gridTermsApplied: boolean,
  setConfirmModalProps: any,
  onModalOpen: any,
  clinicians: IClinician[],
  setCompensationGrid: any,
  facilitiesOptions: ISelectOption[],
) => {
  if (gridTermsApplied) {
    if (!((engagement === 'part_time' && prevValue === 'full_time') || (engagement === 'full_time' && prevValue === 'part_time'))) {
      setConfirmModalProps({
        fieldName: 'payroll_details.engagement',
        prevValue,
      });
      onModalOpen(confirmModalTitle);
    }
  } else {
    findCompensationGrid(
      values.clinician_id, values.facility_id, values.contract_type, engagement,
      setFieldTouched, setFieldValue, clinicians, setCompensationGrid, facilitiesOptions
    );
    setFrequency(values.contract_type, engagement, setFieldValue);
  }
};

export const changeContractTypeHandler = (
  contractType: ContractType,
  prevValue: ContractType | '',
  values: IClinicianContractForm,
  setFieldValue: any,
  setFieldTouched: any,
  gridTermsApplied: boolean,
  setConfirmModalProps: any,
  onModalOpen: any,
  clinicians: IClinician[],
  setCompensationGrid: any,
  facilitiesOptions: ISelectOption[],
) => {
  if (gridTermsApplied) {
    setConfirmModalProps({
      fieldName: 'contract_type',
      prevValue,
    });
    onModalOpen(confirmModalTitle);
  } else {
    findCompensationGrid(
      values.clinician_id, values.facility_id, contractType, values.payroll_details.engagement,
      setFieldTouched, setFieldValue, clinicians, setCompensationGrid, facilitiesOptions
    );
    setFrequency(contractType, values.payroll_details.engagement, setFieldValue);
    setFieldValue('payroll_details.paid_time_off', '');
    setFieldValue('payroll_details.weeks_of_service', '');
  }
};

export const changeClinicianHandler = (
  clinician_id: number | '',
  prevValue: number | '',
  values: IClinicianContractForm,
  setFieldValue: any,
  setFieldTouched: any,
  gridTermsApplied: boolean,
  setConfirmModalProps: any,
  onModalOpen: any,
  clinicians: IClinician[],
  setCompensationGrid: any,
  facilitiesOptions: ISelectOption[],
) => {
  if (gridTermsApplied) {
    setConfirmModalProps({
      fieldName: 'clinician_id',
      prevValue,
    });
    onModalOpen(confirmModalTitle);
  } else {
    findCompensationGrid(clinician_id, values.facility_id, values.contract_type, values.payroll_details.engagement,
      setFieldTouched, setFieldValue, clinicians, setCompensationGrid, facilitiesOptions
    );
    setExperience(clinicians, clinician_id, setFieldValue);
  }
};

export const changeFacilityHandler = (
  facility_id: number | '',
  prevValue: number | '',
  values: IClinicianContractForm,
  setFieldValue: any,
  setFieldTouched: any,
  gridTermsApplied: boolean,
  setConfirmModalProps: any,
  onModalOpen: any,
  clinicians: IClinician[],
  setCompensationGrid: any,
  facilitiesOptions: ISelectOption[],
) => {
  if (gridTermsApplied) {
    setConfirmModalProps({
      fieldName: 'facility_id',
      prevValue,
    });
    onModalOpen(confirmModalTitle);
  } else {
    findCompensationGrid(values.clinician_id, facility_id, values.contract_type, values.payroll_details.engagement,
      setFieldTouched, setFieldValue, clinicians, setCompensationGrid, facilitiesOptions
    );
  }
};

export const resetPayrollDetails = (
  values: IClinicianContractForm,
  setFieldValue: any,
  setValues: any,
  clinicians: IClinician[],
  fieldName: string,
) => {
  setValues({
    ...values,
    compensation_rate_id: '',
    payroll_details: {
      ...values.payroll_details,
      contracted_hours: '',
      weeks_of_service: '',
      notes: '',
      paid_time_off: '',
      hourly_rate: '',
      annual_rate: '',
      staff_category: '',
    },
  });
  switch(fieldName) {
  case 'clinician_id':
    setExperience(clinicians, values.clinician_id, setFieldValue);
    break;
  case 'contract_type':
    setFrequency(values.contract_type, values.payroll_details.engagement, setFieldValue);
    break;
  case 'payroll_details.engagement':
    setFrequency(values.contract_type, values.payroll_details.engagement, setFieldValue);
    break;
  default:
    break;
  }
};

const setFrequency = (contractType: ContractType | '', engagementValue: ContractEngagementType | '', setFieldValue: any) => {
  if (contractType && engagementValue) {
    const engagement = engagementValue === 'part_time' ? 'full_time' : engagementValue;
    setFieldValue('payroll_details.payroll_frequency', frequencyMapper.payroll_frequency[engagement][contractType]);
    setFieldValue('payroll_details.contracted_frequency', frequencyMapper.contracted_frequency[engagement][contractType]);
  };
};


const setExperience = (clinicians: IClinician[], clinician_id: number | '', setFieldValue: any) => {
  const gradDate = clinicians.find((el: IClinician) => el.id === clinician_id)?.grad_date as string;
  setFieldValue('grad_date', gradDate ? dayjs(gradDate) : '');
  setFieldValue('experience', gradDate ? calculateExperience(gradDate) : '');
};
