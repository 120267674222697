import { useMemo } from 'react';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';

import Chip from 'components/base/Chip';

import { IClinicianContractFilterFormParams } from 'interfaces/ClinicianContract/IClinicianContractFilterFormParams';
import { ISelectOption } from 'interfaces/ISelectOption';

import { filteringOptions as initialFilteringOptions, dateConditionOptions } from 'constants/dropdownOptions';

import { FilterChipsWrapper, ValueWrapper } from './styledComponents';

interface Props {
  chipsValues: IClinicianContractFilterFormParams,
  removeCondition: Function,
  formikRemove: Function,
}

const valueNotEmpty = (value: string[]) => {
  if (Array.isArray(value)) {
    return value.length > 0;
  } else {
    return dayjs(value).isValid();
  }
};

const FilterChips = ({ chipsValues, removeCondition, formikRemove }: Props) => {
  const { values, submitForm, setFieldValue } = useFormikContext<IClinicianContractFilterFormParams>();

  const filteredChips = useMemo(() => {
    return chipsValues.filters.filter(filter => filter.condition !== '' && valueNotEmpty(filter.value)) ?? [];
  }, [chipsValues]);

  if (filteredChips.length === 0) return null;

  return(
    <FilterChipsWrapper>
      Filter:
      {filteredChips.map((filter, index) => (
        <Chip onClose={() => removeCondition(index, formikRemove, submitForm, values, setFieldValue)} key={index}>
          <span>{initialFilteringOptions.find(option => option.id === filter.filteringOption)?.name}</span>
          {
            (['execution_date', 'effective_date'].includes(filter.filteringOption)) ?
              <>
                {` ${dateConditionOptions.find(condition => filter.condition === condition.id)?.name} `}
                <ValueWrapper>{filter.value.format('MM/DD/YYYY')}</ValueWrapper>
              </>
              :
              <>
                <span>{filter.value.length > 1 ? 'are' : 'is'}</span>
                <ValueWrapper>
                  { filter.value.find((option: ISelectOption) => option.id === 'all') ?
                    'All'
                    :
                    filter.value.map((option: ISelectOption) => option.name).join(', ')}
                </ValueWrapper>
              </>
          }
        </Chip>
      ))}
    </FilterChipsWrapper>
  );
};

export default FilterChips;
