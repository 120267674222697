import { createContext, useState, useContext } from 'react';
import { IFieldPermissions } from 'interfaces/IFieldPermissions';

interface IFieldsPermissionsContext {
  fieldsPermissions: IFieldPermissions,
  setFieldsPermissions: (fields: IFieldPermissions) => void,
}

export const FieldsPermissionsContext = createContext<IFieldsPermissionsContext>({
  fieldsPermissions: {read_allowed: [], update_allowed: []},
  setFieldsPermissions: (fields: object) => {},
});

interface FieldsPermissionsContextProps {
  children: React.ReactNode,
};

export const FieldsPermissionsContextState = ({ children }: FieldsPermissionsContextProps) => {
  const [fieldsPermissions, setPermissions] = useState<IFieldPermissions>({read_allowed: [], update_allowed: []});

  const setFieldsPermissions = (fields: IFieldPermissions) => {
    setPermissions(fields);
  };

  return (
    <FieldsPermissionsContext.Provider value={{ fieldsPermissions, setFieldsPermissions }}>
      {children}
    </FieldsPermissionsContext.Provider>
  );
};

export const useFieldsPermissions = () => {
  return useContext(FieldsPermissionsContext);
};
