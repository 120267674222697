import useAuth from 'hooks/useAuth';
import { Navigate } from 'react-router-dom';

const RootComponent = () => {
  const { user } = useAuth();

  return (
    user?.role === 'Recruiter' ?
      <Navigate to={'/facilities'} replace />
      :
      <Navigate to={'/clinician_contracts'} replace />
  );
};

export default RootComponent;
