import { Autocomplete, FormControl, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';

import { colors, fieldsWidthValues } from 'styles/globalStyles';
import { WidthType } from 'types';

interface StyledFormControlProps {
  width: WidthType
}

export const StyledFormControl = styled(FormControl)(({ width }: StyledFormControlProps) => ({
  '&': {
    width: fieldsWidthValues[width],
  },
}));

export const StyledAutocomplete = styled(Autocomplete)(() => {
  return {
    '& .MuiFormControl-root .MuiInputBase-root.MuiAutocomplete-inputRoot': {
      paddingRight: '40px',
    },
    '& .MuiInputBase-root.MuiInputBase-sizeSmall': {
      padding: '0 30px 0 0',
    },
    '& .MuiInputBase-root .MuiInputBase-input.MuiInputBase-inputSizeSmall': {
      padding: '8px 12px',
      fontSize: '14px',
    },
    '& .MuiInputBase-root .MuiAutocomplete-endAdornment ': {
      right: '0',
    },
    '& .MuiInputBase-root fieldset': {
      borderColor: colors.borderGray,
    },
    '& .Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
      border: '1px solid',
      borderColor: colors.primaryBlue,
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: colors.primaryBlue,
    },

    '& .MuiInputBase-root.Mui-error fieldset': {
      borderColor: colors.hoveredTextRed,
    },
    '& .MuiInputLabel-root.Mui-error': {
      color: colors.hoveredTextRed,
    },
    '& .MuiFormHelperText-root.Mui-error': {
      color: colors.hoveredTextRed,
      lineHeight: '1.2',
      marginTop: '5px',
    },

    '& .MuiInputBase-root.Mui-disabled': {
      backgroundColor: colors.inputDisabledGray,
    },
    '& .MuiInputBase-root.Mui-disabled fieldset': {
      borderColor: colors.disabledBorderGray,
    },
    '& .MuiInputBase-root.Mui-disabled input': {
      backgroundColor: colors.inputDisabledGray,
      WebkitTextFillColor: colors.black,
    },
    '& .MuiInputLabel-root.Mui-disabled': {
      color: colors.textGray,
    },
    '& .MuiInputAdornment-root': {
      marginRight: '-8px',
      zIndex: '1',
    },
    '& .MuiInputAdornment-root p': {
      fontSize: '14px',
    },
    '& .MuiInputBase-root .MuiChip-root': {
      display: 'none',
    },
  };
});
