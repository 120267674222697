import { useState, useEffect } from 'react';
import { PageContainer, ListOfRecords } from 'components/base';
import { SubmitButton } from 'components/UIComponents';

import { FacilityAPI } from 'api/FacilityAPI';
import { PermissionsAPI } from 'api/PermissionsAPI';

import { ITableColumn } from 'interfaces/ITableColumn';
import { IFacility } from 'interfaces/Facility/IFacility';

import formatPhone from 'helpers/formatPhone';
import columnsWithRecalculatedWidth from 'helpers/columnsWithRecalculatedWidth';
import useData from 'hooks/useData';
import useAuth from 'hooks/useAuth';

const formattedOfficePhone = (item: IFacility, permittedOfficeExt: boolean) => (
  `${formatPhone(item.admin1_office || '')}${(item.admin1_office && item.admin1_office_ext && permittedOfficeExt) ?
    ` x${item.admin1_office_ext}` : ''}`
);

const ListOfFacilities = () => {
  const { user } = useAuth();
  const editAllowed = user?.allowed_write_resources.includes('facilities');
  const fieldsPermissionsData = useData(PermissionsAPI.getAllowedAttributes, 'Facility');

  const [columns, setColumns] = useState<ITableColumn[]>([
    { dataField: 'name', text: 'Facility', headStyle: { width: '21%' }},
    { dataField: 'type', text: 'Type', headStyle: { width: '6%' }},
    { dataField: 'address', text: 'Address', headStyle: { width: '22%' } },
    { dataField: 'admin1_name', text: 'Admin', headStyle: { width: '12%' } },
    { dataField: 'admin1_email', text: 'Email', headStyle: { width: '17%' } },
    { dataField: 'admin1_office', text: 'Office', headStyle: { width: '13%' } },
    { dataField: 'admin1_cell', text: 'Cell', headStyle: { width: '9%' } },
  ]);

  useEffect(() => {
    if (fieldsPermissionsData) {
      setColumns(columnsWithRecalculatedWidth(columns, fieldsPermissionsData.read_allowed));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldsPermissionsData]);

  const dataFormatter = (item: IFacility) => ({
    id: item.id,
    name: item.name,
    type: item.type,
    address: item.address,
    admin1_name: item.admin1_name,
    admin1_email: item.admin1_email,
    admin1_office: formattedOfficePhone(item, fieldsPermissionsData?.read_allowed.includes('admin1_office_ext')),
    admin1_cell: formatPhone(item.admin1_cell || ''),
  });

  return(
    <PageContainer type="wide" style={{marginBottom: '20px'}}>
      <ListOfRecords
        tableProps={{
          name: 'facilities',
          columns: columns as ITableColumn[],
          editAction: editAllowed,
          withActions: editAllowed,
          rowsAsLinks: true,
        }}
        pageTitle='Facilities'
        barActions={editAllowed &&
          <SubmitButton href='/facilities/new?redirectTo=/facilities'>Add Facility</SubmitButton>
        }
        dataFormatter={dataFormatter}
        dataProvider={FacilityAPI.getAll}
        searchProps={{
          allowSearch: false,
        }}
      />
    </PageContainer>
  );
};

export default ListOfFacilities;
