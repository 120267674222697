import React from 'react';
import Highlighter from 'react-highlight-words';

import { BodyTableCell } from '../styledComponents';

interface Props {
  search?: string;
  content: any;
  style?: React.CSSProperties;
  customSearchMatchRule?: RegExp;
}

const BodyTableCellWithData = ({ search = '', content, customSearchMatchRule, style }: Props) => {
  let cellContent = content;

  const highlighted = (content: string | number) => {
    const text = String(content);
    const searchWords = search === '' ? [] : [search.trim()];
    if (customSearchMatchRule && String(text).match(customSearchMatchRule)) {
      searchWords.push(text);
    }
    return (
      <Highlighter
        highlightClassName="highlighted"
        searchWords={searchWords}
        textToHighlight={String(text)}
        autoEscape
        highlightStyle={{backgroundColor: 'rgba(209, 231, 254, 0.64)'}}
      />
    );
  };

  if (search) {
    if (['string', 'number'].includes(typeof content)) {
      cellContent = highlighted(content);
    }
  }

  return (
    <BodyTableCell align="right" sx={style}>
      {cellContent}
    </BodyTableCell>
  );
};

export default BodyTableCellWithData;
