import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

import { colors } from 'styles/globalStyles';

export const StyledOutlinedButton = styled(Button)(() => ({
  '&': {
    border: '1px solid',
    color: colors.secondaryGreen,
    borderColor: colors.secondaryGreen,
  },
  '&:hover': {
    backgroundColor: colors.hoveredGreen,
    color: colors.secondaryGreen,
    borderColor: colors.borderGreen,
  },
  '&.Mui-disabled': {
    border: '1px solid',
    color: colors.disabledGray,
  },
}));
