import { Button, Popover } from '@mui/material';
import { styled } from '@mui/material/styles';

import { colors } from 'styles/globalStyles';

export const StyledPopover = styled(Popover)(() => ({
  '& .MuiPopover-paper': {
    boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.16)',
    marginTop: '8px',
    border: `1px solid ${colors.filterGray}`,
    width: '685px',
  },
}));

export const PopoverContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  margin: '16px 20px',
});

export const ButtonsBlock = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

export const FiltersBlock = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  gap: '12px',
  alignItems: 'center',
});

export const FormWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
});

export const WhereBlock = styled('div')({
  width: '43px',
});

export const StyledOutlinedButton = styled(Button)({
  '&': {
    border: '1px solid',
    gap: '5px',
    color: colors.black,
    borderColor: colors.borderGray,
  },
  '&:hover': {
    backgroundColor: colors.hoverGray,
    color: colors.black,
    borderColor: colors.borderGray,
  },
});
