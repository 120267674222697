import { request } from 'api/instance';

export const PayrollCompanyAPI = {
  getAll: async(name?: string, acronyms?: string[], facility_names?: string[]) => {
    try {
      const response = await request({
        url: 'v1/payroll_companies',
        method: 'GET',
        params: {
          name,
          acronyms,
          facility_names,
        },
      });
      return response.data.data;
    } catch (error: any) {
      return error?.response;
    }
  },
};
