import { createTheme } from '@mui/material';
import { fontFamily } from './globalStyles';
import { colors } from 'styles/globalStyles';

export const theme = {
  typography: {
    fontFamily: fontFamily,
    h1: {
      display: 'flex',
      justifyContent: 'left',
      fontSize: '26px',
      fontWeight: '600',
      textTransform: 'capitalize' as const,
      color: colors.primaryBlue,
    },
    h2: {
      display: 'flex',
      justifyContent: 'left',
      fontSize: '14px',
      fontWeight: '500',
      textTransform: 'uppercase' as const,
      color: colors.primaryBlue,
      marginBottom: '2px',
    },
  },
  palette: {
    primary: {
      main: colors.primaryBlue,
    },
    error: {
      main: colors.hoveredTextRed,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          maxHeight: '36px',
          fontWeight: '400',
          padding: '9px 12px',
          textTransform: 'none' as const,
          boxShadow: 'none',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          color: colors.textGray,
          lineHeight: '18px',
          focused: {
            color: colors.primaryBlue,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderColor: colors.borderGray,
          disabled: {
            borderColor: colors.disabledBorderGray,
            backgroundColor: colors.inputDisabledGray,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginTop: 0,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: colors.hoveredTextRed,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          boxShadow: '0px 5px 5px -3px rgba(0, 0, 0, 0.20), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
        },
        noOptions: {
          color: colors.black,
          padding: '14px 16px',
          fontSize: '14px',
        },
      },
    },
  },
};

export default createTheme(theme);
