import React from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';


interface CustomNumericProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  maxValue: number;
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomNumericProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, maxValue, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
        allowNegative={false}
        isAllowed={(values) => {
          const {floatValue} = values;
          return typeof floatValue === 'undefined' || Number(floatValue) <= (maxValue) ;
        }}
      />
    );
  },
);

export default NumericFormatCustom;
