import { IconButton } from '@mui/material';
import { ChipWrapper } from './styledComponents';
import { CustomIcon } from 'components/UIComponents';

interface Props {
  children: React.ReactNode;
  onClose: Function;
}

const Chip = ({ children, onClose }: Props) => {
  return (
    <ChipWrapper>
      {children}
      <div style={{marginLeft: '4px'}}>
        <IconButton
          aria-label="close"
          color='inherit'
          onClick={() => onClose()}
          sx={{ padding: 0 }}
        >
          <CustomIcon name="close" size="x-small"/>
        </IconButton>
      </div>
    </ChipWrapper>
  );
};

export default Chip;
