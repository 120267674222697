import { useEffect, useState } from 'react';

const useData = (apiCall: (params: any) => Promise<any>, params?: any) => {
  const [data, setData] = useState<any>(null);
  useEffect(() => {
    let ignore = false;
    apiCall(params)
      .then(data => {
        if (!ignore) {
          setData(data);
        };
      });
    return () => {
      ignore = true;
    };
  }, [apiCall, JSON.stringify(params)]);
  return data;
};

export default useData;
