export const payrollDetailsNamesMapper = {
  engagement: 'Engagement',
  fte: 'FTE',
  amendment_notes: 'Amendment Notes',
  execution_date: 'Execution Date',
  effective_date: 'Effective Date',
  contract_term: 'Contract Term',
  staff_category: 'Staff Category',
  contracted_hours: 'Contracted Hours',
  weeks_of_service: 'Weeks of Service',
  paid_time_off: 'Paid Time-Off, days',
  annual_rate: 'Annual Rate',
  bi_weekly: 'Bi-Weekly',
  weekly: 'Weekly',
  hourly_rate: 'Hourly Rate',
  notes: 'Notes',
  contracted_frequency: 'Contracted Frequency',
  payroll_frequency: 'Payroll Frequency',
  malpractice_deduction: 'Malpractice Deduction',
  type: 'Type',
};
