import { styled } from '@mui/material/styles';
import { colors } from 'styles/globalStyles';

export const TableWithSidebarWrapper = styled('div')({
  display: 'flex',
  background: colors.white,
  flexGrow: 1,
  overflow: 'auto',
  padding: '20px',
});
