import { styled } from '@mui/material/styles';

interface StyledPageContainerProps {
  type: 'normal' | 'wide';
}

export const StyledPageContainer = styled('div')(({ type }: StyledPageContainerProps) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  width: type === 'normal' ? '940px' : '100%',
  margin: type === 'normal' ? '0 auto' : '0',
  padding: type === 'normal' ? '0' : '0 30px',
  boxSizing: 'border-box',
  marginTop: '50px',
}));
