import { IContractedHour } from 'interfaces/CompensationGrid/IContractedHour';

import { StyledContainer } from './styledComponents';
import { Link } from '@mui/material';

interface Props {
  compensationGridName: string,
  staffCatergory: string,
  selectedContractedHour: IContractedHour,
  compensationGridId?: number,
}

const AppliedCompensationGridInfo = ({
  compensationGridName,
  staffCatergory,
  selectedContractedHour,
  compensationGridId = undefined,
}: Props) => {
  return (
    <StyledContainer>
      <div>
        Comp Grid
        { compensationGridId ?
          <span className='fw-600'> <Link href={`/compensation_grids/${compensationGridId}`} target="_blank">
            "{compensationGridName}"
          </Link> </span>
          :
          <span className='fw-600'> "{compensationGridName}" </span>}
        was applied to the contract
      </div>
      {staffCatergory && <div>Comp Grid staff category is <span className='fw-600'>"{staffCatergory}"</span></div> }
      <div>Comp Grid contracted hours are <span className='fw-600'>"{selectedContractedHour.title}"</span></div>
    </StyledContainer>
  );
};

export default AppliedCompensationGridInfo;
