import { styled } from '@mui/material/styles';
import { colors } from 'styles/globalStyles';

export const TableWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  marginTop: '12px',
  overflow: 'hidden',
});

export const PageWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100dvh - 70px)',
});

export const TotalCountBlock = styled('div')({
  backgroundColor: colors.primaryBlue,
  minHeight: '34px',
  color: colors.white,
  paddingLeft: '17px',
  display: 'flex',
  alignItems: 'center',
});
