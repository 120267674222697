import { styled } from '@mui/material/styles';
import { colors } from 'styles/globalStyles';

export const FacilitiesContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  gap: '1px',
});

export const FacilitiesText = styled('div')({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

export const FacilitiesNumber = styled('div')({
  fontWeight: '600',
  color: colors.primaryBlue,
});
