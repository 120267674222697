import { styled } from '@mui/material/styles';
import { colors } from 'styles/globalStyles';

export const StyledSectionContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: '15px 0',
  gap: '16px 0',
  padding: '20px 20px 30px 20px',
  backgroundColor: colors.white,
  borderRadius: '4px',
  boxShadow: '0px -3px 31px 0px rgba(18, 38, 63, 0.02)',
});
