import { ICompensationGridDetails } from 'interfaces/CompensationGrid/ICompensationGridDetails';

import {
  SectionContainer,
  DetailsContainer,
  DetailsWrapper,
} from 'components/base';

import formatCurrency from 'helpers/formatCurrency';
import ItalicContainer from 'components/base/ItalicContainer';
import { TitleContainter, ListWrapper, TitleWrapper } from '../styledComponents';
import { TextButtonWithPopover } from 'components/UIComponents';

interface Props {
  compensationGrid: ICompensationGridDetails,
  addToMemoCallback?: any,
}

const CallTermsInfo = ({
  compensationGrid,
  addToMemoCallback,
}: Props) => {
  return (
    <SectionContainer title="Call Terms">
      <ListWrapper>
        {compensationGrid.call_terms.map((callTerm, index) => (
          <DetailsWrapper key={index}>
            <TitleWrapper>
              <TitleContainter data-testid="call_terms_title">
                {callTerm.title}
              </TitleContainter>
              {addToMemoCallback &&
                <TextButtonWithPopover
                  onClick={() => addToMemoCallback(callTerm)}
                  color='blue'
                  popoverText='Added'
                >
                  Add to Memo
                </TextButtonWithPopover>}
            </TitleWrapper>
            <DetailsContainer
              name="call_terms_hours"
              label="Hours"
              skipPermissions
              width="wide"
            >
              {callTerm.hours}
            </DetailsContainer>
            <DetailsContainer
              name="call_terms_amount"
              label="Amount"
              skipPermissions
              width="wide"
            >
              {formatCurrency(callTerm.amount)}
            </DetailsContainer>
            <DetailsContainer
              name="call_terms_call_in"
              label="Call-In"
              skipPermissions
              width="wide"
            >
              {callTerm.call_in}
            </DetailsContainer>
            {callTerm.notes && (
              <ItalicContainer
                name="call_terms_notes"
                skipPermissions
                width="wide"
              >
                {callTerm.notes}
              </ItalicContainer>
            )}
          </DetailsWrapper>
        ))}
      </ListWrapper>
    </SectionContainer>
  );
};

export default CallTermsInfo;
