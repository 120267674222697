import { styled } from '@mui/material/styles';
import { colors } from 'styles/globalStyles';

export const ErrorContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '270px',
  alignItems: 'center',
});

export const TitleContainer = styled('div')({
  fontSize: '36px',
  color: colors.textGray,
});

export const ErrorTextContainer = styled('div')({
  fontSize: '22px',
  color: colors.textGray,
  width: '380px',
  textAlign: 'center',
});
