import { StyledTextButton } from './styledComponents';

interface TextButtonProps {
  onClick?: Function;
  disabled?: boolean;
  children: React.ReactNode;
  color?: 'red' | 'blue';
  href?: string;
}

const TextButton = ({ onClick, children, disabled = false, color='red', href }: TextButtonProps) => {
  return (
    <StyledTextButton href={href} variant="text" disabled={disabled} onClick={() => onClick?.()} buttoncolor={color}>
      {children}
    </StyledTextButton>
  );
};

export default TextButton;
