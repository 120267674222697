import { IconButton, Link } from '@mui/material';

import { SidebarContainer, SidebarHeader, SidebarTitle, ContentContainer } from './styledComponents';
import { CustomIcon } from 'components/UIComponents';

interface Props {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  children: React.ReactNode
  title: string;
  titleLink: string;
  setActiveRowIndex: any;
}
const Sidebar = ({visible, setVisible, children, title, titleLink, setActiveRowIndex}: Props) => {
  const handleClose = () => {
    setVisible(false);
    setActiveRowIndex(undefined);
  };

  return (
    <SidebarContainer visible={visible}>
      <SidebarHeader>
        <SidebarTitle><Link href={titleLink}>{title}</Link></SidebarTitle>
        <IconButton aria-label='close' onClick={handleClose} sx={{ padding: '5px' }}>
          <CustomIcon name='close' size='small'/>
        </IconButton>
      </SidebarHeader>
      <ContentContainer>
        {children}
      </ContentContainer>
    </SidebarContainer>
  );
};

export default Sidebar;
