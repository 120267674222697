import { InputBase, CircularProgress, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { colors } from 'styles/globalStyles';

export const StyledInputBase = styled(InputBase)(() => {
  return ({
    '&': {
      width: '272px',
      height: '36px',
      border: '1px solid',
      borderColor: colors.borderGray,
      borderRadius: '4px',
      fontSize: '14px',
      lineHeight: 'normal',
      paddingLeft: '8px',
    },
    '& .MuiInputBase-input::placeholder': {
      color: colors.textGray,
      opacity: 1,
    },
    '&:hover': {
      borderColor: colors.black,
    },
    '&.Mui-focused': {
      borderColor: colors.primaryBlue,
    },
    '&.Mui-disabled': {
      borderColor: colors.disabledBorderGray,
      backgroundColor: colors.inputDisabledGray,
    },
    '&.Mui-disabled input': {
      WebkitTextFillColor: colors.textGray,
    },
  });
});

export const StyledCircularProgress = styled(CircularProgress)(() => {
  return ({
    '&': {
      marginRight: '8px',
    },
  });
});

export const StyledIconButton = styled(IconButton)(() => {
  return ({
    '&': {
      marginLeft: '-8px',
    },
  });
});
