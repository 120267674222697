import { cloneDeep, omit } from 'lodash';
import { ICompensationGridDetails } from 'interfaces/CompensationGrid/ICompensationGridDetails';

const prepareCompensationGridFormData = (values: ICompensationGridDetails) => {
  let formData = cloneDeep(values);

  formData.additional_compensations = removeEmptyObjects(formData.additional_compensations);
  formData.stipends = removeEmptyObjects(formData.stipends);
  formData.call_terms = removeEmptyObjects(formData.call_terms);
  formData.compensation_scales = formData.compensation_scales.map(compensationScale => ({
    ...compensationScale,
    compensation_rates: removeEmptyObjects(compensationScale.compensation_rates),
  }));
  formData.compensation_scales.forEach((compensationScale) => {
    if (compensationScale.rate_type !== 'tiered') {
      delete compensationScale.step_increase;
    };
    if(formData.engagement === 'per_diem') {
      delete compensationScale.staff_category;
    };
  });
  return formData;
};

const keysNotToCheck = ['id', 'compensation_grid_id', 'created_at', 'updated_at', 'compensation_scale_id'];

const removeEmptyObjects = (data: Array<any>) => (
  data.filter((object) => Object.values(omit(object, keysNotToCheck)).filter(value => ![null, '', undefined].includes(value)).length !== 0)
);

export default prepareCompensationGridFormData;
