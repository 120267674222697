import dayjs from 'dayjs';

const calculateExperience = (value: string) => {
  if (value === null) return '';
  const diffInMonths = dayjs().diff(new Date(value), 'month');
  const numberOfMonths = 12;
  const years = Math.floor(diffInMonths / numberOfMonths);
  const months = diffInMonths % numberOfMonths;
  return `${years} yrs ${months} mos`;
};

export default calculateExperience;
