import { Alert, Snackbar } from '@mui/material';
import { styled } from '@mui/material/styles';
import { colors } from 'styles/globalStyles';

export const StyledSnackbar = styled(Snackbar)(() => ({
  '&': {
    maxWidth: '728px',
    bottom: '100px',
    left: '100px',
  },
}));

export const StyledAlert = styled(Alert)(() => ({
  '&': {
    width: '100%',
    border: '1px solid',
    boxShadow: '0px 4px 13.3px 0px rgba(134, 134, 134, 0.19)',
    fontWeight: '500',
  },
  '&.MuiAlert-standardSuccess': {
    color: colors.successAlertTextGreen,
    borderColor: colors.successAlertBorderGreen,
    backgroundColor: colors.successAlertGreen,
  },
  '&.MuiAlert-standardInfo': {
    color: colors.infoAlertTextBlue,
    borderColor: colors.infoAlertBorderBlue,
    backgroundColor: colors.infoAlertBlue,
  },
  '&.MuiAlert-standardError': {
    color: colors.errorAlertTextRed,
    borderColor: colors.errorAlertBorderRed,
    backgroundColor: colors.errorAlertRed,
  },
  '&.MuiAlert-standardWarning': {
    color: colors.warningAlertTextYellow,
    borderColor: colors.warningAlertBorderYellow,
    backgroundColor: colors.warningAlertYellow,
  },
}));
