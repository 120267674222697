import { PageContainer, PageHeader } from 'components/base';
import ClinicianForm from 'containers/Clinicians/ClinicianForm';

const NewFacility = () => {
  return (
    <PageContainer type="normal" style={{marginBottom: '66px'}}>
      <PageHeader pageTitle='Adding Clinician'/>
      <ClinicianForm />
    </PageContainer>
  );
};

export default NewFacility;
