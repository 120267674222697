import { ReactComponent as Search } from 'assets/icons/search.svg';
import { ReactComponent as Trash } from 'assets/icons/trash.svg';
import { ReactComponent as Exit } from 'assets/icons/exit.svg';
import { ReactComponent as Edit } from 'assets/icons/edit.svg';
import { ReactComponent as Calendar } from 'assets/icons/calendar.svg';
import { ReactComponent as Eye } from 'assets/icons/eye.svg';
import { ReactComponent as CrossedEye } from 'assets/icons/crossed-eye.svg';
import { ReactComponent as Clipboard } from 'assets/icons/clipboard.svg';
import { ReactComponent as Close } from 'assets/icons/close.svg';

const iconsMap: { [key: string]: any } = {
  trash: Trash,
  search: Search,
  exit: Exit,
  edit: Edit,
  calendar: Calendar,
  eye: Eye,
  crossedEye: CrossedEye,
  clipboard: Clipboard,
  close: Close,
};

export default iconsMap;
