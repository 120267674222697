import { PageContainer, PageHeader } from 'components/base';
import CompensationGridForm from 'containers/CompensationGrids/CompensationGridForm';

const EditCompensationGrid = () => {
  return (
    <PageContainer type="normal" style={{marginBottom: '66px'}}>
      <PageHeader pageTitle='Editing Compensation Grid'/>
      <CompensationGridForm />
    </PageContainer>
  );
};

export default EditCompensationGrid;
