import { contractEngagementTypeOptions, frequencyOptions } from 'constants/contractsConstants';
import { calculateBiWeeklyRate, calculateHourlyRate, calculateWeeklyRate } from 'helpers/calculateRates';
import formatCurrency from 'helpers/formatCurrency';
import { IContractedHour } from 'interfaces/CompensationGrid/IContractedHour';
import { ContractType } from 'types';

interface IDiffObj {
  [propName: string]: {
    oldValue: string,
    newValue: string,
  }
};

const keysToSkip = ['execution_date', 'effective_date', 'type', 'amendment_notes', 'id', 'created_at', 'updated_at',
  'created_by_id', 'updated_by_id'];

const amendmentObjectsDiff = (
  initialAmendment: any,
  newValues: any,
  contract_type: ContractType,
  selectedContractedHour: IContractedHour,
) => {
  const diff: IDiffObj = {};

  for (const key in newValues) {
    if (!keysToSkip.includes(key) && initialAmendment.hasOwnProperty(key) && String(newValues[key]) !== String(initialAmendment[key])) {
      if(key !== 'annual_rate') {
        diff[key] = {
          oldValue: initialAmendment[key],
          newValue: newValues[key],
        };
      }

      if(key === 'engagement') {
        diff[key] = {
          oldValue: contractEngagementTypeOptions.find((option) => option.id === initialAmendment[key])?.name as string,
          newValue: contractEngagementTypeOptions.find((option) => option.id === newValues[key])?.name as string,
        };
      }

      if(['contracted_frequency', 'payroll_frequency'].includes(key)) {
        diff[key] = {
          oldValue: frequencyOptions.find((option) => option.id === initialAmendment[key])?.name as string,
          newValue: frequencyOptions.find((option) => option.id === newValues[key])?.name as string,
        };
      }

      if (key === 'annual_rate') {
        const oldAnnualRate = formatCurrency(initialAmendment[key]);
        const newAnnualRate = formatCurrency(newValues[key]);
        if (oldAnnualRate !== newAnnualRate) {
          diff[key] = {
            oldValue: oldAnnualRate,
            newValue: newAnnualRate,
          };
        };
      }

      if(['weeks_of_service', 'annual_rate'].includes(key)) {
        const oldBiWeeklyRate = formatCurrency(calculateBiWeeklyRate(
          Number(initialAmendment.annual_rate),
          contract_type,
          Number(initialAmendment.weeks_of_service),
        ));

        const newBiWeeklyRate = formatCurrency(calculateBiWeeklyRate(
          Number(newValues.annual_rate),
          contract_type,
          Number(newValues.weeks_of_service),
        ));

        if (oldBiWeeklyRate !== newBiWeeklyRate) {
          diff['bi_weekly'] = {
            oldValue: oldBiWeeklyRate,
            newValue: newBiWeeklyRate,
          };
        };

        const oldWeeklyRate = formatCurrency(calculateWeeklyRate(
          Number(initialAmendment.annual_rate),
          contract_type,
          Number(initialAmendment.weeks_of_service),
        ));

        const newWeeklyRate = formatCurrency(calculateWeeklyRate(
          Number(newValues.annual_rate),
          contract_type,
          Number(newValues.weeks_of_service),
        ));

        if (oldWeeklyRate !== newWeeklyRate) {
          diff['weekly'] = {
            oldValue: oldWeeklyRate,
            newValue: newWeeklyRate,
          };
        }

        const oldHourlyRate = formatCurrency(calculateHourlyRate(
          Number(initialAmendment.annual_rate),
          contract_type,
          Number(initialAmendment.weeks_of_service),
          selectedContractedHour,
          initialAmendment.fte,
        ));

        const newHourlyRate = formatCurrency(calculateHourlyRate(
          Number(newValues.annual_rate),
          contract_type,
          Number(newValues.weeks_of_service),
          selectedContractedHour,
          newValues.fte,
        ));

        if (oldHourlyRate !== newHourlyRate) {
          diff['hourly_rate'] = {
            oldValue: oldHourlyRate,
            newValue: newHourlyRate,
          };
        }
      }

      if (key === 'hourly_rate') {
        diff[key] = {
          oldValue: formatCurrency(initialAmendment[key]),
          newValue: formatCurrency(newValues[key]),
        };
      }
    }
  }

  return diff;
};

export default amendmentObjectsDiff;
