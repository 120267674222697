import { useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';

import { PageContainer, ListOfRecords } from 'components/base';

import { UserAPI } from 'api/UserAPI';
import { PermissionsAPI } from 'api/PermissionsAPI';

import { ITableColumn } from 'interfaces/ITableColumn';
import { IUsersTable } from 'interfaces/User/IUsersTable';

import columnsWithRecalculatedWidth from 'helpers/columnsWithRecalculatedWidth';
import useData from 'hooks/useData';
import useAuth from 'hooks/useAuth';
import { FacilitiesContainer, FacilitiesText, FacilitiesNumber } from './styledComponents';

const facilitiesData = (facilities: Array<string>) => {
  return (
    <FacilitiesContainer>
      <FacilitiesText>
        {facilities.join(', ')}
      </FacilitiesText>
      {facilities.length > 1 &&
        <FacilitiesNumber>({facilities.length})</FacilitiesNumber>
      }
    </FacilitiesContainer>
  );
};

const ListOfFacilities = () => {
  const { user } = useAuth();
  const editAllowed = user?.allowed_write_resources.includes('users');
  const fieldsPermissionsData = useData(PermissionsAPI.getAllowedAttributes, 'User');

  const { instance } = useMsal();

  const [columns, setColumns] = useState<ITableColumn[]>([
    { dataField: 'first_name', text: 'First Name', headStyle: { width: '8.2%' }},
    { dataField: 'last_name', text: 'Last Name', headStyle: { width: '8.2%' }},
    { dataField: 'email', text: 'Email', headStyle: { width: '17.2%' } },
    { dataField: 'facility', text: 'Facility', headStyle: { width: '27.8%' } },
    { dataField: 'role', text: 'Role', headStyle: { width: '8.2%' } },
    { dataField: 'description', text: 'Description', headStyle: { width: '21.6%' } },
  ]);

  useEffect(() => {
    if (fieldsPermissionsData) {
      setColumns(columnsWithRecalculatedWidth(columns, fieldsPermissionsData.read_allowed));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldsPermissionsData]);

  const dataFormatter = (item: IUsersTable) => ({
    id: item.id,
    first_name: item.first_name,
    last_name: item.last_name,
    email: item.email,
    facility: facilitiesData(item.facility_names),
    role: item.role_name,
    description: item.description,
  });

  return(
    <PageContainer type="wide" style={{marginBottom: '20px'}}>
      <ListOfRecords
        tableProps={{
          name: 'users',
          columns: columns as ITableColumn[],
          editAction: editAllowed,
          withActions: editAllowed,
          rowsAsLinks: false,
        }}
        pageTitle='Users'
        dataFormatter={dataFormatter}
        dataProvider={UserAPI.getAll}
        msalInstance={instance}
        searchProps={{
          allowSearch: false,
        }}
      />
    </PageContainer>
  );
};

export default ListOfFacilities;
