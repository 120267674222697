import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

import { colors } from 'styles/globalStyles';

interface StyledTextButtonProps {
  buttoncolor: 'red' | 'blue';
};

const buttonColors = {
  'red': {
    color: colors.red,
    hoverColor: colors.hoveredTextRed,
  },
  'blue': {
    color: colors.primaryBlue,
    hoverColor: colors.hoveredBlue,
  },
};

export const StyledTextButton = styled(Button)(({ buttoncolor }: StyledTextButtonProps) => ({
  '&': {
    color: buttonColors[buttoncolor].color,
    gap: '5px',
    padding: 0,
    minWidth: 0,
  },
  '&:hover': {
    color: buttonColors[buttoncolor].hoverColor,
    backgroundColor: 'inherit',
    boxShadow: 'none',
    textDecoration: 'underline',
  },
  '&.Mui-disabled': {
    color: colors.disabledGray,
  },
  '&& .MuiTouchRipple-child': {
    backgroundColor: 'inherit',
  },
}));
