import { styled } from '@mui/material/styles';

export const CompensationRatesHeading = styled('div')({
  fontWeight: 500,
  marginTop: '18px',
  marginBottom: '8px',
});

export const SectionsWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '60px 0',
});

export const DeleteCaterogyWrapper = styled('div')({
  marginTop: '16px',
});
