import { IClinicianContractFilterQueryParams } from 'interfaces/ClinicianContract/IClinicianContractFilterQueryParams';
import { request } from '../instance';

export const ReportsAPI = {

  generateReport: async(search: string, filterQuery: IClinicianContractFilterQueryParams, fields: string[]) => {
    const response = await request({
      url: 'v1/clinician_contracts/generate_report',
      method: 'GET',
      params: {
        search,
        ...filterQuery,
        fields,
      },
    }, { responseType: 'blob' });

    return response;
  },

  saveFieldSelection: async (title: string, payload: string[]) => {
    try {
      const response = await request({
        url: 'v1/clinician_contracts/report_fields_selections',
        method: 'POST',
        data: {
          data: {
            report_fields_selection: {
              title, payload,
            },
          },
        },
      });

      return response;
    } catch (error: any) {
      return error?.response;
    };
  },

  getSavedFieldSelections: async () => {
    const response = await request({
      url: 'v1/clinician_contracts/report_fields_selections',
      method: 'GET',
    });

    return response.data.data;
  },

  deleteSavedFieldSelection: async (id: number) => {
    const response = await request({
      url: `v1/clinician_contracts/report_fields_selections/${id}`,
      method: 'DELETE',
    });

    return response.data.data;
  },

  getAvailableFieldsList: async () => {
    const response = await request({
      url: 'v1/clinician_contracts/report_fields_selections/list_fields',
      method: 'GET',
    });

    return response.data.data;
  },
};
