import { createContext, useState } from 'react';

interface IModalContext {
  modalOpen: Record<string, boolean>;
  onModalOpen: (id: string) => void;
  onModalClose: (id: string) => void;
  closeAll: () => void;
}

export const ModalContext = createContext<IModalContext>({
  modalOpen: {},
  onModalOpen: (id: string) => {},
  onModalClose: (id: string) => {},
  closeAll: () => {},
});

export const ModalState = ({ children }: { children: React.ReactNode }) => {
  const [modals, setModals] = useState<Record<string, boolean>>({});

  const onModalOpen = (id: string) => {
    setModals((prevModals) => ({ ...prevModals, [id]: true }));
  };

  const onModalClose = (id: string) => {
    setModals((prevModals) => ({ ...prevModals, [id]: false }));
  };

  const closeAll = () => {
    setModals({});
  };

  return (
    <ModalContext.Provider value={{ modalOpen: modals, onModalOpen, onModalClose, closeAll }}>
      {children}
    </ModalContext.Provider>
  );
};
