import { useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { SubmitButton } from 'components/UIComponents';

import { StyledMenu, StyledMenuItem } from './styledComponents';


interface IMenuAciton {
  title: string;
  action: () => void;
}
interface Props {
  buttonLabel: string,
  actions: IMenuAciton[]
}

const ButtonMenu = ({ buttonLabel, actions }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <SubmitButton
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {buttonLabel}{open ? <ArrowDropUpIcon sx={{ marginRight: '-8px' }}/> : <ArrowDropDownIcon sx={{ marginRight: '-8px' }}/> }
      </SubmitButton>
      <StyledMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {actions.map(({ title, action }, index) => (
          <StyledMenuItem
            key={index}
            onClick={() => {
              action();
              handleClose();
            }}
          >
            {title}
          </StyledMenuItem>
        ))}
      </StyledMenu>
    </>
  );
};

export default ButtonMenu;
