import { forwardRef } from 'react';
import { TableVirtuoso, TableComponents } from 'react-virtuoso';
import { TableRow, TableContainer, TableHead, Table, TableBody } from '@mui/material';
import { ITableColumn } from 'interfaces/ITableColumn';
import { IClinicianContractListItem } from 'interfaces/ClinicianContract/IClinicianContractListItem';

import BodyTableCellWithData from 'components/base/TableWithLazyLoad/BodyTableCellWithData';
import NoResultsBlock from 'components/base/TableWithLazyLoad/NoResultsBlock';

import LoadingBlock from '../LoadingBlock';
import { HeadTableCell, BodyTableRow, Wrapper } from './styledComponents';

interface Props {
  loadMore: () => void;
  setActiveRowIndex: (index: number) => void;
  activeRowIndex: number | undefined;
  setSidebarData: (index: number) => void;
  data: any[];
  search: string;
  loading: boolean;
}

const columns: ITableColumn[] = [
  {
    dataField: 'clinician_full_title',
    text: 'Clinician',
    headStyle: { width: '24.6%' },
  },
  {
    dataField: 'entity_name',
    text: 'Entity Name',
    headStyle: { width: '23.3%' },
  },
  {
    dataField: 'facility',
    text: 'Facility',
    headStyle: { width: '23.3%' },
  },
  { dataField: 'contract_type', text: 'Type', headStyle: { width: '7.2%' } },
  {
    dataField: 'engagement',
    text: 'Engagement',
    headStyle: { width: '9.8%' },
  },
  {
    dataField: 'status',
    text: 'Status',
    headStyle: { width: '70px' },
  },
  {
    dataField: 'actions',
    text: '',
    headStyle: { width: '5.8%' },
  },
];

const fixedHeaderContent = () => {
  return (
    <TableRow>
      {columns.map((column, index) => (
        <HeadTableCell key={index} style={column.headStyle}>
          {column.text}
        </HeadTableCell>
      ))}
    </TableRow>
  );
};

const rowContent = (row: IClinicianContractListItem, search: string) => {
  return (
    <>
      {columns.map((column, index) => (
        <BodyTableCellWithData
          search={search}
          key={index}
          content={row[column.dataField as keyof IClinicianContractListItem]}
        />
      ))}
    </>
  );
};

const VirtuosoTableComponents: TableComponents<IClinicianContractListItem, string> = {
  Scroller: forwardRef<HTMLDivElement>((props, ref) => (
    <TableContainer {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table {...props} stickyHeader aria-label="sticky table" sx={{tableLayout: 'fixed' }}/>
  ),
  TableHead,
  TableBody: forwardRef<HTMLTableSectionElement>((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};

const VirtualTable = ({ loadMore, data, setSidebarData, activeRowIndex, setActiveRowIndex, search, loading }: Props) => {

  const handleRowClick = (e: any, row: IClinicianContractListItem, index: number) => {
    e.stopPropagation();
    setActiveRowIndex(index);
    return setSidebarData(row.id);
  };

  return (
    <Wrapper>
      <TableVirtuoso
        data={data}
        components={{...VirtuosoTableComponents,
          TableRow: ({ item: _item, ...props }) =>
            <BodyTableRow
              rowsAsLinks
              onClick={(e) => handleRowClick(e, _item, props['data-index'])}
              selected={activeRowIndex === props['data-index']}{...props}
            />,
        }}
        fixedHeaderContent={fixedHeaderContent}
        itemContent={(index, row) => rowContent(row, search)}
        endReached={loadMore}
        increaseViewportBy={200}
      />
      {loading && <LoadingBlock/> }
      {data.length === 0 && !loading ? <NoResultsBlock searchText={search} /> : ''}
    </Wrapper>
  );

};

export default VirtualTable;
