import { MenuItem, OutlinedInput, FormHelperText, InputLabel } from '@mui/material';

import { useField } from 'formik';

import { ISelectOption } from 'interfaces/ISelectOption';
import { useFieldsPermissions } from 'context/FieldsPermissionsContext';
import { WidthType } from 'types';

import { StyledSelect, StyledFormControl } from './styledComponents';

interface SelectProps {
  label: string;
  name: string;
  options: ISelectOption[];
  onChange?: any;
  placeholder?: string;
  required?: boolean;
  emptyOptionLabel?: string;
  allowEmpty?: boolean;
  disabled?: boolean;
  width?: WidthType;
  permissionKey?: string;
  skipPermissionsCheck?: boolean;
  onBlur?: any;
  styles?: {
    width?: string
  }
}

const Select = ({
  onChange,
  label,
  name,
  options = [],
  placeholder = '',
  required = false,
  emptyOptionLabel = 'None',
  allowEmpty = true,
  disabled = false,
  width = 'normal',
  permissionKey = name,
  skipPermissionsCheck = false,
  onBlur,
  styles,
}: SelectProps) => {
  const [field, meta, { setTouched }] = useField(name);
  const { fieldsPermissions } = useFieldsPermissions();

  return (
    <>
      {(skipPermissionsCheck || fieldsPermissions.read_allowed.includes(permissionKey)) &&
      <StyledFormControl styles={styles} size="small" error={meta.touched && !!meta.error} required={required} width={width}>
        <InputLabel>
          {label}
        </InputLabel>
        <StyledSelect
          {...field}
          data-testid={`select-${name}`}
          variant="standard"
          error={meta.touched && !!meta.error}
          onChange={(e) => {
            onChange?.(e.target.value, field.value);
            field.onChange(e);
          }}
          onBlur={() => {
            setTouched(true); // To validate/show errors for field after trimming
            onBlur?.();
          }}
          placeholder={placeholder}
          disabled={disabled || !(fieldsPermissions.update_allowed.includes(permissionKey) || skipPermissionsCheck)}
          input={<OutlinedInput label={label} />}
        >
          {!required && allowEmpty && (
            <MenuItem value="" key="none">
              <em>{emptyOptionLabel}</em>
            </MenuItem>
          )}
          {options.map(({ id, name, disabled = false }) => (
            <MenuItem disabled={disabled} key={String(id)} value={id}>
              <span>{name}</span>
            </MenuItem>
          ))}
        </StyledSelect>
        {
          meta.touched && meta.error && (
            <FormHelperText>{meta.error}</FormHelperText>
          )
        }
      </StyledFormControl>
      }
    </>
  );
};

export default Select;
