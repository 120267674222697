import dayjs from 'dayjs';
import { ClinicianAPI } from 'api/ClinicianAPI';
import { FacilityAPI } from 'api/FacilityAPI';
import { PayrollCompanyAPI } from 'api/PayrollCompanyAPI';
import { IPayrollCompany } from 'interfaces/IPayrollCompany';
import { ISelectOption } from 'interfaces/ISelectOption';

import { IClinicianContractFilterQueryParams } from 'interfaces/ClinicianContract/IClinicianContractFilterQueryParams';
import { IClinicianContractFilterFormParams } from 'interfaces/ClinicianContract/IClinicianContractFilterFormParams';

export const dynamicFilteringOptions = ['payroll_company_names', 'acronyms', 'facility_names'];

export const initialFormValues: IClinicianContractFilterFormParams = { filters: [] };

export const prepareFilterQuery = (values: IClinicianContractFilterFormParams) => {
  let query: IClinicianContractFilterQueryParams = {};

  values.filters.forEach(filter => {
    if (['execution_date', 'effective_date'].includes(filter.filteringOption)) {
      if (dayjs(filter.value).isValid()) {
        query[filter.filteringOption as 'execution_date' | 'effective_date'] = filter.value.format('YYYY-MM-DD');
        query[`${filter.filteringOption}_condition` as 'execution_date_condition' | 'effective_date_condition'] = filter.condition;
      }
    } else {
      if (filter.value.length > 0) {
        query[filter.filteringOption as keyof typeof query] = filter.value
          .filter((option: any) => option.id !== 'all').map((option: any) => option.id);
      }
    }
  });

  return query;
};

export const requestValuesDependingOnOption = async (values: IClinicianContractFilterFormParams, selectedValue: string) => {
  const acronyms = values.filters
    .find((filter) => filter.filteringOption === 'acronyms')?.value
    .filter((option: any) => option.id !== 'all')
    .map((val: ISelectOption) => val.name);
  const facilityNames = values.filters
    .find((filter) => filter.filteringOption === 'facility_names')?.value
    .filter((option: any) => option.id !== 'all')
    .map((val: ISelectOption) => val.name);
  const payrollCompanyNames = values.filters
    .find((filter) => filter.filteringOption === 'payroll_company_names')?.value
    .filter((option: any) => option.id !== 'all')
    .map((val: ISelectOption) => val.name);

  if (selectedValue === 'payroll_company_names') {
    const companies = await PayrollCompanyAPI.getAll(undefined, acronyms, facilityNames);
    return companies.map((company: IPayrollCompany) => ({id: company.name, name: company.name}));
  } else if (selectedValue === 'acronyms') {
    const acronyms = await FacilityAPI.getAcronyms(facilityNames, payrollCompanyNames);
    return acronyms.map((acronym: string) => ({id: acronym, name: acronym}));
  } else if (selectedValue === 'facility_names') {
    const facilityNames = await FacilityAPI.getNames(acronyms, payrollCompanyNames);
    return facilityNames.map((name: string) => ({id: name, name: name}));
  } else if (selectedValue === 'entity_names') {
    const names = await ClinicianAPI.getEntityNames();
    return names.map((name: string) => ({ id: name, name: name }));
  };
};
