import { styled } from '@mui/material/styles';
import { colors } from 'styles/globalStyles';


interface StyledButtonProps {
  active: boolean;
}

export const StyledButton = styled('span')(({ active }: StyledButtonProps) => ({
  cursor: 'pointer',
  color: active ? colors.black : '#ccc',
}));

export const StyledToolbar = styled('div')(() => ({
  position: 'relative',
  padding: '10px 0',
  margin: '0 -20px',
  borderBottom: '1px solid #eee',
  '& > *': {
    display: 'inline-block',
  },
  '& > *  *': {
    marginLeft: '15px',
  },
  '& svg': {
    marginLeft: 0,
  },
}));

export const StyledIcon = styled('span')(() => ({
  fontSize: '0',
  verticalAlign: 'text-bottom',
  '& svg': {
    width: '18px',
    height: '18px',
  },
}));

export const EditorWrapper = styled('div')(() => ({
  border: `1px solid ${colors.borderGray}`,
  borderRadius: '4px',
  padding: '0 20px',
}));
