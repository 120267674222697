import * as Yup from 'yup';
import { Dayjs } from 'dayjs';
import VALIDATION_MESSAGES from 'constants/validationMessages';
import { compTermNoteMaxLength, callTermTextMaxLength, minDecimalValue } from 'constants/fieldsLimits';

export const amendmentValidationSchema = (minEffectiveDate: Dayjs) => {
  return Yup.object({
    payroll_details: Yup.object({
      engagement: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
      fte: Yup.number().nullable().min(minDecimalValue, VALIDATION_MESSAGES.MIN_NUMBER(minDecimalValue)).when('engagement', {
        is: (val: string) => val === 'part_time',
        then: (scheme: any) => scheme.required(VALIDATION_MESSAGES.REQUIRED),
      }),
      execution_date: Yup.date()
        .nullable()
        .optional()
        .typeError('Invalid Date'),
      effective_date: Yup.date()
        .required(VALIDATION_MESSAGES.REQUIRED)
        .min(minEffectiveDate, VALIDATION_MESSAGES.MIN_EFFECTIVE_DATE)
        .typeError('Invalid Date'),
      contracted_hours: Yup.string()
        .nullable().optional().max(callTermTextMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(callTermTextMaxLength)),
      amendment_notes: Yup.string()
        .nullable().optional().max(compTermNoteMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(compTermNoteMaxLength)),
      notes: Yup.string()
        .nullable().optional().max(compTermNoteMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(compTermNoteMaxLength)),
      malpractice_deduction: Yup.string()
        .nullable().optional().max(callTermTextMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(callTermTextMaxLength)),
    }),
  });
};
