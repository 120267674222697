import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';

import { IFacility } from 'interfaces/Facility/IFacility';
import { IPayrollCompany } from 'interfaces/IPayrollCompany';

import { PageContainer, PageHeader, SectionContainer, DetailsContainer, DetailsWrapper, ActionsBar } from 'components/base';
import { TextButton, CustomIcon, OutlinedButton } from 'components/UIComponents';

import { useFieldsPermissions } from 'context/FieldsPermissionsContext';

import formatPhone from 'helpers/formatPhone';
import isSectionRendering from 'helpers/isSectionRendering';
import useData from 'hooks/useData';
import useAuth from 'hooks/useAuth';
import formatCurrency from 'helpers/formatCurrency';

import { FacilityAPI } from 'api/FacilityAPI';
import { PayrollCompanyAPI } from 'api/PayrollCompanyAPI';
import { PermissionsAPI } from 'api/PermissionsAPI';

const AdminWrapper = styled('div')({
  marginBottom: '4px',
});

const FacilityDetails = () => {
  const { facilityId } = useParams();
  const { setFieldsPermissions } = useFieldsPermissions();
  const { user } = useAuth();
  const editAllowed = user?.allowed_write_resources.includes('facilities');

  const [payrollCompany, setPayrollCompany] = useState<IPayrollCompany | undefined>(undefined);
  const fieldsPermissionsData = useData(PermissionsAPI.getAllowedAttributes, 'Facility');
  const facility = useData(FacilityAPI.getDetails, Number(facilityId)) as IFacility;
  const payrollCompanies = useData(PayrollCompanyAPI.getAll) as IPayrollCompany[];

  useEffect(() => {
    if (fieldsPermissionsData) {
      setFieldsPermissions(fieldsPermissionsData);
    }
  }, [setFieldsPermissions, fieldsPermissionsData]);

  useEffect(() => {
    if (!payrollCompany && payrollCompanies) {
      setPayrollCompany(payrollCompanies.find((pc: IPayrollCompany) => pc.id === facility?.payroll_company_id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facility, payrollCompanies]);

  if (!(facility && fieldsPermissionsData)) return null;

  return (
    <PageContainer style={{marginBottom: '66px'}}>
      <PageHeader pageTitle={facility.name}>
        { editAllowed &&
          <TextButton color='blue' href={`${facilityId}/edit?redirectTo=/facilities/${facilityId}`}>
            <CustomIcon size='normal' name='edit'/>
            Edit
          </TextButton>
        }
      </PageHeader>
      {isSectionRendering(fieldsPermissionsData.read_allowed, ['acronym', 'type', 'payroll_company_id']) &&
        <SectionContainer title='Facility Info'>
          <DetailsWrapper>
            <DetailsContainer name='acronym' label='Acronym'>{facility.acronym}</DetailsContainer>
            <DetailsContainer name='type' label='Type'>{facility.type}</DetailsContainer>
            <DetailsContainer name='payroll_company_id' label='Payroll Company'>{payrollCompany?.name}</DetailsContainer>
            <DetailsContainer name='payroll_company_id' label='EIN'>{payrollCompany?.ein}</DetailsContainer>
          </DetailsWrapper>
        </SectionContainer>
      }

      {isSectionRendering(fieldsPermissionsData.read_allowed, ['address']) &&
        <SectionContainer title='Facility Address'>
          <DetailsContainer name='address' label='Address'>{facility.address}</DetailsContainer>
        </SectionContainer>
      }

      {isSectionRendering(fieldsPermissionsData.read_allowed, ['limit_per_event', 'limit_yearly']) &&
      <SectionContainer title='Malpractice Limits'>
        <DetailsWrapper>
          <DetailsContainer name='limit_per_event' label='Per Event'>{formatCurrency(facility.limit_per_event, true)}</DetailsContainer>
          <DetailsContainer name='limit_yearly' label='Yearly Aggregated'>{formatCurrency(facility.limit_yearly, true)}</DetailsContainer>
        </DetailsWrapper>
      </SectionContainer>
      }

      {isSectionRendering(fieldsPermissionsData.read_allowed, ['admin1_name', 'admin1_email', 'admin1_cell', 'admin1_office',
        'admin2_name', 'admin2_email', 'admin2_cell', 'admin2_office']) &&
        <SectionContainer title='Admin Info'>
          {isSectionRendering(fieldsPermissionsData.read_allowed, ['admin1_name', 'admin1_email', 'admin1_cell', 'admin1_office']) &&
            <DetailsWrapper>
              <AdminWrapper>Admin 1</AdminWrapper>
              <DetailsContainer name='admin1_name' label='Name'>{facility.admin1_name}</DetailsContainer>
              <DetailsContainer name='admin1_email' label='Email'>{facility.admin1_email}</DetailsContainer>
              <DetailsContainer name='admin1_cell' label='Cell'>{formatPhone(facility.admin1_cell || '')}</DetailsContainer>
              <DetailsContainer name='admin1_office' label='Office'>
                {formatPhone(facility.admin1_office || '')}
                {facility.admin1_office && facility.admin1_office_ext && fieldsPermissionsData.read_allowed.includes('admin1_office_ext')
                  ? ` x${facility.admin1_office_ext}` : ''}
              </DetailsContainer>
            </DetailsWrapper>
          }
          {isSectionRendering(fieldsPermissionsData.read_allowed, ['admin2_name', 'admin2_email', 'admin2_cell', 'admin2_office']) &&
            <DetailsWrapper>
              <AdminWrapper>Admin 2</AdminWrapper>
              <DetailsContainer name='admin2_name' label='Name'>{facility.admin2_name}</DetailsContainer>
              <DetailsContainer name='admin2_email' label='Email'>{facility.admin2_email}</DetailsContainer>
              <DetailsContainer name='admin2_cell' label='Cell'>{formatPhone(facility.admin2_cell || '')}</DetailsContainer>
              <DetailsContainer name='admin2_office' label='Office'>
                {formatPhone(facility.admin2_office || '')}
                {facility.admin2_office && facility.admin2_office_ext && fieldsPermissionsData.read_allowed.includes('admin2_office_ext')
                  ? ` x${facility.admin2_office_ext}` : ''}
              </DetailsContainer>
            </DetailsWrapper>
          }
        </SectionContainer>
      }

      {isSectionRendering(fieldsPermissionsData.read_allowed, ['admin_assistant', 'office_coordinator', 'chief_md', 'chief_md_email',
        'chief_md_cell', 'assistant_chief_md', 'assistant_chief_md_email', 'assistant_chief_md_cell', 'chief_crna', 'chief_crna_email',
        'chief_crna_cell']) &&
        <SectionContainer title='Other Contacts'>
          {isSectionRendering(fieldsPermissionsData.read_allowed, ['admin_assistant', 'office_coordinator']) &&
            <DetailsWrapper>
              <DetailsContainer name='admin_assistant' label='Admin Assistant'>{facility.admin_assistant}</DetailsContainer>
              <DetailsContainer
                name='office_coordinator'
                label='Medical Office Coordinator'
              >
                {facility.office_coordinator}
              </DetailsContainer>
            </DetailsWrapper>
          }
          {isSectionRendering(fieldsPermissionsData.read_allowed, ['chief_md', 'chief_md_email', 'chief_md_cell']) &&
            <DetailsWrapper>
              <DetailsContainer name='chief_md' label='Chief MD'>{facility.chief_md}</DetailsContainer>
              <DetailsContainer name='chief_md_email' label='Chief MD Email'>{facility.chief_md_email}</DetailsContainer>
              <DetailsContainer name='chief_md_cell' label='Chief MD Cell'>{formatPhone(facility.chief_md_cell || '')}</DetailsContainer>
            </DetailsWrapper>
          }
          {isSectionRendering(fieldsPermissionsData.read_allowed, ['assistant_chief_md', 'assistant_chief_md_email',
            'assistant_chief_md_cell']) &&
            <DetailsWrapper>
              <DetailsContainer name='assistant_chief_md' label='Assistant Chief MD'>{facility.assistant_chief_md}</DetailsContainer>
              <DetailsContainer
                name='assistant_chief_md_email'
                label='Assistant Chief MD Email'
              >
                {facility.assistant_chief_md_email}
              </DetailsContainer>
              <DetailsContainer
                name='assistant_chief_md_cell'
                label='Assistant Chief MD Cell'
              >
                {formatPhone(facility.assistant_chief_md_cell || '')}
              </DetailsContainer>
            </DetailsWrapper>
          }

          {isSectionRendering(fieldsPermissionsData.read_allowed, ['chief_crna', 'chief_crna_email', 'chief_crna_cell']) &&
            <DetailsWrapper>
              <DetailsContainer name='chief_crna' label='Chief CRNA'>{facility.chief_crna}</DetailsContainer>
              <DetailsContainer name='chief_crna_email' label='Chief CRNA Email'>{facility.chief_crna_email}</DetailsContainer>
              <DetailsContainer
                name='chief_crna_cell'
                label='Chief CRNA Cell'
              >
                {formatPhone(facility.chief_crna_cell || '')}
              </DetailsContainer>
            </DetailsWrapper>
          }
        </SectionContainer>
      }
      <ActionsBar>
        <OutlinedButton href='/facilities'>
          Back
        </OutlinedButton>
      </ActionsBar>
    </PageContainer>
  );
};

export default FacilityDetails;
