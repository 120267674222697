import { styled } from '@mui/material/styles';
import { colors } from 'styles/globalStyles';

export const ChipWrapper = styled('div')({
  border: `1px solid ${colors.borderGray}`,
  borderRadius: '23px',
  padding: '0 10px',
  color: colors.black,
  display: 'flex',
  alignItems: 'center',
  height: '26px',
  fontWeight: '400',
  flexDirection: 'row',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  gap: '4px',
});
