import { useState } from 'react';
import { MenuItem, Tooltip } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import {
  StyledActionsMenuContainer as ActionsMenuContainer,
  StyledIconButton as IconButton,
  StyledLink,
} from './styledActionsMenuContainer';
import { ClinicianContractAPI } from 'api/ClinicianContractAPI';
import RESPONSE_CODES from 'constants/responseCodes';

interface Props {
  data: any
  actions: any[],
}

const ActionsMenu = ({ data, actions }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuActions, setMenuActions] = useState(actions);
  const open = Boolean(anchorEl);
  const handleClick = async (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    const response = await ClinicianContractAPI.checkAmendmentCreateAvailable(data.id);
    if (response.status === RESPONSE_CODES.UNPROCESSABLE_ENTITY) {
      setMenuActions(prevState => prevState.map(action => action.name === 'Add Amendment'
        ? { ...action, disabled: true, tooltip: response.data.error.details }
        : action));
    };
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style={{ textAlign: 'right' }} onClick={(e) => e.stopPropagation()}>
      <IconButton
        aria-label="actions"
        id="actions-button"
        data-testid="actions-button"
        aria-controls={open ? 'context-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        size="small"
      >
        <MoreVertIcon fontSize="inherit" />
      </IconButton>
      <ActionsMenuContainer
        id={`${data.id}-context-menu`}
        key={data.id}
        MenuListProps={{
          'aria-labelledby': 'actions-button',
          disablePadding: true,
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {menuActions.map(({ name, href, disabled = false, tooltip }, index) => (
          <Tooltip title={tooltip} key={index}>
            <div>
              <MenuItem disabled={disabled}>
                <StyledLink href={href}>{name}</StyledLink>
              </MenuItem>
            </div>
          </Tooltip>
        ))}
      </ActionsMenuContainer>
    </div>
  );
};

export default ActionsMenu;
