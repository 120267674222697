import { FormEvent, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Form, Formik } from 'formik';

import { IUserDetails } from 'interfaces/User/IUserDetails';
import { IFacility } from 'interfaces/Facility/IFacility';

import { OutlinedButton, SubmitButton, Input, Select, SearchableMultiselect } from 'components/UIComponents';
import { PageContainer, PageHeader, SectionContainer, ActionsBar, InputFieldsWrapper } from 'components/base';

import { useFieldsPermissions } from 'context/FieldsPermissionsContext';
import { useToast } from 'context/ToastContext';


import filterObjectKeys from 'helpers/filterObjectKeys';
import handleFormSubmitResponse from 'helpers/handleFormSubmitResponse';
import isSectionRendering from 'helpers/isSectionRendering';

import useData from 'hooks/useData';


import { UserAPI } from 'api/UserAPI';
import { PermissionsAPI } from 'api/PermissionsAPI';
import { FacilityAPI } from 'api/FacilityAPI';
import { RoleAPI } from 'api/RoleAPI';
import { ISelectOption } from 'interfaces/ISelectOption';

const EditUser = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { userId } = useParams();
  const { onToastOpen } = useToast();
  const { setFieldsPermissions } = useFieldsPermissions();

  const fieldsPermissionsData = useData(PermissionsAPI.getAllowedAttributes, 'User');
  const facilities = useData(FacilityAPI.getAll)?.data;
  const rolesOptions = useData(RoleAPI.getAll)?.objects;

  const [facilitiesOptions, setFacilitiesOptions] = useState<Array<ISelectOption>>([]);
  const [userFormInformation, setUserFormInformation] = useState(filterObjectKeys({
    first_name: '',
    last_name: '',
    email: '',
    facility_ids: [],
    facilities: [],
    role_id: '',
  }, fieldsPermissionsData?.read_allowed) as IUserDetails);

  useEffect(() => {
    if (fieldsPermissionsData) {
      setFieldsPermissions(fieldsPermissionsData);
    }
  }, [setFieldsPermissions, fieldsPermissionsData]);

  useEffect(() => {
    if (facilities) {
      setFacilitiesOptions(facilities.objects.map((facility: IFacility) => ({ id: facility.id as number, name: facility.name })));
    };
  }, [facilities]);

  useEffect(() => {
    if (userId && fieldsPermissionsData && facilitiesOptions.length > 0) {
      UserAPI.getDetails(Number(userId)).then((data: IUserDetails) => {
        let formData = {
          ...data,
          facilities: facilitiesOptions.filter(option => data.facility_ids.includes(option.id as number)),
        };
        setUserFormInformation(formData);
      });
    };
  }, [userId, fieldsPermissionsData, facilitiesOptions]);

  if (!(fieldsPermissionsData)) return null;

  return (
    <PageContainer type="normal" style={{marginBottom: '66px'}}>
      <PageHeader pageTitle={`${userFormInformation.first_name} ${userFormInformation.last_name}`}/>
      <Formik
        initialValues={userFormInformation}
        enableReinitialize={true}
        validateOnBlur
        onSubmit={async (values, { setFieldError }) => {
          const form = {
            role_id: values.role_id,
            facility_ids: values.facilities?.filter((option) => option.id !== 'all').map(option => option.id) as number[],
          };
          let convertedUpdateAllowedList = [];
          if (fieldsPermissionsData.update_allowed.includes('role')) convertedUpdateAllowedList.push('role_id');
          if (fieldsPermissionsData.update_allowed.includes('facility')) convertedUpdateAllowedList.push('facility_ids');
          const response = await UserAPI.updateUser(Number(userId), form, convertedUpdateAllowedList);
          handleFormSubmitResponse(response, setFieldError, onToastOpen, navigate, searchParams.get('redirectTo') as string);
        }}
      >
        {({ errors, handleSubmit, dirty }) => (
          <Form>
            {isSectionRendering(fieldsPermissionsData.read_allowed, ['email', 'description']) &&
              <SectionContainer title='User Info'>
                <InputFieldsWrapper>
                  <Input name='email' label='Email'/>
                  <Input name='description' label='Description'/>
                </InputFieldsWrapper>
              </SectionContainer>
            }

            {isSectionRendering(fieldsPermissionsData.read_allowed, ['role', 'facility']) &&
              <SectionContainer title='Access Level'>
                <InputFieldsWrapper>
                  <SearchableMultiselect
                    width='full-width'
                    label='Facility'
                    name='facilities'
                    permissionKey='facility'
                    options={facilitiesOptions}
                    initial={userFormInformation.facilities}
                    allOptionAvailable={true}
                  />
                  <Select
                    name="role_id"
                    label="Role"
                    permissionKey='role'
                    allowEmpty={false}
                    options={rolesOptions}
                    disabled={false}
                  />
                </InputFieldsWrapper>
              </SectionContainer>
            }
            <ActionsBar>
              <OutlinedButton
                onClick={() => navigate(-1)}
              >
                Cancel
              </OutlinedButton>
              <SubmitButton
                disabled={!dirty || !!Object.values(errors).length}
                onClick={(values: FormEvent<HTMLFormElement>) => handleSubmit(values)}
              >
                Save User
              </SubmitButton>
            </ActionsBar>
          </Form>
        )}
      </Formik>
    </PageContainer>
  );
};

export default EditUser;
