import { StyledDetailsWrapper } from './styledComponents';

interface DetailsWrapperProps {
  children: React.ReactNode,
};

const DetailsWrapper = ({ children }: DetailsWrapperProps) => {
  return(
    <StyledDetailsWrapper>
      {children}
    </StyledDetailsWrapper>
  );
};

export default DetailsWrapper;
