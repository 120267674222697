import {
  StyledDetailsContainer,
  PropertyName,
  NewValue,
  OldValue,
} from './styledComponents';

interface Props {
  label: string;
  oldValue: string;
  newValue: string;
  type: 'amendmentForm' | 'PerDiemContractDetails' | 'FullTimeContractDetails'
}

const ChangesContainer = ({
  label,
  oldValue,
  newValue,
  type,
}: Props) => {
  return (
    <>
      <StyledDetailsContainer>
        <PropertyName type={type}>{label}</PropertyName>
        <OldValue type={type}>{oldValue}</OldValue>
        <NewValue type={type}>{newValue}</NewValue>
      </StyledDetailsContainer>
    </>
  );
};

export default ChangesContainer;
