import { styled } from '@mui/material/styles';
import { colors } from 'styles/globalStyles';

export const StyledActionsBar = styled('div')({
  display: 'flex',
  position: 'fixed',
  width: '100vw',
  bottom: '0',
  left: '0',
  justifyContent: 'center',
  gap: '20px',
  padding: '14px 0',
  backgroundColor: colors.white,
  boxShadow: '0px 2px 7px 0px rgba(0, 77, 161, 0.15)',
  zIndex: '2',
});
