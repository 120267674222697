import { Autocomplete, FormControl, CircularProgress, Checkbox } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ISelectOption } from 'interfaces/ISelectOption';
import { WidthType } from 'types';

import { colors, fieldsWidthValues } from 'styles/globalStyles';

interface StyledFormControlProps {
  width: WidthType;
  styles?: {
    width?: string,
  }
}

interface SelectProps {
  value: ISelectOption[];
}

export const StyledFormControl = styled(FormControl)(({ width, styles }: StyledFormControlProps) => ({
  '&': {
    width: fieldsWidthValues[width],
    ...styles,
  },
}));

export const StyledAutocomplete = styled(Autocomplete)(({ value }: SelectProps) => {
  const isEmptyValue = !value?.length;

  return {
    '& .MuiFormControl-root .MuiInputBase-root.MuiAutocomplete-inputRoot': {
      paddingRight: '40px',
    },
    '& .MuiInputBase-root.MuiInputBase-sizeSmall': {
      padding: '0 30px 0 0',
    },
    '& .MuiInputBase-root .MuiInputBase-input.MuiInputBase-inputSizeSmall': {
      padding: '8px 12px',
      fontSize: '14px',
      '&::placeholder': {
        color: isEmptyValue ? '' : colors.black,
        opacity: isEmptyValue ? '' : 1,
      },
    },
    '& .MuiInputBase-root .MuiAutocomplete-endAdornment ': {
      right: '0',
    },
    '& .MuiInputBase-root fieldset': {
      borderColor: colors.borderGray,
    },
    '& .Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
      border: '1px solid',
      borderColor: colors.primaryBlue,
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: colors.primaryBlue,
    },

    '& .MuiInputBase-root.Mui-error fieldset': {
      borderColor: colors.hoveredTextRed,
    },
    '& .MuiInputLabel-root.Mui-error': {
      color: colors.hoveredTextRed,
    },
    '& .MuiFormHelperText-root.Mui-error': {
      color: colors.hoveredTextRed,
      lineHeight: '1.2',
      marginTop: '5px',
    },

    '& .MuiInputBase-root.Mui-disabled': {
      backgroundColor: colors.inputDisabledGray,
    },
    '& .MuiInputBase-root.Mui-disabled fieldset': {
      borderColor: colors.disabledBorderGray,
    },
    '& .MuiInputBase-root.Mui-disabled input': {
      backgroundColor: colors.inputDisabledGray,
      WebkitTextFillColor: colors.black,
    },
    '& .MuiInputLabel-root.Mui-disabled': {
      color: colors.textGray,
    },
    '& .MuiInputAdornment-root': {
      marginRight: '-8px',
      zIndex: '1',
    },
    '& .MuiInputAdornment-root p': {
      fontSize: '14px',
    },
    '& .MuiInputBase-root .MuiChip-root': {
      display: 'none',
    },
  };
});

export const StyledCircularProgress = styled(CircularProgress)(() => {
  return ({
    '&': {
      marginRight: '8px',
    },
  });
});

export const StyledListItem = styled('li')({
  '&.MuiAutocomplete-option': {
    paddingLeft: '16px',
    '&.Mui-focused': {
      backgroundColor: colors.tableHeaderGray,
    },
    '&[aria-selected=true]': {
      backgroundColor: colors.highlightedBlue,
      '&.Mui-focused': {
        backgroundColor: colors.tableHeaderGray,
      },
    },
  },
});

export const StyledCheckbox = styled(Checkbox)(() => ({
  '&.MuiCheckbox-root': {
    padding: 0,
    marginRight: '8px',
  },
}));

export const StartAdornment = styled('div')({
  fontSize: '14px',
  color: colors.primaryBlue,
  fontWeight: '500',
  paddingLeft: '12px',
  marginRight: '-10px',
  zIndex: 2,
});
