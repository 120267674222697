import { FieldArray } from 'formik';
import { TableBody, TableHead } from '@mui/material';

import { ContractType } from 'types';

import { ICompensationGridDetails } from 'interfaces/CompensationGrid/ICompensationGridDetails';
import { ICompensationScale } from 'interfaces/CompensationGrid/ICompensationScale';
import { ICompensationRate } from 'interfaces/CompensationGrid/ICompensationRate';
import { IContractedHour } from 'interfaces/CompensationGrid/IContractedHour';

import { StyledTable, HeadTableRow, HeadTableCell, BodyTableRow,
  NoOverflowBodyTableCell } from 'components/base/TableWithLazyLoad/styledComponents';
import { CustomIcon, InlineInput, TextButton, IconButton } from 'components/UIComponents';

import formatCurrency from 'helpers/formatCurrency';
import { calculateWeeklyRate, calculateBiWeeklyRate, calculateHourlyRate } from 'helpers/calculateRates';


import { CompensationRatesHeading, AddTierWrapper } from './styledComponents';
import { colors } from 'styles/globalStyles';

interface Props {
  values: ICompensationGridDetails,
  compensationScaleIndex: number,
  compensationScale: ICompensationScale,
  contractedHours: IContractedHour[],
};

const compensationRatesTableConfiguration = (engagement: string, contractType: string, rate_type: string) => {
  if (engagement === 'per_diem') {
    if (rate_type === 'tiered') {
      return [
        { title: 'Experience + ', style: { width: '20%' } },
        { title: 'Hourly', style: { width: '20%' } },
        { title: 'Notes', style: { width: '60%' } },
      ];
    } else {
      return [
        { title: 'Hourly', style: { width: '20%' } },
        { title: 'Notes', style: { width: '80%' } },
      ];
    }
  } else {
    if (rate_type === 'tiered') {
      return [
        { title: 'Experience + ', style: { width: '20%' } },
        { title: 'Annually', style: { width: '14%' } },
        { title: contractType === 'w2' ? 'Bi-Weekly': 'Weekly', style: { width: '14%' } },
        { title: 'Hourly', style: { width: '14%' } },
        { title: 'Notes', style: { width: '38%' } },
      ];
    } else {
      return [
        { title: 'Annually', style: { width: '14%' } },
        { title: contractType === 'w2' ? 'Bi-Weekly': 'Weekly', style: { width: '14%' } },
        { title: 'Hourly', style: { width: '14%' } },
        { title: 'Notes', style: { width: '58%' } },
      ];
    }
  }
};

const CompensationRateSubform = ({ values, compensationScale, compensationScaleIndex, contractedHours }: Props) => {

  const deleteTierAvailable = () => {
    return (compensationScale.rate_type === 'tiered' && compensationScale.compensation_rates.length > 1);
  };

  return (
    <FieldArray name={`compensation_scales.${compensationScaleIndex}.compensation_rates`}>
      {({ remove, push }) => (
        <>
          <CompensationRatesHeading>
            Compensation Rates
          </CompensationRatesHeading>
          <StyledTable>
            <TableHead>
              <HeadTableRow>
                {compensationRatesTableConfiguration(values.engagement, values.contract_type, compensationScale.rate_type)
                  .map((column, index) => (
                    <HeadTableCell key={index} style={column.style}>
                      {column.title}
                    </HeadTableCell>
                  ))
                }
                {deleteTierAvailable() && <HeadTableCell style={{width: '30px'}}/>}
              </HeadTableRow>
            </TableHead>
            <TableBody>
              { compensationScale.compensation_rates.map((compensationRate: ICompensationRate, rateIndex) => (
                <BodyTableRow key={`compansation_rates[${rateIndex}]`}>
                  { compensationScale.rate_type === 'tiered' &&
                    <NoOverflowBodyTableCell>
                      <InlineInput
                        type='experience'
                        name={`compensation_scales.${compensationScaleIndex}.compensation_rates.${rateIndex}.experience`}
                      />
                    </NoOverflowBodyTableCell>
                  }
                  { values.engagement === 'full_time' &&
                    <>
                      <NoOverflowBodyTableCell>
                        <InlineInput
                          type='currency'
                          name={`compensation_scales.${compensationScaleIndex}.compensation_rates.${rateIndex}.annual_rate`}
                        />
                      </NoOverflowBodyTableCell>
                      <NoOverflowBodyTableCell>
                        {formatCurrency(values.contract_type === 'w2' ?
                          calculateBiWeeklyRate(
                            Number(compensationRate.annual_rate), values.contract_type, Number(compensationScale.weeks_of_service)) :
                          calculateWeeklyRate(
                            Number(compensationRate.annual_rate),
                            values.contract_type as ContractType,
                            Number(compensationScale.weeks_of_service))
                        )
                        }
                      </NoOverflowBodyTableCell>
                      <NoOverflowBodyTableCell>
                        {formatCurrency(
                          calculateHourlyRate(
                            Number(compensationRate.annual_rate),
                            values.contract_type as ContractType,
                            Number(compensationScale.weeks_of_service),
                            contractedHours.find((hour) => hour.id === compensationScale.contracted_hour_id) as IContractedHour,
                          )
                        )}
                      </NoOverflowBodyTableCell>
                    </>
                  }
                  { values.engagement === 'per_diem' &&
                    <NoOverflowBodyTableCell>
                      <InlineInput
                        type='currency'
                        name={`compensation_scales.${compensationScaleIndex}.compensation_rates.${rateIndex}.hourly_rate`}
                      />
                    </NoOverflowBodyTableCell>
                  }
                  <NoOverflowBodyTableCell>
                    <InlineInput
                      type='text'
                      name={`compensation_scales.${compensationScaleIndex}.compensation_rates.${rateIndex}.notes`}
                      multiline
                    />
                  </NoOverflowBodyTableCell>
                  {deleteTierAvailable() && (
                    <NoOverflowBodyTableCell>
                      <IconButton onClick={() => remove(rateIndex)}>
                        <CustomIcon size='normal' color={colors.red} name='trash' />
                      </IconButton>
                    </NoOverflowBodyTableCell>
                  )}
                </BodyTableRow>
              ))}
            </TableBody>
          </StyledTable>
          {compensationScale.rate_type === 'tiered' &&
            <AddTierWrapper>
              <TextButton color='blue' onClick={() => push({
                annual_rate: '',
                hourly_rate: '',
                notes: '',
                experience: '',
              })}>
                + Add Tier
              </TextButton>
            </AddTierWrapper>
          }
        </>
      )}
    </FieldArray>
  );
};

export default CompensationRateSubform;
