import { styled } from '@mui/material/styles';

export const CompensationRatesHeading = styled('div')({
  fontWeight: 500,
  marginTop: '18px',
  marginBottom: '8px',
});

export const AddTierWrapper = styled('div')({
  marginTop: '8px',
});

