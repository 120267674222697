import { StyledSubmitButton } from './styledComponents';

interface SubmitButtonProps {
  onClick?: Function;
  disabled?: boolean;
  children: React.ReactNode;
  href?: string;
}

const SubmitButton = ({ onClick, children, disabled = false, href }: SubmitButtonProps) => {
  return (
    <StyledSubmitButton href={href} variant="contained" disabled={disabled} onClick={(e) => onClick?.(e)}>
      {children}
    </StyledSubmitButton>
  );
};

export default SubmitButton;
