import { NavLink } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import { colors } from 'styles/globalStyles';

export const NavBarContainer = styled('div')({
  display: 'flex',
});

export const NavBarItem = styled(NavLink)({
  position: 'relative',
  padding: '9px 12px',
  color: colors.white,
  textDecoration: 'none',
  height: '29.6px',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '29.6px',
  textAlign: 'center',
  '&:hover': {
    fontWeight: '600',
  },
  '&.active': {
    borderBottom: '3px solid',
    borderColor: colors.white,
    fontWeight: '600',
  },
  '&::before': {
    content: 'attr(data-label)',
    fontWeight: '600',
    visibility: 'hidden',
  },
});

export const LinkLabel = styled('span')({
  position: 'absolute',
  top: '9px',
  bottom: '9px',
  left: '12px',
  right: '12px',
});
