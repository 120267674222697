import { IContractedHour } from 'interfaces/CompensationGrid/IContractedHour';
import { ContractType } from 'types';

export const calculateWeeklyRate = (annualRate: number, contractType: ContractType, weeksOfService: number) => {
  if (contractType === 'w2') {
    const weeklyDivider = 52;
    return annualRate / weeklyDivider;
  } else {
    if (weeksOfService > 0) {
      return annualRate / weeksOfService;
    }
  }
  return 0;
};

export const calculateBiWeeklyRate = (annualRate: number, contractType: ContractType, weeksOfService: number) => {
  const biWeeklyMultiplier = 2;
  return calculateWeeklyRate(annualRate, contractType, weeksOfService) * biWeeklyMultiplier;
};

export const calculateHourlyRate = (
  annualRate: number,
  contractType: ContractType,
  weeksOfService: number,
  selectedContractedHour: IContractedHour,
  fte: '' | number | undefined = undefined
) => {
  if (!selectedContractedHour) {
    return '';
  }

  const calculatedAnnualRate = contractType === 'pc' ? calculateWeeklyRate(annualRate, contractType, weeksOfService) : annualRate;

  const divider = contractType === 'pc' ? selectedContractedHour.hrs_per_week : selectedContractedHour.hrs_per_year;

  if (Number(fte) > 0) {
    return calculatedAnnualRate / (divider * Number(fte));
  }
  return calculatedAnnualRate / divider;
};
