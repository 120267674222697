import { StyledOutlinedButton } from './styledComponents';

interface OutlinedButtonProps {
  onClick?: Function,
  children: React.ReactNode,
  disabled?: boolean,
  href?: string,
}

const OutlinedButton = ({ onClick, children, disabled, href }: OutlinedButtonProps) => {
  return (
    <StyledOutlinedButton href={href} variant="outlined" disabled={disabled} onClick={() => onClick?.()}>
      {children}
    </StyledOutlinedButton>
  );
};

export default OutlinedButton;
