import { Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import ProtectedLayout from 'layouts/ProtectedLayout';
import NewFacility from 'containers/Facilities/NewFacility';
import ListOfFacilities from 'containers/Facilities/ListOfFacilities';
import FacilityDetails from 'containers/Facilities/FacilityDetails';
import EditFacility from 'containers/Facilities/EditFacility';
import NewClinician from 'containers/Clinicians/NewClinician';
import ListOfClinicians from 'containers/Clinicians/ListOfClinicians';
import ClinicianDetails from 'containers/Clinicians/ClinicianDetails';
import EditClinician from 'containers/Clinicians/EditClinician';
import ListOfUsers from 'containers/Users/ListOfUsers';
import EditUser from 'containers/Users/EditUser';
import NewCompensationGrid from 'containers/CompensationGrids/NewCompensationGrid';
import ListOfCompensationGrids from 'containers/CompensationGrids/ListOfCompensationGrids';
import EditCompensationGrid from 'containers/CompensationGrids/EditCompensationGrid';
import CompensationGridDetails from 'containers/CompensationGrids/CompensationGridDetails';
import NewClinicianContract from 'containers/ClinicianContracts/NewClinicianContract';
import ListOfClinicianContracts from 'containers/ClinicianContracts/ListOfClinicianContracts';
import NewAmendment from 'containers/ClinicianContracts/Amendments/NewAmendment';
import EditClinicianContract from 'containers/ClinicianContracts/EditClinicianContract';
import ClinicianContractDetails from 'containers/ClinicianContracts/ClinicianContractDetails';
import EditAmendment from 'containers/ClinicianContracts/Amendments/EditAmendment';
import NotFound from 'containers/Errors/NotFound';
import SystemError from 'containers/Errors/SystemError';
import Forbidden from 'containers/Errors/Forbidden';
import RESPONSE_CODES from 'constants/responseCodes';
import { ERRORS_PATHS } from 'constants/errors';
import RootComponent from 'components/UIComponents/dataDisplay/RootComponent';

export default createBrowserRouter(
  createRoutesFromElements(
    <Route element={<ProtectedLayout />} errorElement={<SystemError />}>
      <Route path="/" element={<RootComponent/>} />
      <Route path="/facilities/new" element={<NewFacility/>} />
      <Route path="/facilities" element={<ListOfFacilities/>} />
      <Route path="/facilities/:facilityId" element={<FacilityDetails/>} />
      <Route path="/facilities/:facilityId/edit" element={<EditFacility/>} />
      <Route path="/clinicians/new" element={<NewClinician/>} />
      <Route path="/clinicians" element={<ListOfClinicians/>} />
      <Route path="/clinicians/:clinicianId" element={<ClinicianDetails/>} />
      <Route path="/clinicians/:clinicianId/edit" element={<EditClinician/>} />
      <Route path="/users" element={<ListOfUsers/>} />
      <Route path="/users/:userId/edit" element={<EditUser/>} />
      <Route path="/compensation_grids/new" element={<NewCompensationGrid/>} />
      <Route path="/compensation_grids" element={<ListOfCompensationGrids/>} />
      <Route path="/compensation_grids/:compensationGridId/edit" element={<EditCompensationGrid/>} />
      <Route path="/compensation_grids/:compensationGridId" element={<CompensationGridDetails/>} />
      <Route path="/clinician_contracts/" element={<ListOfClinicianContracts/>} />
      <Route path="/clinician_contracts/new" element={<NewClinicianContract/>} />
      <Route path="/clinician_contracts/:clinicianContractId/amendments/new" element={<NewAmendment/>} />
      <Route path="/clinician_contracts/:clinicianContractId/amendments/:amendmentId/edit" element={<EditAmendment/>} />
      <Route path="/clinician_contracts/:clinicianContractId/edit" element={<EditClinicianContract/>} />
      <Route path="/clinician_contracts/:clinicianContractId" element={<ClinicianContractDetails/>} />
      <Route path={ERRORS_PATHS[RESPONSE_CODES.FORBIDDEN]} element={<Forbidden />} />
      <Route path={ERRORS_PATHS[RESPONSE_CODES.SYSTEM_ERROR]} element={<SystemError />} />
      <Route path="*" element={<NotFound/>} />
    </Route>
  )
);
