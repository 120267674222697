import useAuth from 'hooks/useAuth';
import { NavBarContainer, NavBarItem, LinkLabel } from './styledComponents';
import { availableResources } from 'constants/availableResources';

const NavBar = () => {
  const { user } = useAuth();

  return (
    <NavBarContainer>
      {
        availableResources.map(resource => (
          user?.allowed_read_resources.includes(resource.url) &&
            <NavBarItem to={`/${resource.url}`} data-label={resource.label} key={resource.label}>
              <LinkLabel>{resource.label}</LinkLabel>
            </NavBarItem>
        ))
      }
    </NavBarContainer>
  );
};

export default NavBar;
