import { Outlet, useLocation } from 'react-router-dom';

import useAuth from 'hooks/useAuth';
import Forbidden from 'containers/Errors/Forbidden';
import { Header } from 'components/base';
import { availableResources } from 'constants/availableResources';

const ProtectedLayout = () => {
  const location = useLocation();
  const { user } = useAuth();

  let locations = location.pathname.split('/');
  locations.shift();
  if (availableResources.map(resource => resource.url).includes(locations[0])) {
    if (['new', 'edit'].includes(locations[locations.length - 1])) {
      if (!user?.allowed_write_resources.includes(locations[0])) {
        return (
          <>
            <Header />
            <Forbidden />
          </>
        );
      }
    } else {
      if (!user?.allowed_read_resources.includes(locations[0])) {
        return (
          <>
            <Header />
            <Forbidden />
          </>
        );
      }
    }
  }

  return (
    <>
      <Header />
      <Outlet />
    </>
  );
};

export default ProtectedLayout;
