import { FormEvent, useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { styled } from '@mui/material/styles';

import { PayrollCompanyAPI } from 'api/PayrollCompanyAPI';
import { FacilityAPI } from 'api/FacilityAPI';
import { PermissionsAPI } from 'api/PermissionsAPI';

import { OutlinedButton, SubmitButton, Input, Select } from 'components/UIComponents';
import { SectionContainer, ActionsBar, InputFieldsWrapper } from 'components/base';

import { IFacility } from 'interfaces/Facility/IFacility';
import { IPayrollCompany } from 'interfaces/IPayrollCompany';

import { facilityTypeOptions, stateOptions } from 'constants/dropdownOptions';
import { useToast } from 'context/ToastContext';
import { useFieldsPermissions } from 'context/FieldsPermissionsContext';
import { facilityValidationSchema } from 'helpers/validation/facilityValidationSchema';
import handleFormSubmitResponse from 'helpers/handleFormSubmitResponse';
import filterObjectKeys from 'helpers/filterObjectKeys';
import formatPhone from 'helpers/formatPhone';
import isSectionRendering from 'helpers/isSectionRendering';
import useData from 'hooks/useData';

const AdminWrapper = styled('div')(({ styles }: { styles?: React.CSSProperties}) => ({
  marginBottom: '-2px',
  ...styles,
}));

const payrollCompaniesOptions = (payrollCompanies: IPayrollCompany[] | []) => {
  return payrollCompanies?.map(({id, name}) => ({id, name}));
};

const FacilityForm = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { facilityId } = useParams();
  const { onToastOpen } = useToast();
  const { setFieldsPermissions } = useFieldsPermissions();
  const fieldsPermissionsData = useData(PermissionsAPI.getAllowedAttributes, 'Facility');

  useEffect(() => {
    if (fieldsPermissionsData) {
      setFieldsPermissions({...fieldsPermissionsData, read_allowed: [...fieldsPermissionsData.read_allowed, 'ein']});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldsPermissionsData]);

  const [facilityFormInformation, setFacilityFormInformation] = useState<IFacility>(filterObjectKeys({
    name: '',
    acronym: '',
    payroll_company_id: '',
    type: '',
    street: '',
    apt: '',
    city: '',
    state: '',
    zip: '',
    limit_per_event: '',
    limit_yearly: '',
    admin1_name: '',
    admin1_email: '',
    admin1_office: '',
    admin1_office_ext: '',
    admin1_cell: '',
    admin2_name: '',
    admin2_email: '',
    admin2_office: '',
    admin2_office_ext: '',
    admin2_cell: '',
    admin_assistant: '',
    office_coordinator: '',
    chief_md: '',
    chief_md_email: '',
    chief_md_cell: '',
    assistant_chief_md: '',
    assistant_chief_md_email: '',
    assistant_chief_md_cell: '',
    chief_crna: '',
    chief_crna_email: '',
    chief_crna_cell: '',
    ein: '',
  }, fieldsPermissionsData?.read_allowed) as IFacility);

  const payrollCompanies = useData(PayrollCompanyAPI.getAll) as IPayrollCompany[];

  useEffect(() => {
    if (facilityId && payrollCompanies) {
      FacilityAPI.getDetails(Number(facilityId)).then((data: IFacility) => {
        setFacilityFormInformation({
          ...data,
          admin1_cell: formatPhone(data.admin1_cell || ''),
          admin1_office: formatPhone(data.admin1_office || ''),
          admin2_office: formatPhone(data.admin2_office || ''),
          admin2_cell: formatPhone(data.admin2_cell || ''),
          chief_md_cell: formatPhone(data.chief_md_cell || ''),
          assistant_chief_md_cell: formatPhone(data.assistant_chief_md_cell || ''),
          chief_crna_cell: formatPhone(data.chief_crna_cell || ''),
          ein: payrollCompanies.find((el) => el.id === data.payroll_company_id)?.ein || '',
        });
      });
    };
  }, [facilityId, payrollCompanies]);

  if (!fieldsPermissionsData) return null;

  return (
    <Formik
      initialValues={facilityFormInformation}
      enableReinitialize={true}
      validationSchema={facilityValidationSchema}
      validateOnBlur
      onSubmit={async (values, { setFieldError }) => {
        let response;
        if (facilityId) {
          response = await FacilityAPI.updateFacility(Number(facilityId), values, fieldsPermissionsData.update_allowed);
        } else {
          response = await FacilityAPI.createFacility(values, fieldsPermissionsData.update_allowed);
        }
        handleFormSubmitResponse(response, setFieldError, onToastOpen, navigate, searchParams.get('redirectTo') as string);
      }}
    >
      {({ errors, handleSubmit, dirty, setFieldValue }) => (
        <Form>
          <SectionContainer title='Facility Info'>
            <InputFieldsWrapper>
              <Input name='name' label='Name' required width='ultra-wide'/>
              <Input name='acronym' label='Acronym' width='narrow'/>
              <Select name='type' label='Type' options={facilityTypeOptions} width='narrow'/>
              <Select
                name="payroll_company_id"
                label="Payroll Company"
                width='ultra-wide'
                allowEmpty={true}
                options={payrollCompaniesOptions(payrollCompanies)}
                disabled={false}
                onChange={(newValue: any) => setFieldValue('ein', payrollCompanies.find((el) => el.id === newValue)?.ein || '')}
              />
              <Input name='ein' label='EIN' disabled={true} width='narrow'/>
            </InputFieldsWrapper>
          </SectionContainer>

          {isSectionRendering(fieldsPermissionsData.read_allowed, ['apt', 'street', 'city', 'state', 'zip']) &&
          <SectionContainer title='Facility Address'>
            {isSectionRendering(fieldsPermissionsData.read_allowed, ['apt', 'street']) &&
            <InputFieldsWrapper>
              <Input name='street' label='Street'/>
              <Input name='apt' label='Apt, Suite, Floor'/>
            </InputFieldsWrapper>
            }
            {isSectionRendering(fieldsPermissionsData.read_allowed, ['city', 'state', 'zip']) &&
            <InputFieldsWrapper>
              <Input name='city' label='City'/>
              <Select name='state' label='State' options={stateOptions} width='narrow'/>
              <Input name='zip' label='ZIP Code' width='narrow'/>
            </InputFieldsWrapper>
            }
          </SectionContainer>
          }

          {isSectionRendering(fieldsPermissionsData.read_allowed, ['limit_per_event', 'limit_yearly']) &&
          <SectionContainer title='Malpractice Limits'>
            <InputFieldsWrapper>
              <Input name='limit_per_event' label='Per Event' type='currency'/>
              <Input name='limit_yearly' label='Yearly Aggregated' type='currency'/>
            </InputFieldsWrapper>
          </SectionContainer>
          }

          {isSectionRendering(fieldsPermissionsData.read_allowed, ['admin1_name', 'admin1_email', 'admin1_cell', 'admin1_office',
            'admin1_office_ext', 'admin2_name', 'admin2_email', 'admin2_cell', 'admin2_office', 'admin2_office_ext']) &&
          <SectionContainer title='Admin Info'>
            {isSectionRendering(fieldsPermissionsData.read_allowed, ['admin1_name', 'admin1_email', 'admin1_cell', 'admin1_office',
              'admin1_office_ext']) &&
              <>
                <AdminWrapper styles={{marginTop: '-6px'}}>Admin 1</AdminWrapper>
                <InputFieldsWrapper>
                  <Input name='admin1_name' label='Name'/>
                  <Input name='admin1_email' label='Email'/>
                  <Input name='admin1_cell' label='Cell' type='phone'/>
                  <Input name='admin1_office' label='Office' type='phone'/>
                  <Input name='admin1_office_ext' label='Extension'/>
                </InputFieldsWrapper>
              </>
            }
            {isSectionRendering(fieldsPermissionsData.read_allowed, ['admin2_name', 'admin2_email', 'admin2_cell', 'admin2_office',
              'admin2_office_ext']) &&
              <>
                <AdminWrapper styles={{marginTop: '4px'}}>Admin 2</AdminWrapper>
                <InputFieldsWrapper>
                  <Input name='admin2_name' label='Name'/>
                  <Input name='admin2_email' label='Email'/>
                  <Input name='admin2_cell' label='Cell' type='phone'/>
                  <Input name='admin2_office' label='Office' type='phone'/>
                  <Input name='admin2_office_ext' label='Extension'/>
                </InputFieldsWrapper>
              </>
            }
          </SectionContainer>
          }

          {isSectionRendering(fieldsPermissionsData.read_allowed, ['admin_assistant', 'office_coordinator', 'chief_md', 'chief_md_email',
            'chief_md_cell', 'assistant_chief_md', 'assistant_chief_md_email', 'assistant_chief_md_cell', 'chief_crna', 'chief_crna_email',
            'chief_crna_cell']) &&
          <SectionContainer title='Other contacts'>
            {isSectionRendering(fieldsPermissionsData.read_allowed, ['admin_assistant', 'office_coordinator']) &&
            <InputFieldsWrapper>
              <Input name='admin_assistant' label='Admin Assistant'/>
              <Input name='office_coordinator' label='Medical Office Coordinator'/>
            </InputFieldsWrapper>
            }
            {isSectionRendering(fieldsPermissionsData.read_allowed, ['chief_md', 'chief_md_email', 'chief_md_cell', 'assistant_chief_md',
              'assistant_chief_md_email', 'assistant_chief_md_cell', 'chief_crna', 'chief_crna_email', 'chief_crna_cell']) &&
            <InputFieldsWrapper>
              <Input name='chief_md' label='Chief MD'/>
              <Input name='chief_md_email' label='Chief MD Email'/>
              <Input name='chief_md_cell' label='Chief MD Cell' type='phone'/>
              <Input name='assistant_chief_md' label='Assistant Chief MD'/>
              <Input name='assistant_chief_md_email' label='Assistant Chief MD Email'/>
              <Input name='assistant_chief_md_cell' label='Assistant Chief MD Cell' type='phone'/>
              <Input name='chief_crna' label='Chief CRNA'/>
              <Input name='chief_crna_email' label='Chief CRNA Email'/>
              <Input name='chief_crna_cell' label='Chief CRNA Cell' type='phone'/>
            </InputFieldsWrapper>
            }
          </SectionContainer>
          }
          <ActionsBar>
            <OutlinedButton
              onClick={() => navigate(-1)}
            >
              Cancel
            </OutlinedButton>
            <SubmitButton
              disabled={!dirty || !!Object.values(errors).length}
              onClick={(values: FormEvent<HTMLFormElement>) => handleSubmit(values)}
            >
              Save Facility
            </SubmitButton>
          </ActionsBar>
        </Form>
      )}
    </Formik>
  );
};

export default FacilityForm;
