import { ContractType } from 'types';
import { ICompensationScale } from 'interfaces/CompensationGrid/ICompensationScale';
import { ICompensationGridDetails } from 'interfaces/CompensationGrid/ICompensationGridDetails';
import { IContractedHour } from 'interfaces/CompensationGrid/IContractedHour';

import formatCurrency from 'helpers/formatCurrency';
import { calculateWeeklyRate, calculateBiWeeklyRate, calculateHourlyRate } from 'helpers/calculateRates';
import { DetailsContainer, DetailsWrapper, ItalicContainer } from 'components/base';

import { CompRateWrapper, TitleContainter } from '../styledComponents';

interface Props {
  scale: ICompensationScale,
  compensationGrid: ICompensationGridDetails,
  contractedHours: IContractedHour[],
  width: 'wide' | 'normal',
  paddingTop: string,
};

const CompensationRatesInfo = ({ scale, compensationGrid, contractedHours, width, paddingTop }: Props) => {
  return (
    <CompRateWrapper style={{ paddingTop }}>
      {scale.compensation_rates.map((rate, index) => (
        <DetailsWrapper key={index}>
          <TitleContainter data-testid="compensation_rates_title">
            {scale.rate_type === 'tiered'
              ? `Comp Rates for ${rate.experience}+ yrs of Experience`
              : 'Compensation Rates'}
          </TitleContainter>
          {compensationGrid.engagement === 'full_time' &&
            <>
              <DetailsContainer
                name="annual_rate"
                label="Annually"
                skipPermissions
                width={width}
              >
                {formatCurrency(rate.annual_rate)}
              </DetailsContainer>
              <DetailsContainer
                name="weekly_rate"
                label={`${
                  compensationGrid.contract_type === 'w2'
                    ? 'Bi-Weekly'
                    : 'Weekly'
                }`}
                skipPermissions
                width={width}
              >
                {formatCurrency(compensationGrid.contract_type === 'w2' ?
                  calculateBiWeeklyRate(Number(rate.annual_rate), compensationGrid.contract_type, Number(scale.weeks_of_service)) :
                  calculateWeeklyRate(
                    Number(rate.annual_rate), compensationGrid.contract_type as ContractType, Number(scale.weeks_of_service)
                  )
                )}
              </DetailsContainer>
              <DetailsContainer
                name="hourly_rate"
                label="Hourly"
                skipPermissions
                width={width}
              >
                {formatCurrency(
                  calculateHourlyRate(
                    Number(rate.annual_rate),
                    compensationGrid.contract_type as ContractType,
                    Number(scale.weeks_of_service),
                    contractedHours.find((hour) => hour.id === scale.contracted_hour_id) as IContractedHour,
                  )
                )}
              </DetailsContainer>
            </>
          }
          {compensationGrid.engagement === 'per_diem' &&
            <DetailsContainer
              name="hourly_rate"
              label="Hourly"
              skipPermissions
              width={width}
            >
              {formatCurrency(rate.hourly_rate)}
            </DetailsContainer>
          }
          {rate.notes &&
            <ItalicContainer name="notes" skipPermissions width={width}>
              {rate.notes}
            </ItalicContainer>
          }
        </DetailsWrapper>
      ))}
    </CompRateWrapper>
  );
};

export default CompensationRatesInfo;
