import { useMemo, useState } from 'react';
import { MenuItem, OutlinedInput, InputLabel } from '@mui/material';
import { ReportsAPI } from 'api/ReportsAPI';
import { CustomIcon, IconButton, TextButton } from 'components/UIComponents';
import { IFieldSelection } from 'interfaces/Report/IFieldSelection';
import { colors } from 'styles/globalStyles';

import { SectionWrapper, StyledFormControl, StyledSelect, OptionWrapper } from './styledComponents';

interface Props {
  savedFieldSelections: IFieldSelection[],
  setSavedFieldSelections: Function,
  setSelectedFields: Function,
};

const SavedSelectionsSection = ({ setSavedFieldSelections, savedFieldSelections, setSelectedFields }: Props) => {

  const [selectedOption, setSelectedOption] = useState<'' | number>('');

  const savedSelectionsOptions = useMemo(() => {
    return savedFieldSelections.map((fieldSelection) => ({ id: fieldSelection.id, name: fieldSelection.title }));
  }, [savedFieldSelections]);

  return (
    <SectionWrapper>
      <StyledFormControl size="small">
        <InputLabel>
          Saved Selections
        </InputLabel>
        <StyledSelect
          value={selectedOption}
          data-testid='select-saved-selections'
          variant="standard"
          onChange={(e) => {
            setSelectedOption(e.target.value as number);
          }}
          input={<OutlinedInput label='Saved Selections' />}
          renderValue={(selected: any) => (
            <span>{savedSelectionsOptions.find(option => option.id === selected)?.name}</span>
          )}
        >
          {savedSelectionsOptions.map(({ id, name }) => (
            <MenuItem key={String(id)} value={id}>
              <OptionWrapper>
                <div>
                  {name}
                </div>
                <IconButton
                  styles={{ root: { padding: 0 }, hover: { backgroundColor: 'inherit' } }}
                  onClick={(e: MouseEvent) => {
                    e.stopPropagation();
                    ReportsAPI.deleteSavedFieldSelection(id).then(() => {
                      setSelectedOption('');
                      setSavedFieldSelections((prevValue: IFieldSelection[]) => prevValue.filter(option => option.id !== id));
                    });
                  }}
                >
                  <CustomIcon size='normal' color={colors.red} name='trash' />
                </IconButton>
              </OptionWrapper>
            </MenuItem>
          ))}
        </StyledSelect>
      </StyledFormControl>
      <TextButton
        color='blue'
        onClick={() => {
          if (selectedOption) {
            setSelectedFields(savedFieldSelections.find(selection => selection.id === selectedOption)?.payload);
          };
        }}
      >
        Apply
      </TextButton>
    </SectionWrapper>
  );
};

export default SavedSelectionsSection;
