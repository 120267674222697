import * as Yup from 'yup';
import { stringMaxLength, addressMaxLength, acronymMaxLength, shortStringMaxLength, minNumericValue,
  maxIntegerValue, phoneRegex, emailRegex } from 'constants/fieldsLimits';
import VALIDATION_MESSAGES from 'constants/validationMessages';

export const facilityValidationSchema = Yup.object({
  name: Yup.string()
    .required(VALIDATION_MESSAGES.REQUIRED)
    .max(stringMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(stringMaxLength)),
  acronym: Yup.string()
    .nullable()
    .optional()
    .max(acronymMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(acronymMaxLength)),
  street: Yup.string()
    .nullable()
    .optional()
    .max(addressMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(addressMaxLength)),
  apt: Yup.string()
    .nullable()
    .optional()
    .max(addressMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(addressMaxLength)),
  city: Yup.string()
    .nullable()
    .optional()
    .max(addressMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(addressMaxLength)),
  zip: Yup.string()
    .nullable()
    .optional()
    .max(shortStringMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(shortStringMaxLength)),
  limit_per_event: Yup.number()
    .nullable()
    .optional()
    .min(minNumericValue, VALIDATION_MESSAGES.POSITIVE)
    .max(maxIntegerValue, VALIDATION_MESSAGES.MAX_NUMBER(maxIntegerValue)),
  limit_yearly: Yup.number()
    .nullable()
    .optional()
    .min(minNumericValue, VALIDATION_MESSAGES.POSITIVE)
    .max(maxIntegerValue, VALIDATION_MESSAGES.MAX_NUMBER(maxIntegerValue)),
  admin1_name: Yup.string()
    .nullable()
    .optional()
    .max(stringMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(stringMaxLength)),
  admin1_email: Yup.string()
    .nullable()
    .optional()
    .test('is valid', VALIDATION_MESSAGES.EMAIL, (value => ( value ? emailRegex.test(value) : true )))
    .max(stringMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(stringMaxLength)),
  admin1_office: Yup.string()
    .nullable()
    .optional()
    .test(
      'is valid',
      VALIDATION_MESSAGES.PHONE_EXACT_LENGTH,
      (value) => ( value ? phoneRegex.test(String(value)) : true)
    ),
  admin1_office_ext: Yup.string()
    .nullable()
    .optional()
    .max(shortStringMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(shortStringMaxLength)),
  admin1_cell: Yup.string()
    .nullable()
    .optional()
    .test(
      'is valid',
      VALIDATION_MESSAGES.PHONE_EXACT_LENGTH,
      (value) => ( value ? phoneRegex.test(String(value)) : true)
    ),
  admin2_name: Yup.string()
    .nullable()
    .optional()
    .max(stringMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(stringMaxLength)),
  admin2_email: Yup.string()
    .nullable()
    .optional()
    .test('is valid', VALIDATION_MESSAGES.EMAIL, (value => ( value ? emailRegex.test(value) : true )))
    .max(stringMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(stringMaxLength)),
  admin2_office: Yup.string()
    .nullable()
    .optional()
    .test(
      'is valid',
      VALIDATION_MESSAGES.PHONE_EXACT_LENGTH,
      (value) => ( value ? phoneRegex.test(String(value)) : true)
    ),
  admin2_office_ext: Yup.string()
    .nullable()
    .optional()
    .max(shortStringMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(shortStringMaxLength)),
  admin2_cell: Yup.string()
    .nullable()
    .optional()
    .test(
      'is valid',
      VALIDATION_MESSAGES.PHONE_EXACT_LENGTH,
      (value) => ( value ? phoneRegex.test(String(value)) : true)
    ),
  admin_assistant: Yup.string()
    .nullable()
    .optional()
    .max(stringMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(stringMaxLength)),
  office_coordinator: Yup.string()
    .nullable()
    .optional()
    .max(stringMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(stringMaxLength)),
  chief_md: Yup.string()
    .nullable()
    .optional()
    .max(stringMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(stringMaxLength)),
  chief_md_email: Yup.string()
    .nullable()
    .optional()
    .test('is valid', VALIDATION_MESSAGES.EMAIL, (value => ( value ? emailRegex.test(value) : true )))
    .max(stringMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(stringMaxLength)),
  chief_md_cell: Yup.string()
    .nullable()
    .optional()
    .test(
      'is valid',
      VALIDATION_MESSAGES.PHONE_EXACT_LENGTH,
      (value) => ( value ? phoneRegex.test(String(value)) : true)
    ),
  assistant_chief_md: Yup.string()
    .nullable()
    .optional()
    .max(stringMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(stringMaxLength)),
  assistant_chief_md_email: Yup.string()
    .nullable()
    .optional()
    .test('is valid', VALIDATION_MESSAGES.EMAIL, (value => ( value ? emailRegex.test(value) : true )))
    .max(stringMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(stringMaxLength)),
  assistant_chief_md_cell: Yup.string()
    .nullable()
    .optional()
    .test(
      'is valid',
      VALIDATION_MESSAGES.PHONE_EXACT_LENGTH,
      (value) => ( value ? phoneRegex.test(String(value)) : true)
    ),
  chief_crna: Yup.string()
    .nullable()
    .optional()
    .max(stringMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(stringMaxLength)),
  chief_crna_email: Yup.string()
    .nullable()
    .optional()
    .test('is valid', VALIDATION_MESSAGES.EMAIL, (value => ( value ? emailRegex.test(value) : true )))
    .max(stringMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(stringMaxLength)),
  chief_crna_cell: Yup.string()
    .nullable()
    .optional()
    .test(
      'is valid',
      VALIDATION_MESSAGES.PHONE_EXACT_LENGTH,
      (value) => ( value ? phoneRegex.test(String(value)) : true)
    ),
});
