import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { ChangesContainer, DetailsContainer, DetailsWrapper, ItalicContainer, SectionContainer } from 'components/base';
import { CustomIcon, TextButton } from 'components/UIComponents';
import { IClinicianContractDetails } from 'interfaces/ClinicianContract/IClinicianContractDetails';
import { IPayrollDetails } from 'interfaces/ClinicianContract/IPayrollDetails';

import formatDate from 'helpers/formatDate';
import amendmentObjectsDiff from 'helpers/ClinicianConracts/amendmentObjectsDiff';
import { payrollDetailsNamesMapper } from 'constants/payrollDetailsNamesMapper';
import { IContractedHour } from 'interfaces/CompensationGrid/IContractedHour';

import {
  AmendmentsWrapper,
  TitleWrapper,
  AmendmentTitle,
  EffectContainer,
  Subtitle,
  NoChangesText,
  SubtitleWrapper,
} from './styledComponents';
import useAuth from 'hooks/useAuth';
interface Props {
  clinicianContract: IClinicianContractDetails,
  activeAmendment: IPayrollDetails,
}

const AmendmentsSection = ({ clinicianContract, activeAmendment }: Props) => {
  dayjs.extend(isSameOrBefore);
  const { user } = useAuth();
  const editAllowed = user?.allowed_write_resources.includes('clinician_contracts');

  const sortedAmendments = clinicianContract.payroll_details.sort((a, b) => dayjs(b.effective_date).diff(dayjs(a.effective_date)));

  const formatedEffectString = (amendment: IPayrollDetails, index: number) => {
    if (dayjs(amendment.effective_date).isSameOrBefore(dayjs())) {
      if (activeAmendment.id === amendment.id) {
        return (<EffectContainer type='active'>
          {` (has been in effect since ${dayjs(amendment.effective_date).format('MMM DD, YYYY')})`}
        </EffectContainer>);
      } else {
        return (<EffectContainer type='inactive'>
          {` (was in effect from ${dayjs(amendment.effective_date).format('MMM DD, YYYY')} until
          ${dayjs(sortedAmendments[index - 1].effective_date).format('MMM DD, YYYY')})`}
        </EffectContainer>);
      };
    } else {
      return (<EffectContainer type='inactive'>
        {` (will be in effect on ${dayjs(amendment.effective_date).format('MMM DD, YYYY')})`}
      </EffectContainer>);
    };
  };

  return (
    <SectionContainer title="Amendments">
      <AmendmentsWrapper>
        {sortedAmendments.filter(detail => detail.type !== 'Initial contract').map((amendment, index) => {
          const changes = amendmentObjectsDiff(
            sortedAmendments[index + 1],
            amendment,
            clinicianContract.contract_type,
            clinicianContract.compensation_scale.contracted_hour as IContractedHour);
          return(
            <div key={index}>
              <DetailsWrapper>
                <TitleWrapper>
                  <AmendmentTitle>
                    Amendment
                    {formatedEffectString(amendment, index)}
                  </AmendmentTitle>
                  {index === 0 && editAllowed &&
                    <TextButton
                      color="blue"
                      href=
                        {`${clinicianContract.id}/amendments/${amendment.id}/edit?redirectTo=/clinician_contracts/${clinicianContract.id}`}
                    >
                      <CustomIcon size='normal' name='edit'/>
                      Edit
                    </TextButton>
                  }
                </TitleWrapper>
                <DetailsContainer name="execution_date" label="Execution Date" skipPermissions width="wide">
                  {amendment.execution_date ? formatDate(String(amendment.execution_date)) : ''}
                </DetailsContainer>
                <DetailsContainer name="effective_date" label="Effective Date" skipPermissions width="wide">
                  {formatDate(String(amendment.effective_date))}
                </DetailsContainer>
                <DetailsContainer name="type" label="Type" skipPermissions width="wide">
                  {amendment.type}
                </DetailsContainer>
                {amendment.amendment_notes &&
                  <ItalicContainer name="notes" skipPermissions width="wide">
                    {amendment.amendment_notes}
                  </ItalicContainer>
                }
              </DetailsWrapper>
              <DetailsWrapper>
                <SubtitleWrapper>
                  <Subtitle>
                    Changes
                  </Subtitle>
                  {Object.keys(changes).length === 0 && <NoChangesText>No changes have been made</NoChangesText>}
                </SubtitleWrapper>
                {Object.entries(changes).map(([key, values], index) => (
                  <ChangesContainer
                    type={amendment.engagement === 'per_diem' ? 'PerDiemContractDetails' : 'FullTimeContractDetails'}
                    key={index}
                    label={payrollDetailsNamesMapper[key as keyof typeof payrollDetailsNamesMapper]}
                    oldValue={values.oldValue}
                    newValue={values.newValue}
                  />
                ))
                }
              </DetailsWrapper>
            </div>
          );
        })}
      </AmendmentsWrapper>
    </SectionContainer>
  );
};

export default AmendmentsSection;
