
import { FormEvent, useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import { Input, SubmitButton } from 'components/UIComponents';

import { ReportsAPI } from 'api/ReportsAPI';

import {
  StyledSaveSection,
  StyledTextButton,
  StyledPopover,
  FormWrapper,
} from './styledComponents';

interface Props {
  selectedFields: string[];
  setSavedFieldSelections: Function;
};

const SaveSection = ({ selectedFields, setSavedFieldSelections }: Props) => {
  const anchorEl = useRef<HTMLButtonElement>(null);

  const [savePopoverVisible, setSavePopoverVisible] = useState(false);

  return (
    <StyledSaveSection>
      <StyledTextButton
        variant='text'
        ref={anchorEl}
        onClick={() => setSavePopoverVisible(true)}
      >
        Save Selection
      </StyledTextButton>
      <StyledPopover
        id={'save-popover'}
        open={true}
        anchorEl={anchorEl?.current}
        disableScrollLock
        style={{'display': savePopoverVisible ? 'block' : 'none'}}
        onClose={() => setSavePopoverVisible(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Formik
          initialValues={{name: ''}}
          enableReinitialize={true}
          validate={(values: { name: string }) => {
            const errors: { name?: string } = {};
            if (!values.name) {
              errors.name = 'Required field';
            };
            return errors;
          }}
          validateOnBlur
          onSubmit={async (values, { resetForm }) => {
            await ReportsAPI.saveFieldSelection(values.name, selectedFields);
            resetForm();
            ReportsAPI.getSavedFieldSelections().then((data) => {
              setSavedFieldSelections(data);
            });
            setSavePopoverVisible(false);
          }}
        >
          {({ dirty, errors, handleSubmit }) => (
            <Form>
              <FormWrapper>
                <Input
                  name='name'
                  label='Name'
                  placeholder="Enter the selection's name"
                  width='full-width'
                  skipPermissionsCheck
                />
                <SubmitButton
                  disabled={!dirty || !!Object.values(errors).length}
                  onClick={(values: FormEvent<HTMLFormElement>) => handleSubmit(values)}
                >
                  Save
                </SubmitButton>
              </FormWrapper>
            </Form>
          )}
        </Formik>
      </StyledPopover>
    </StyledSaveSection>
  );
};

export default SaveSection;
