import { request } from '../instance';

export const AuthAPI = {
  login: async (token: string) => {
    try {
      const response = await request({
        url: 'v1/auth/authorize',
        method: 'POST',
        data: {
          token,
        },
      });

      return response;
    } catch (error: any) {
      return error?.response;
    };
  },

  logout: async () => {
    try {
      const response = await request({
        url: 'v1/auth/logout',
        method: 'DELETE',
      });

      return response;
    } catch (error: any) {
      return error?.response;
    };
  },
};
