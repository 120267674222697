import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { DetailsContainer, DetailsWrapper, ItalicContainer, SectionContainer } from 'components/base';
import { IPayrollDetails } from 'interfaces/ClinicianContract/IPayrollDetails';
import { IContractedHour } from 'interfaces/CompensationGrid/IContractedHour';

import { TitleContainter } from 'containers/CompensationGrids/CompensationGridDetails/styledComponents';
import formatCurrency from 'helpers/formatCurrency';
import { calculateBiWeeklyRate, calculateHourlyRate, calculateWeeklyRate } from 'helpers/calculateRates';
import { frequencyOptions } from 'constants/contractsConstants';
import { IClinicianContractDetails } from 'interfaces/ClinicianContract/IClinicianContractDetails';
import useAuth from 'hooks/useAuth';

interface Props {
  clinicianContract: IClinicianContractDetails
  activeAmendment: IPayrollDetails,
}

const PayrollDetails = ({ activeAmendment, clinicianContract }: Props) => {
  const { user } = useAuth();
  dayjs.extend(quarterOfYear);

  const selectedContractedHour = clinicianContract.compensation_scale?.contracted_hour as IContractedHour;

  const nextLevelText = () => {
    if (clinicianContract.compensation_scale.rate_type === 'tiered') {
      const currentTierExperience = clinicianContract.compensation_scale.compensation_rates
        .find(rate => rate.id === clinicianContract.compensation_rate_id)?.experience;
      if (Number(currentTierExperience) >= 0) {
        const nextTier = clinicianContract.compensation_scale.compensation_rates
          .find(rate => Number(rate.experience) > Number(currentTierExperience));
        if (nextTier) {
          const hourlyRate = activeAmendment.engagement === 'per_diem' ? nextTier.hourly_rate : calculateHourlyRate(
            Number(nextTier.annual_rate),
            clinicianContract.contract_type,
            Number(clinicianContract.compensation_scale.weeks_of_service),
            selectedContractedHour,
          );
          const increaseDate = dayjs(clinicianContract.clinician.grad_date)
            .add(Number(nextTier.experience), 'years')
            .startOf('quarter')
            .add(1, 'quarter');
          const weeksOfServiceAdditionalText = clinicianContract.contract_type === 'pc' && activeAmendment.engagement !== 'per_diem' ?
            ` for ${clinicianContract.compensation_scale.weeks_of_service} weeks of service` :
            '';
          return `Next level is for experience over ${nextTier.experience} years.
            ${ user?.role !== 'Facility Admin' ? `Hourly rate${weeksOfServiceAdditionalText} is ${formatCurrency(hourlyRate)}.` : ''}
            Step increase date is ${increaseDate.format('MMM DD, YYYY')}`;
        }
      }
    };

    return '';
  };

  return(
    <SectionContainer title="Payroll Details">
      <DetailsWrapper>
        {activeAmendment.engagement !== 'per_diem' && (
          <DetailsContainer name="staff_category" label="Staff Category" skipPermissions width="wide">
            {activeAmendment.staff_category}
          </DetailsContainer>
        )}
        <DetailsContainer name="contracted_hours" label="Contracted Hours" skipPermissions width="wide">
          {activeAmendment.contracted_hours}
        </DetailsContainer>
        {clinicianContract.contract_type === 'pc' && activeAmendment.engagement !== 'per_diem' && (
          <DetailsContainer name="weeks_of_service" label="Weeks of Service" skipPermissions width="wide">
            {activeAmendment.weeks_of_service}
          </DetailsContainer>
        )}
        {clinicianContract.contract_type === 'w2' && activeAmendment.engagement !== 'per_diem' && (
          <DetailsContainer name="paid_time_off" label="Paid Time-Off, days" skipPermissions width="wide">
            {activeAmendment.paid_time_off}
          </DetailsContainer>
        )}
      </DetailsWrapper>
      <DetailsWrapper>
        <TitleContainter data-testid="compensation_rates_title">
          Compensation Rates
        </TitleContainter>
        { user?.role !== 'Facility Admin' && <>
          {activeAmendment.engagement !== 'per_diem' &&
            <>
              <DetailsContainer name="annual_rate" label="Annually" skipPermissions width="wide">
                {formatCurrency(activeAmendment.annual_rate)}
              </DetailsContainer>
              <DetailsContainer name='bi_weekly' label='Bi-Weekly' skipPermissions width="wide">
                {formatCurrency(calculateBiWeeklyRate(
                  Number(activeAmendment.annual_rate), clinicianContract.contract_type, Number(activeAmendment.weeks_of_service)
                ))}
              </DetailsContainer>
              <DetailsContainer name="weekly_rate" label="Weekly" skipPermissions width="wide">
                {formatCurrency(calculateWeeklyRate(
                  Number(activeAmendment.annual_rate), clinicianContract.contract_type, Number(activeAmendment.weeks_of_service)
                ))}
              </DetailsContainer>
              <DetailsContainer name="hourly_rate" label="Hourly" skipPermissions width="wide">
                {formatCurrency(
                  calculateHourlyRate(
                    Number(activeAmendment.annual_rate),
                    clinicianContract.contract_type,
                    Number(activeAmendment.weeks_of_service),
                    selectedContractedHour,
                    activeAmendment.fte,
                  )
                )}
              </DetailsContainer>
            </>
          }
          {activeAmendment.engagement === 'per_diem' &&
            <DetailsContainer name="hourly_rate" label="Hourly" skipPermissions width="wide">
              {formatCurrency(activeAmendment.hourly_rate)}
            </DetailsContainer>
          }
          {activeAmendment.notes &&
            <ItalicContainer name="notes" skipPermissions width="wide">
              {activeAmendment.notes}
            </ItalicContainer>
          }
        </>
        }
        <DetailsContainer name="contracted_frequency" label="Contracted Frequency" skipPermissions width="wide">
          {frequencyOptions.find((option) => option.id === activeAmendment.contracted_frequency)?.name}
        </DetailsContainer>
        <DetailsContainer name="payroll_frequency" label="Payroll Frequency" skipPermissions width="wide">
          {frequencyOptions.find((option) => option.id === activeAmendment.payroll_frequency)?.name}
        </DetailsContainer>
        <DetailsContainer name="malpractice_deduction" label="Malpractice Deduction" skipPermissions width="wide">
          {activeAmendment.malpractice_deduction}
        </DetailsContainer>
      </DetailsWrapper>
      {nextLevelText()}
    </SectionContainer>
  );
};

export default PayrollDetails;
