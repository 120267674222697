import { StyledInputFieldsWrapper } from './styledComponents';

interface Props {
  children: React.ReactNode;
  styles?: React.CSSProperties;
}

const InputFieldsWrapper = ({ children, styles = {} }: Props) => {
  return <StyledInputFieldsWrapper styles={styles}>{children}</StyledInputFieldsWrapper>;
};

export default InputFieldsWrapper;
