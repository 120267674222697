import { numericFormatter } from 'react-number-format';

const formatCurrency = (value: number | string | undefined, withoutDecimal = false) => {
  if (value) {
    const decimalScale = 2;
    const roundedNumber = Number(value).toFixed(decimalScale);
    return numericFormatter(String(roundedNumber), {
      prefix: '$',
      decimalScale: withoutDecimal ? 0 : decimalScale,
      thousandSeparator: true,
      decimalSeparator: '.',
      displayType: 'text',
    });
  } else {
    return '';
  };
};

export default formatCurrency;
