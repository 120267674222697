import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { WidthType } from 'types';

import { colors, fieldsWidthValues } from 'styles/globalStyles';

interface StyledTextFieldProps {
  width: WidthType;
  styles: React.CSSProperties,
}

export const StyledTextField = styled(TextField)(({ width, styles }: StyledTextFieldProps) => ({
  '&': {
    width: fieldsWidthValues[width],
  },
  '& .MuiInputBase-input': {
    padding: '8px 0 8px 12px',
    fontSize: '14px',
  },
  '& .MuiInputBase-root fieldset': {
    borderColor: colors.borderGray,
  },
  '& .Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
    border: '1px solid',
    borderColor: colors.primaryBlue,
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: colors.primaryBlue,
  },

  '& .MuiInputBase-root.Mui-error fieldset': {
    borderColor: colors.hoveredTextRed,
  },
  '& .MuiInputLabel-root.Mui-error': {
    color: colors.hoveredTextRed,
  },
  '& .MuiFormHelperText-root.Mui-error': {
    color: colors.hoveredTextRed,
    lineHeight: '1.2',
    marginTop: '5px',
  },

  '& .MuiInputBase-root.Mui-disabled': {
    backgroundColor: colors.inputDisabledGray,
  },
  '& .MuiInputBase-root.Mui-disabled fieldset': {
    borderColor: colors.disabledBorderGray,
  },
  '& .MuiInputBase-root.Mui-disabled input': {
    backgroundColor: colors.inputDisabledGray,
    WebkitTextFillColor: colors.black,
  },
  '& .MuiInputLabel-root.Mui-disabled': {
    color: colors.textGray,
  },
  '& .MuiInputAdornment-root': {
    marginRight: '-8px',
    zIndex: '1',
  },
  '& .MuiInputAdornment-root.MuiInputAdornment-positionEnd': {
    marginRight: '-12px',
  },
  '& .MuiInputAdornment-root.MuiInputAdornment-positionEnd .MuiIconButton-root': {
    padding: '6px',
  },
  '& .MuiInputAdornment-root p': {
    fontSize: '14px',
  },
  ...styles,
}));
