import { styled } from '@mui/material/styles';
import { statusStylesMapper } from 'constants/contractsConstants';

import { StatusType } from 'types';

export const StyledStatusLabel = styled('div')<{ status: StatusType }>`
  width: 70px;
  height: 20px;

  font-size: 11px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;

  color: ${(props) => (statusStylesMapper[props.status].color)};

  border: ${(props) => statusStylesMapper[props.status].border};
  border-radius: 18px;
  background-color: ${(props) => statusStylesMapper[props.status].backgroundColor};
`;
