import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { styled } from '@mui/material/styles';

import { WidthType } from 'types';
import { colors, fieldsWidthValues } from 'styles/globalStyles';

interface StyledDatePickerProps {
  width: WidthType;
  styles?: {
    width?: string,
  }
}

export const StyledMaterialDatePicker = styled(DatePicker)(({ width, styles }: StyledDatePickerProps) => ({
  '&': {
    width: fieldsWidthValues[width],
    ...styles,
  },
  '& .MuiInputBase-input': {
    padding: '8px 0 8px 12px',
    fontSize: '14px',
  },
  '& .MuiInputBase-root fieldset': {
    borderColor: colors.borderGray,
  },
  '& .Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
    border: '1px solid',
    borderColor: colors.primaryBlue,
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: colors.primaryBlue,
  },

  '& .MuiInputBase-root.Mui-error fieldset': {
    borderColor: colors.hoveredTextRed,
  },
  '& .MuiInputLabel-root.Mui-error': {
    color: colors.hoveredTextRed,
  },
  '& .MuiFormHelperText-root.Mui-error': {
    color: colors.hoveredTextRed,
  },

  '& .MuiInputBase-root.Mui-disabled': {
    backgroundColor: colors.inputDisabledGray,
  },
  '& .MuiInputBase-root.Mui-disabled fieldset': {
    borderColor: colors.disabledBorderGray,
  },
  '& .MuiInputBase-root.Mui-disabled input': {
    backgroundColor: colors.inputDisabledGray,
    WebkitTextFillColor: colors.black,
  },
  '& .MuiInputLabel-root.Mui-disabled': {
    color: colors.textGray,
  },
  '& .MuiInputAdornment-root': {
    zIndex: '1',
    marginLeft: 0,
  },
  '& .MuiInputAdornment-root p': {
    fontSize: '14px',
  },
}));
