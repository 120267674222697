import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

import { colors } from 'styles/globalStyles';

export const StyledSubmitButton = styled(Button)(() => {
  return ({
    '&': {
      color: colors.white,
      backgroundColor: colors.primaryBlue,
    },
    '&:hover': {
      backgroundColor: colors.hoveredBlue,
      boxShadow: 'none',
    },
    '&.Mui-disabled': {
      backgroundColor: colors.disabledBlue,
      color: colors.white,
    },
  });
});
