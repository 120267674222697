import { useContext, useEffect, useState } from 'react';
import { Modal } from 'components/base';
import { Checkbox } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ReactComponent as CheckboxChecked } from 'assets/icons/checkbox-checked.svg';
import { ReactComponent as CheckboxUnchecked } from 'assets/icons/checkbox-unchecked.svg';

import { IFieldList } from 'interfaces/Report/IFieldList';
import { IFieldSelection } from 'interfaces/Report/IFieldSelection';

import { ModalContext } from 'context/ModalContext';

import useData from 'hooks/useData';

import { ReportsAPI } from 'api/ReportsAPI';

import { reportModalTitle } from 'constants/contractsConstants';
import { OutlinedButton, SubmitButton } from 'components/UIComponents';

import {
  ModalContent,
  ActionsBar,
  AccordionWrapper,
  StyledSummaryLabel,
  StyledDetailsLabel,
  StyledAccordionSummary,
  StyledAccordion,
  StyledAccordionDetails,
} from './styledComponents';

import SavedSelectionsSection from './SavedSelectionsSection';
import SaveSection from './SaveSection';
import { IClinicianContractFilterQueryParams } from 'interfaces/ClinicianContract/IClinicianContractFilterQueryParams';

interface Props {
  search: string,
  filterQuery: IClinicianContractFilterQueryParams,
};

const sectionNames = ['Clinician', 'Facility', 'Contract Details', 'Payroll Details', 'Memos'];

const ReportModal = ({ search, filterQuery }: Props) => {

  const { onModalClose } = useContext(ModalContext);

  const fieldsList: IFieldList[] = useData(ReportsAPI.getAvailableFieldsList);

  const [selectedFields, setSelectedFields] = useState<string[]>([]);
  const [savedFieldSelections, setSavedFieldSelections] = useState<IFieldSelection[]>([]);

  useEffect(() => {
    ReportsAPI.getSavedFieldSelections().then(response => {
      setSavedFieldSelections(response);
    });
  }, []);

  const closeModal = () => {
    setSelectedFields([]);
    onModalClose(reportModalTitle);
  };

  const handleFieldSelectChange = (selectedField: string) => {
    if (selectedFields.includes(selectedField)) {
      setSelectedFields(prevValue => prevValue.filter(value => value !== selectedField));
    } else {
      setSelectedFields(prevValue => [...prevValue, selectedField]);
    }
  };

  const handleSelecteSectionChange = (sectionName: string) => {
    const sectionFields = fieldsList.filter(field => field.section_name === sectionName).map(field => field.value);
    if (sectionFields.every(val => selectedFields.includes(val))) {
      setSelectedFields(prevValue => prevValue.filter(value => !sectionFields.includes(value)));
    } else {
      setSelectedFields(prevValue => [...prevValue.filter(value => !sectionFields.includes(value)), ...sectionFields]);
    };
  };

  const exportClickHandler = () => {
    ReportsAPI.generateReport(search, filterQuery, selectedFields).then((response) => {
      const url = window.URL.createObjectURL(response.data);
      let filename = response.headers['content-disposition'].split(';')[1].match(/filename="([^"]+)"/)[1];
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  };

  if (!fieldsList) return null;

  return(
    <Modal
      title={reportModalTitle}
      onModalClose={closeModal}
    >
      <ModalContent>
        {
          savedFieldSelections.length > 0 &&
          <SavedSelectionsSection
            savedFieldSelections={savedFieldSelections}
            setSelectedFields={setSelectedFields}
            setSavedFieldSelections={setSavedFieldSelections}
          />
        }
        <SaveSection
          selectedFields={selectedFields}
          setSavedFieldSelections={setSavedFieldSelections}
        />
        <AccordionWrapper>
          {sectionNames.map(sectionName => (
            <StyledAccordion key={sectionName}>
              <StyledAccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${sectionName}-content`}
                id={`${sectionName}-header`}
              >
                <StyledSummaryLabel
                  onClick={(e) => e.stopPropagation()}
                  onChange={() => handleSelecteSectionChange(sectionName)}
                  control={
                    <Checkbox
                      icon={<CheckboxUnchecked />}
                      checkedIcon={<CheckboxChecked />}
                      inputProps={{'aria-label': sectionName}}
                      checked={fieldsList
                        .filter(field => field.section_name === sectionName)
                        .map(field => field.value)
                        .every(val => selectedFields.includes(val))
                      }
                    />
                  }
                  label={sectionName}
                />
              </StyledAccordionSummary>
              {fieldsList.filter(field => field.section_name === sectionName).map(field => (
                <StyledAccordionDetails key={`${sectionName}-${field.value}`}>
                  <StyledDetailsLabel
                    control={
                      <Checkbox
                        icon={<CheckboxUnchecked />}
                        checkedIcon={<CheckboxChecked />}
                        checked={selectedFields.includes(field.value)}
                        inputProps={{'aria-label': field.label}}
                        onChange={() => handleFieldSelectChange(field.value)}
                      />
                    }
                    label={field.label}
                  />
                </StyledAccordionDetails>
              ))}
            </StyledAccordion>
          ))}
        </AccordionWrapper>
      </ModalContent>
      <ActionsBar>
        <OutlinedButton onClick={closeModal}>
          Cancel
        </OutlinedButton>
        <SubmitButton disabled={selectedFields.length === 0} onClick={exportClickHandler}>
          Export
        </SubmitButton>
      </ActionsBar>
    </Modal>
  );
};

export default ReportModal;
