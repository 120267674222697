
import { IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

interface IStyles {
  root?: React.CSSProperties,
  hover?: React.CSSProperties,
}

export const StyledIconButton = styled(IconButton)(({ styles }: { styles?: IStyles }) => ({
  '&': {
    width: '30px',
    padding: '7px',
    borderRadius: '4px',
    ...styles?.root,
    ':hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
      ...styles?.hover,
    },
  },
}));
