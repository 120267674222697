import { TableBody, TableHead } from '@mui/material';

import { ContractType } from 'types';

import { SectionContainer, InputFieldsWrapper } from 'components/base';
import { BodyTableRow, HeadTableCell, HeadTableRow, NoOverflowBodyTableCell,
  StyledTable } from 'components/base/TableWithLazyLoad/styledComponents';
import { Select, InlineInput, Input } from 'components/UIComponents';

import { contractStaffCategoryOptions, frequencyOptions } from 'constants/contractsConstants';

import { IContractedHour } from 'interfaces/CompensationGrid/IContractedHour';

import formatCurrency from 'helpers/formatCurrency';
import { calculateWeeklyRate, calculateBiWeeklyRate, calculateHourlyRate } from 'helpers/calculateRates';

import { CompensationRatesHeading, CompensationRatesWrapping } from './styledComponents';
import { IPayrollDetails } from 'interfaces/ClinicianContract/IPayrollDetails';

interface Props {
  contract_type: ContractType,
  payroll_details: IPayrollDetails,
  selectedContractedHour: IContractedHour,
  contractCreation?: boolean,
  amendmentsPresent?: boolean,
}

const compensationRatesTableConfiguration = (engagement: string) => {
  if (engagement === 'per_diem') {
    return [
      { title: 'Hourly', style: { width: '20%' } },
      { title: 'Notes', style: { width: '80%' } },
    ];
  } else {
    return [
      { title: 'Annually', style: { width: '14%' } },
      { title: 'Bi-Weekly', style: { width: '14%' } },
      { title: 'Weekly', style: { width: '14%' } },
      { title: 'Hourly', style: { width: '14%' } },
      { title: 'Notes', style: { width: '44%' } },
    ];
  }
};

const PayrollDetailsSubform = ({
  contract_type,
  payroll_details,
  selectedContractedHour,
  contractCreation = false,
  amendmentsPresent = false,
}: Props) => {
  return (
    <SectionContainer title='Payroll Details'>
      {payroll_details.engagement !== 'per_diem' &&
        <InputFieldsWrapper>
          <Select
            name='payroll_details.staff_category'
            label='Staff Category'
            options={contractStaffCategoryOptions}
            disabled={amendmentsPresent}
            width='ultra-wide'
            skipPermissionsCheck
          />
        </InputFieldsWrapper>
      }
      <InputFieldsWrapper>
        <Input
          name='payroll_details.contracted_hours'
          label='Contracted Hours'
          disabled={amendmentsPresent}
          width={payroll_details.engagement === 'per_diem' ? 'ultra-wide' : 'normal'}
          skipPermissionsCheck
        />
        {contract_type === 'pc' && payroll_details.engagement !== 'per_diem' &&
          <Input
            name='payroll_details.weeks_of_service'
            label='Weeks of Service'
            width='normal'
            type='twoDigits'
            disabled={amendmentsPresent}
            skipPermissionsCheck
          />
        }
        {contract_type === 'w2' && payroll_details.engagement !== 'per_diem' &&
          <Input
            name='payroll_details.paid_time_off'
            label='Paid Time-Off, days'
            width='normal'
            type='threeDigits'
            disabled={amendmentsPresent}
            skipPermissionsCheck
          />
        }
      </InputFieldsWrapper>
      <CompensationRatesWrapping>
        <CompensationRatesHeading>
          Compensation Rates
        </CompensationRatesHeading>
        <StyledTable>
          <TableHead>
            <HeadTableRow>
              {compensationRatesTableConfiguration(payroll_details.engagement)
                .map((column, index) => (
                  <HeadTableCell key={index} style={column.style}>
                    {column.title}
                  </HeadTableCell>
                ))
              }
            </HeadTableRow>
          </TableHead>
          <TableBody>
            <BodyTableRow>
              {
                payroll_details.engagement === 'per_diem' ?
                  <NoOverflowBodyTableCell>
                    <InlineInput
                      type='currency'
                      name='payroll_details.hourly_rate'
                      disabled={amendmentsPresent}
                    />
                  </NoOverflowBodyTableCell>
                  :
                  <>
                    <NoOverflowBodyTableCell>
                      <InlineInput
                        type='currency'
                        name='payroll_details.annual_rate'
                        disabled={amendmentsPresent}
                      />
                    </NoOverflowBodyTableCell>
                    <NoOverflowBodyTableCell>
                      { formatCurrency(
                        calculateBiWeeklyRate(
                          Number(payroll_details.annual_rate),
                          contract_type,
                          Number(payroll_details.weeks_of_service)
                        ))
                      }
                    </NoOverflowBodyTableCell>
                    <NoOverflowBodyTableCell>
                      { formatCurrency(
                        calculateWeeklyRate(
                          Number(payroll_details.annual_rate),
                          contract_type,
                          Number(payroll_details.weeks_of_service)
                        ))
                      }
                    </NoOverflowBodyTableCell>
                    <NoOverflowBodyTableCell>
                      {formatCurrency(
                        calculateHourlyRate(
                          Number(payroll_details.annual_rate),
                          contract_type,
                          Number(payroll_details.weeks_of_service),
                          selectedContractedHour,
                          payroll_details.fte
                        )
                      )}
                    </NoOverflowBodyTableCell>
                  </>
              }
              <NoOverflowBodyTableCell>
                <InlineInput
                  type='text'
                  name='payroll_details.notes'
                  multiline
                  disabled={amendmentsPresent}
                />
              </NoOverflowBodyTableCell>
            </BodyTableRow>
          </TableBody>
        </StyledTable>
      </CompensationRatesWrapping>
      <InputFieldsWrapper>
        <Select
          name='payroll_details.contracted_frequency'
          label='Contracted Frequency'
          options={frequencyOptions}
          width='normal'
          skipPermissionsCheck
          disabled={amendmentsPresent}
        />
        <Select
          name='payroll_details.payroll_frequency'
          label='Payroll Frequency'
          options={frequencyOptions}
          width='normal'
          skipPermissionsCheck
          disabled={amendmentsPresent}
        />
        { !contractCreation &&
          <Input
            name='payroll_details.malpractice_deduction'
            label='Malpractice Deduction'
            width='normal'
            skipPermissionsCheck
            disabled={amendmentsPresent}
          />
        }
      </InputFieldsWrapper>
    </SectionContainer>
  );
};

export default PayrollDetailsSubform;
