import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';
import { request } from '../instance';
import { IFilterParams } from 'interfaces/IFilterParams';
import filterObjectKeys from 'helpers/filterObjectKeys';


export const UserAPI = {
  getAll: async (params: IFilterParams, msalInstance: IPublicClientApplication | undefined) => {
    const activeAccount = (msalInstance as IPublicClientApplication).getActiveAccount();
    const tokenResponse = await (msalInstance as IPublicClientApplication)
      .acquireTokenSilent({scopes: ['user.read'], account: activeAccount as AccountInfo});
    const response = await request({
      url: 'v1/users',
      method: 'GET',
      params,
      headers: { token: tokenResponse.accessToken },
    });

    return response.data;
  },

  getDetails: async (userId: number) => {
    const response = await request({
      url: `v1/users/${userId}`,
      method: 'GET',
    });

    return response.data.data;
  },

  updateUser: async (userId: number, formData: {role_id: number, facility_ids: number[]}, updateAllowedFieldsList: Array<string>) => {
    try {
      const response = await request({
        url: `v1/users/${userId}`,
        method: 'PUT',
        data: {
          data: {
            user: filterObjectKeys(formData, updateAllowedFieldsList),
          },
        },
      });

      return response;
    } catch (error: any) {
      return error?.response;
    };
  },
};
