import { CircularProgress } from '@mui/material';
import { StyledBox, StyledContainer } from './styledComponents';

const LoadingBlock = () => {
  return (
    <StyledContainer>
      <StyledBox>
        <CircularProgress />
      </StyledBox>
    </StyledContainer>
  );
};

export default LoadingBlock;
