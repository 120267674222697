import { StyledPageContainer } from './styledComponents';

interface Props {
  type?: 'normal' | 'wide';
  style?: React.CSSProperties;
  children: React.ReactNode;
}

const PageContainer = ({ type = 'normal', children, style }: Props) => {
  return <StyledPageContainer type={type} style={style}>{children}</StyledPageContainer>;
};

export default PageContainer;
