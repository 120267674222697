import ErrorPage from 'components/base/ErrorPage';

const SystemError = () => {
  return <ErrorPage
    title='500 Error'
    errorText='The server encountered an error and could not complete your request'
    iconName='systemError'
  />;
};

export default SystemError;
