import { ITableColumn } from 'interfaces/ITableColumn';

const columnsWithRecalculatedWidth = (columns: ITableColumn[], permittedFields: Array<string>) => {
  const excludedColumns = columns.filter(column => !permittedFields.includes(column.dataField));
  const newColumns = columns.filter(column => permittedFields.includes(column.dataField));

  const exludedColumnsWidth = excludedColumns.reduce((accumulator, currentValue) => (
    accumulator + Number(String(currentValue?.headStyle?.width)?.replace('%', ''))), 0);

  const columnsAdjustmentWidth = exludedColumnsWidth / newColumns.length;

  return newColumns.map((column) => (
    {...column, headStyle: { width: `${Number(String(column?.headStyle?.width)?.replace('%', '')) + columnsAdjustmentWidth}%`}}
  ));
};

export default columnsWithRecalculatedWidth;
