import { IResponse } from 'interfaces/IResponse';
import RESPONSE_CODES from 'constants/responseCodes';
import ToastType from 'constants/enums/ToastType';

const handleFormSubmitResponse = (
  response: IResponse,
  setFieldError: (name: string, value: any) => void,
  onToastOpen: (id: string, message: string, type: ToastType) => void,
  navigate: (to: string) => void,
  navigateTo: string,
) => {
  switch(response.status) {
  case RESPONSE_CODES.OK:
  case RESPONSE_CODES.CREATED:
    onToastOpen(response.data.data, response.data.data, ToastType.success);
    navigate(navigateTo);
    break;
  case RESPONSE_CODES.UNPROCESSABLE_ENTITY:
    response.data.error.details.forEach((e: object) => {
      setFieldError(Object.keys(e)[0], Object.values(e)[0]);
    });
    break;
  default:
  }
};

export default handleFormSubmitResponse;
