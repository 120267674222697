import searchSvg from 'assets/images/search.svg';
import {
  StyledBox,
  StyledContainer,
  SearchIcon,
  HighlightedText,
  StyledQuotation,
} from './styledComponents';

const NoResultsBlock = ({ searchText }: { searchText?: string }) => {
  return (
    <StyledContainer>
      <StyledBox>
        <SearchIcon src={searchSvg} />
        {
          searchText ?
            <>
              <div>
                We couldn't find a match for
                <StyledQuotation> "</StyledQuotation>
                <HighlightedText>
                  {searchText}
                </HighlightedText>
                <StyledQuotation>".</StyledQuotation>
              </div>
              <div>
                Please try another search
              </div>
            </>
            :
            <div>There is no data to display</div>
        }
      </StyledBox>
    </StyledContainer>
  );
};

export default NoResultsBlock;
