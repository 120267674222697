import { StyledActionsBar } from './styledComponents';

interface Props {
  children: React.ReactNode;
}

const ActionsBar = ({ children }: Props) => {
  return <StyledActionsBar>{children}</StyledActionsBar>;
};

export default ActionsBar;
