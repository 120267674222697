import { PageContainer, PageHeader } from 'components/base';
import ClinicianContractForm from 'containers/ClinicianContracts/ClinicianContractForm';

const NewClinicianContract = () => {
  return (
    <PageContainer type="normal" style={{marginBottom: '66px'}}>
      <PageHeader pageTitle='Adding Contract'/>
      <ClinicianContractForm />
    </PageContainer>
  );
};

export default NewClinicianContract;
