import { useField } from 'formik';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useFieldsPermissions } from 'context/FieldsPermissionsContext';
import CustomIcon from 'components/UIComponents/dataDisplay/CustomIcon';
import { WidthType } from 'types';

import { StyledMaterialDatePicker } from './styledComponents';

const CalendarIcon = () => {
  return(
    <CustomIcon name='calendar' size='normal'/>
  );
};

interface DatePickerProps {
  label: string;
  name: string;
  required?: boolean;
  disabled?: boolean;
  onChange?: any;
  onBlur?: Function;
  styles?: {
    width?: string
  }
  helperText?: string;
  width?: WidthType;
  skipPermissionsCheck?: boolean
  disableFuture?: boolean;
};

const DatePicker = ({
  label,
  name,
  required = false,
  disabled = false,
  onChange,
  onBlur,
  width = 'normal',
  skipPermissionsCheck = false,
  disableFuture = true,
  styles,
}: DatePickerProps) => {
  const [field, meta, { setValue, setTouched }] = useField(name);
  const { fieldsPermissions } = useFieldsPermissions();

  const handleChange = async (value: any) => {
    setValue(value);
    await field.onBlur(name);
    onChange?.(value);
  };

  return(
    <>
      {(skipPermissionsCheck || fieldsPermissions.read_allowed.includes(name)) &&
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <StyledMaterialDatePicker
            width={width}
            styles={styles}
            {...field}
            views={['year', 'month', 'day']}
            value={!field.value ? null : field.value}
            label={label}
            disabled={disabled || !(fieldsPermissions.update_allowed.includes(name) || skipPermissionsCheck)}
            onChange={handleChange}
            slots={{
              openPickerIcon: CalendarIcon,
            }}
            disableFuture={disableFuture}
            slotProps={{
              textField: {
                variant: 'outlined',
                size: 'small',
                error: meta.touched && !!meta.error,
                helperText: meta.touched && meta.error,
                onBlur: () => {
                  onBlur?.();
                  setTouched(true);
                },
                required,
              },
            }}
            closeOnSelect
            onAccept={() => {
              onBlur?.();
              setTouched(true, false);
            }}
          />
        </LocalizationProvider>
      }
    </>
  );
};

export default DatePicker;
