import React from 'react';
import { useSlate } from 'slate-react';
import { Editor, BaseEditor } from 'slate';
import { StyledButton, StyledIcon } from '../styledComponents';

interface MarkButtonProps {
  format: string;
  icon: React.ReactNode;
}

const MarkButton = ({ format, icon }: MarkButtonProps) => {
  const editor = useSlate();

  const isMarkActive = (editor: BaseEditor, format: string) => {
    const marks: { [key: string]: boolean } | null = Editor.marks(editor);
    return marks ? marks[format] === true : false;
  };

  const toggleMark = (editor: BaseEditor, format: string) => {
    const isActive = isMarkActive(editor, format);

    if (isActive) {
      Editor.removeMark(editor, format);
    } else {
      Editor.addMark(editor, format, true);
    }
  };

  return (
    <StyledButton
      active={isMarkActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      <StyledIcon>{icon}</StyledIcon>
    </StyledButton>
  );
};

export default MarkButton;
