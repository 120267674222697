import { useEffect, useState } from 'react';
import { useField } from 'formik';
import { TextField, MenuItem, FormHelperText } from '@mui/material';

import { ISelectOption } from 'interfaces/ISelectOption';

import { useFieldsPermissions } from 'context/FieldsPermissionsContext';

import { WidthType } from 'types';

import { StyledFormControl, StyledAutocomplete } from './styledComponents';

interface SelectProps {
  label: string;
  name: string;
  options: ISelectOption[];
  required?: boolean;
  disabled?: boolean;
  emptyOptionLabel?: string;
  width?: WidthType;
  permissionKey?: string;
  initial?: ISelectOption;
  onChange?: any;
  skipPermissionsCheck?: boolean;
}

const DEFAULT_OPTION = { id: '', name: '' };

const SelectWithSearch = ({
  label,
  name = '',
  required = false,
  disabled = false,
  emptyOptionLabel = 'None',
  width = 'normal',
  permissionKey = name,
  initial = DEFAULT_OPTION,
  onChange,
  options,
  skipPermissionsCheck = false,
}: SelectProps) => {
  const [field, meta, helpers] = useField(name);
  const { fieldsPermissions } = useFieldsPermissions();

  const [value, setValue] = useState<ISelectOption>(DEFAULT_OPTION);

  useEffect(() => {
    setValue(initial);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(initial)]);

  useEffect(() => {
    if (field.value && options.length > 0) {
      setValue(options.find((option: ISelectOption) => option.id === field.value) as ISelectOption);
    };
  // https://github.com/facebook/react/issues/14476#issuecomment-471199055
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(initial), field.value, options]);

  return (
    <>
      {(skipPermissionsCheck || fieldsPermissions.read_allowed.includes(permissionKey)) &&
        <StyledFormControl size="small" error={meta.touched && !!meta.error} required={required} width={width}>
          <StyledAutocomplete
            size="small"
            {...field}
            data-testid={`select-${name}`}
            disabled={disabled || !(fieldsPermissions.update_allowed.includes(permissionKey) || skipPermissionsCheck)}
            options={options}
            value={value}
            noOptionsText="No results found"
            disableClearable
            isOptionEqualToValue={(option: any, anotherOption: any) => option.id === anotherOption.id}
            getOptionLabel={(option: any) => option.name }
            renderInput={(params) => {
              return (
                <TextField
                  name={name}
                  {...params}
                  required={required}
                  label={label}
                  error={meta.touched && !!meta.error}
                  ref={params.InputProps.ref}
                  inputProps={{ ...params.inputProps, 'aria-label': 'label' }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              );
            }}
            onChange={(e, newValue: any ) => {
              onChange?.(newValue, value);
              helpers.setValue(newValue.id);
            }}
            renderOption={(props, option: any) => (
              <MenuItem {...props} key={option.id} style={{ whiteSpace: 'normal' }}>
                {!required && option.id === DEFAULT_OPTION.id && option.name === DEFAULT_OPTION.name ? (
                  <em>{emptyOptionLabel}</em>
                ) : (
                  option.name
                )}
              </MenuItem>
            )}
          />
          {
            meta.touched && meta.error && (
              <FormHelperText>{meta.error}</FormHelperText>
            )
          }
        </StyledFormControl>
      }
    </>
  );
};

export default SelectWithSearch;
