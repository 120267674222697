import * as Yup from 'yup';
import { compTermMaxLength, compTermNoteMaxLength, callTermTextMaxLength, minNumericValue } from 'constants/fieldsLimits';
import VALIDATION_MESSAGES from 'constants/validationMessages';

export const compensationGridValidationSchema = Yup.object({
  facility_id: Yup.number().required(VALIDATION_MESSAGES.REQUIRED),
  clinician_title: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
  contract_type: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
  engagement: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
  time_off: Yup.string().nullable().optional().max(compTermMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(compTermMaxLength)),
  time_off_notes: Yup.string()
    .nullable().optional().max(compTermNoteMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(compTermNoteMaxLength)),
  time_off_cme: Yup.string().nullable().optional().max(compTermMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(compTermMaxLength)),
  time_off_cme_notes: Yup.string()
    .nullable().optional().max(compTermNoteMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(compTermNoteMaxLength)),
  overtime: Yup.string().nullable().optional().max(compTermMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(compTermMaxLength)),
  overtime_notes: Yup.string()
    .nullable().optional().max(compTermNoteMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(compTermNoteMaxLength)),
  malpractice: Yup.string().nullable().optional().max(compTermMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(compTermMaxLength)),
  malpractice_notes: Yup.string()
    .nullable().optional().max(compTermNoteMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(compTermNoteMaxLength)),
  health_benefits: Yup.string().nullable().optional().max(compTermMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(compTermMaxLength)),
  health_benefits_notes: Yup.string()
    .nullable().optional().max(compTermNoteMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(compTermNoteMaxLength)),
  dental_benefits: Yup.string().nullable().optional().max(compTermMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(compTermMaxLength)),
  dental_benefits_notes: Yup.string()
    .nullable().optional().max(compTermNoteMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(compTermNoteMaxLength)),
  vision_benefits: Yup.string().nullable().optional().max(compTermMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(compTermMaxLength)),
  vision_benefits_notes: Yup.string()
    .nullable().optional().max(compTermNoteMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(compTermNoteMaxLength)),
  li_benefits: Yup.string().nullable().optional().max(compTermMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(compTermMaxLength)),
  li_benefits_notes: Yup.string()
    .nullable().optional().max(compTermNoteMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(compTermNoteMaxLength)),
  ltd_benefits: Yup.string().nullable().optional().max(compTermMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(compTermMaxLength)),
  ltd_benefits_notes: Yup.string()
    .nullable().optional().max(compTermNoteMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(compTermNoteMaxLength)),
  std_benefits: Yup.string().nullable().optional().max(compTermMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(compTermMaxLength)),
  std_benefits_notes: Yup.string()
    .nullable().optional().max(compTermNoteMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(compTermNoteMaxLength)),
  voluntary_benefits: Yup.string().nullable().optional().max(compTermMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(compTermMaxLength)),
  voluntary_benefits_notes: Yup.string()
    .nullable().optional().max(compTermNoteMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(compTermNoteMaxLength)),
  match: Yup.string().nullable().optional().max(compTermMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(compTermMaxLength)),
  match_notes: Yup.string()
    .nullable().optional().max(compTermNoteMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(compTermNoteMaxLength)),
  state_licenses: Yup.string().nullable().optional().max(compTermMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(compTermMaxLength)),
  state_licenses_notes: Yup.string()
    .nullable().optional().max(compTermNoteMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(compTermNoteMaxLength)),
  cme_pdme: Yup.string().nullable().optional().max(compTermMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(compTermMaxLength)),
  cme_pdme_notes: Yup.string()
    .nullable().optional().max(compTermNoteMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(compTermNoteMaxLength)),
  workers_comp: Yup.string().nullable().optional().max(compTermMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(compTermMaxLength)),
  workers_comp_notes: Yup.string()
    .nullable().optional().max(compTermNoteMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(compTermNoteMaxLength)),
  termination_notice: Yup.string().nullable().optional().max(compTermMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(compTermMaxLength)),
  termination_notice_notes: Yup.string()
    .nullable().optional().max(compTermNoteMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(compTermNoteMaxLength)),
  miscellaneous: Yup.string().nullable().optional().max(compTermMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(compTermMaxLength)),
  miscellaneous_notes: Yup.string()
    .nullable().optional().max(compTermNoteMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(compTermNoteMaxLength)),
  call_terms: Yup.array().of(
    Yup.object().shape({
      title: Yup.string().nullable().optional().max(callTermTextMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(callTermTextMaxLength)),
      hours: Yup.string().nullable().optional().max(callTermTextMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(callTermTextMaxLength)),
      call_in: Yup.string().nullable().optional().max(callTermTextMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(callTermTextMaxLength)),
      notes: Yup.string().nullable().optional().max(compTermNoteMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(compTermNoteMaxLength)),
    }),
  ),
  stipends: Yup.array().of(
    Yup.object().shape({
      title: Yup.string().nullable().optional().max(callTermTextMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(callTermTextMaxLength)),
      frequency: Yup.string().nullable().optional()
        .max(callTermTextMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(callTermTextMaxLength)),
      notes: Yup.string().nullable().optional().max(compTermNoteMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(compTermNoteMaxLength)),
    }),
  ),
  additional_compensations: Yup.array().of(
    Yup.object().shape({
      title: Yup.string().nullable().optional().max(callTermTextMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(callTermTextMaxLength)),
      frequency: Yup.string().nullable().optional()
        .max(callTermTextMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(callTermTextMaxLength)),
      notes: Yup.string().nullable().optional().max(compTermNoteMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(compTermNoteMaxLength)),
    }),
  ),
  compensation_scales: Yup.array().of(
    Yup.object().shape({
      staff_category: Yup.string().nullable().test(
        'is-unique',
        VALIDATION_MESSAGES.NOT_UNIQUE_COMP_SCALE_STAFF_CATEGORY,
        function(staff_category: any) {
          let isUnique = true;
          if (this.from && this.from.length > 1) {
            const staffCategories = this.from[1].value.compensation_scales.map((scale: any) => scale.staff_category);
            if (staffCategories.filter((item: any) => item === staff_category).length > 1) {
              isUnique = false;
            }
          }
          return isUnique;
        }
      ),
      weeks_of_service: Yup.number().nullable().min(minNumericValue, VALIDATION_MESSAGES.POSITIVE),
      compensation_rates: Yup.array().of(
        Yup.object().shape({
          notes: Yup
            .string().nullable().optional().max(compTermNoteMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(compTermNoteMaxLength)),
          experience: Yup.number().nullable().test(
            'is-higher',
            VALIDATION_MESSAGES.NOT_HIGHER,
            function(experience: any) {
              if(typeof experience !== 'undefined') {
                if (this.from && this.from.length > 1) {
                  if (this.from[1].value.rate_type !== 'flat') {
                    const minComparableArrayLength = 2;
                    if (this.from[1].value.compensation_rates.length >= minComparableArrayLength) {
                      const match = this.path.match(/compensation_rates\[(\d+)\]/) as RegExpMatchArray;
                      const currentElementIndex = parseInt(match[1], 10);
                      if (currentElementIndex !== 0) {
                        const previousValue = this.from[1].value.compensation_rates[currentElementIndex - 1].experience;
                        if (typeof previousValue !== 'undefined') {
                          return Number(previousValue) < Number(experience);
                        }
                      };
                    };
                  };
                };
              };
              return true;
            }
          ).test(
            'required',
            VALIDATION_MESSAGES.REQUIRED,
            function(experience: any) {
              if (this.from && this.from.length > 1) {
                if (this.from[1].value.rate_type !== 'flat') {
                  return typeof experience !== 'undefined';
                }
              }
              return true;
            },
          ),
        }),
      ),
    })
  ),
});
