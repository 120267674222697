import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

import { colors } from 'styles/globalStyles';

export const DisplayContainer = styled('div')(({ disabled }: {disabled: boolean}) => ({
  width: '100%',
  minWidth: '50px',
  minHeight: '16px',
  cursor: disabled ? 'default' : 'pointer',
  overflowWrap: 'break-word',
}));

export const ErrorContainer = styled('div')({
  color: colors.hoveredTextRed,
  cursor: 'pointer',
});


export const StyledInput = styled(TextField)(() => ({
  '& .MuiInputBase-input': {
    padding: '2px 6px',
    fontSize: '14px',
  },
  '& .MuiInputBase-root.MuiInputBase-adornedStart': {
    paddingLeft: '6px',
  },
  '& .MuiInputBase-root.MuiInputBase-multiline': {
    padding: 0,
  },
  '& .MuiInputBase-root fieldset': {
    borderColor: colors.primaryBlue,
  },
  '& .Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
    border: '1px solid',
    borderColor: colors.primaryBlue,
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: colors.primaryBlue,
  },

  '& .MuiInputBase-root.Mui-error fieldset': {
    borderColor: colors.hoveredTextRed,
  },
  '& .MuiInputLabel-root.Mui-error': {
    color: colors.hoveredTextRed,
  },
  '& .MuiFormHelperText-root.Mui-error': {
    color: colors.hoveredTextRed,
    lineHeight: '1.2',
    marginTop: '5px',
  },
  '& .MuiInputAdornment-root': {
    marginRight: '-4px',
    zIndex: '1',
  },
  '& .MuiInputAdornment-root p': {
    fontSize: '14px',
  },
}));
