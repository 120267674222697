import StatusLabel from 'components/UIComponents/dataDisplay/StatusLabel';
import { DetailsContainer, DetailsWrapper, SectionContainer } from 'components/base';
import { IClinicianContractDetails } from 'interfaces/ClinicianContract/IClinicianContractDetails';
import { IPayrollDetails } from 'interfaces/ClinicianContract/IPayrollDetails';
import { contractEngagementTypeOptions, contractTypeOptions } from 'constants/contractsConstants';
import formatDate from 'helpers/formatDate';

interface Props {
  clinicianContract: IClinicianContractDetails,
  activeAmendment: IPayrollDetails
}

const ContractDetails = ({ clinicianContract, activeAmendment }: Props) => {
  const engagement = contractEngagementTypeOptions.find((option) => option.id === activeAmendment.engagement)?.name;
  const initialContract = clinicianContract.payroll_details.find(detail => detail.type === 'Initial contract');

  return(
    <SectionContainer title="Contract Details">
      <DetailsWrapper>
        <DetailsContainer name="status" label="Status" skipPermissions width="wide">
          <StatusLabel status={clinicianContract.status} />
        </DetailsContainer>
        <DetailsContainer name="contract_type" label="Contract Type" skipPermissions width="wide">
          {contractTypeOptions.find((option) => option.id === clinicianContract.contract_type)?.name}
        </DetailsContainer>
        <DetailsContainer name='engagement' label='Engagement' skipPermissions width="wide">
          {`${engagement}${engagement === 'Part-Time' ? `, ${activeAmendment.fte} FTE` : ''}`}
        </DetailsContainer>
        <DetailsContainer name='execution_date' label='Execution Date' skipPermissions width="wide">
          {initialContract?.execution_date ? formatDate(String(initialContract?.execution_date)) : ''}
        </DetailsContainer>
        <DetailsContainer name='effective_date' label='Effective Date' skipPermissions width="wide">
          {formatDate(String(initialContract?.effective_date))}
        </DetailsContainer>
        <DetailsContainer name='contract_term' label='Contract Term, years' skipPermissions width="wide">
          {activeAmendment.contract_term}
        </DetailsContainer>
        <DetailsContainer name='resign_date' label='Term/Resign Date' skipPermissions width="wide">
          {clinicianContract.resign_date ? formatDate(String(clinicianContract.resign_date)) : ''}
        </DetailsContainer>
        <DetailsContainer name='last_day_of_service' label='Last Day of Service' skipPermissions width="wide">
          {clinicianContract.last_day_of_service ? formatDate(String(clinicianContract.last_day_of_service)) : ''}
        </DetailsContainer>
        <DetailsContainer name='contract_end_type' label='Contract End Type' skipPermissions width="wide">
          {clinicianContract.contract_end_type}
        </DetailsContainer>
      </DetailsWrapper>
    </SectionContainer>
  );
};

export default ContractDetails;
