import { initialMemo } from 'constants/contractsConstants';
import formatCurrency from 'helpers/formatCurrency';
import { IClinicianContractForm } from 'interfaces/ClinicianContract/IClinicianContractForm';

const orderedKeys = ['title', 'hours', 'paid_in_full', 'amount', 'call_in', 'frequency', 'pay_method', 'notes'];

const keyToNameMapper = {
  call_in: 'call in',
  frequency: 'frequency',
  pay_method: 'method',
  hours: 'hours',
};

const valueForMemo = (data: any, isPayrollMemo: boolean) => {
  let result: string[] = [];
  let titleString = '';
  orderedKeys.forEach(key => {
    if (data[key as any]?.toString()) {
      if (key === 'title') {
        titleString = `<strong>${data[key as any]}</strong>: `;
      } else if (key === 'paid_in_full') {
        result.push(`paid in full - ${(data[key as any]) ? 'Yes' : 'No'}`);
      } else if (key === 'amount') {
        if (isPayrollMemo) {
          result.push(`amount - ${formatCurrency(data[key as any])}`);
        }
      } else if (key === 'notes') {
        if (isPayrollMemo) {
          result.push(`notes - <i>${data[key as any]}</i>`);
        }
      } else {
        result.push(`${keyToNameMapper[key as keyof typeof keyToNameMapper]} - ${(data[key as any])}`);
      }
    }
  });

  return `${titleString}${result.join(', ')}`;
};

const addToMemoCallback = (data: any, setFieldValue: any, values: IClinicianContractForm) => {
  if (values.admin_memo === initialMemo) {
    setFieldValue('admin_memo', `<li>${valueForMemo(data, false)}</li>`);
  } else {
    setFieldValue('admin_memo', `${values.admin_memo}<li>${valueForMemo(data, false)}</li>`);
  }

  if (values.payroll_memo === initialMemo) {
    setFieldValue('payroll_memo', `<li>${valueForMemo(data, true)}</li>`);
  } else {
    setFieldValue('payroll_memo', `${values.payroll_memo}<li>${valueForMemo(data, true)}</li>`);
  }
};

export default addToMemoCallback;


