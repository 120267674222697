import { styled } from '@mui/material/styles';
import { colors } from 'styles/globalStyles';

interface AmendmentTitleProps {
  type: 'active' | 'inactive',
}

export const AmendmentsWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '40px',
});

export const TitleWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const EffectContainer = styled('span')(({ type }: AmendmentTitleProps) => ({
  fontWeight: type === 'active' ? '600' : '400',
  color: type === 'active' ? colors.secondaryGreen : colors.black,
}));

export const AmendmentTitle = styled('div')({
  fontWeight: '600',
});

export const SubtitleWrapper = styled('div')({
  marginTop: '8px',
  display: 'flex',
  flexDirection: 'row',
});

export const Subtitle = styled('div')({
  fontWeight: '500',
  width: '320px',
});

export const NoChangesText = styled('div')({
  fontWeight: '400',
});
