import { PageContainer, PageHeader } from 'components/base';
import FacilityForm from 'containers/Facilities/FacilityForm';

const NewFacility = () => {
  return (
    <PageContainer type="normal" style={{marginBottom: '66px'}}>
      <PageHeader pageTitle='Adding Facility'/>
      <FacilityForm />
    </PageContainer>
  );
};

export default NewFacility;
