import { TableCell, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
import { colors } from 'styles/globalStyles';

export const HeadTableCell = styled(TableCell)(() => ({
  background: colors.tableHeaderGray,
  fontSize: '12px',
  fontWeight: '600',
  padding: '12px 15px',
  lineHeight: 'normal',
  borderColor: colors.tableBorderGray,
}));

export const HeadTableRow = styled(TableRow)(() => ({
  position: 'sticky',
  top: '20px',
  zIndex: 1,
}));

export const BodyTableRow = styled(TableRow, {
  shouldForwardProp: (propName) => propName !== 'rowsAsLinks',
})(({ rowsAsLinks }: {rowsAsLinks?: boolean}) => ({
  '&.Mui-selected': {
    background: colors.hoveredBackgroundBlue,
    '&:hover': {
      backgroundColor: colors.focusHoverBlue,
    },
  },
  '&:hover': {
    backgroundColor: colors.backgroundGray,
    cursor: rowsAsLinks ? 'pointer' : 'inherit',
    '& .table-actions': {
      display: 'block',
    },
  },
}));

export const BodyTableCell = styled(TableCell)(() => ({
  borderColor: colors.tableBorderGray,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  padding: '12px 15px',
  height: '30px',
  textAlign: 'left',
}));

export const Actions = styled('div')({
  display: 'none',
});

export const NoOverflowBodyTableCell = styled(TableCell)({
  borderColor: colors.tableBorderGray,
  padding: '12px 15px',
  height: '30px',
  textAlign: 'left',
});

export const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',

  '.MuiTableContainer-root:not(:last-child)': {
    height: '42px !important',
  },
});
