import { StatusType } from 'types';
import { StyledStatusLabel } from './styledStatusLabelBlocks';

interface Props {
  status: StatusType;
}

const StatusLabel = ({ status }: Props) => {
  return (
    <StyledStatusLabel status={status}>{status}</StyledStatusLabel>
  );
};

export default StatusLabel;
