import { FormEvent, useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import dayjs, { Dayjs } from 'dayjs';

import { ClinicianContractAPI } from 'api/ClinicianContractAPI';

import { IPayrollDetails } from 'interfaces/ClinicianContract/IPayrollDetails';
import { IContractedHour } from 'interfaces/CompensationGrid/IContractedHour';
import { IClinicianContractDetails } from 'interfaces/ClinicianContract/IClinicianContractDetails';
import { ContractEngagementType, ContractType } from 'types';

import { SectionContainer, DetailsContainer, DetailsWrapper, InputFieldsWrapper, ActionsBar, ChangesContainer } from 'components/base';
import { DatePicker, Input, OutlinedButton, Select, SubmitButton } from 'components/UIComponents';

import { contractTypeOptions } from 'constants/contractsConstants';
import { amendmentTypes } from 'constants/dropdownOptions';
import { amendmentValidationSchema } from 'helpers/validation/amendmentValidationSchema';
import { calculatedAnnualRateValue, calculatedContractedHoursValue } from 'helpers/ClinicianConracts/applyGridTerms';
import handleFormSubmitResponse from 'helpers/handleFormSubmitResponse';
import getActiveAmendment from 'helpers/ClinicianConracts/getActiveAmendment';

import useData from 'hooks/useData';
import { useToast } from 'context/ToastContext';

import amendmentObjectsDiff from 'helpers/ClinicianConracts/amendmentObjectsDiff';
import { payrollDetailsNamesMapper } from 'constants/payrollDetailsNamesMapper';
import RESPONSE_CODES from 'constants/responseCodes';
import ToastType from 'constants/enums/ToastType';

import PayrollDetailsSubform from 'containers/ClinicianContracts/ClinicianContractForm/PayrollDetailsSubform';

const initialFormInformation: IPayrollDetails = {
  engagement: '',
  fte: '',
  amendment_notes: '',
  execution_date: '',
  effective_date: '',
  contract_term: '',
  staff_category: '',
  contracted_hours: '',
  weeks_of_service: '',
  paid_time_off: '',
  annual_rate: '',
  hourly_rate: '',
  notes: '',
  contracted_frequency: '',
  payroll_frequency: '',
  malpractice_deduction: '',
  type: 'Legal Amendment',
};

const AmendmentForm = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { onToastOpen } = useToast();
  const { clinicianContractId, amendmentId } = useParams();
  const clinicianContract: IClinicianContractDetails = useData(ClinicianContractAPI.getDetails, clinicianContractId);
  const clinician = clinicianContract?.clinician;
  const selectedContractedHour: IContractedHour = clinicianContract?.compensation_scale.contracted_hour as IContractedHour;

  const [activeAmendment, setActiveAmendment] = useState<IPayrollDetails>(initialFormInformation);
  const [previousActiveAmendment, setPreviousActiveAmendment] = useState<IPayrollDetails | undefined>(undefined);
  const [amendentFormInformation, setAmendentFormInformation] = useState<{payroll_details: IPayrollDetails}>({
    payroll_details: initialFormInformation,
  });
  const [minimalAvailableEffectiveDate, setMinimalAvailableEffectiveDate] = useState(dayjs());

  useEffect(() => {
    const initialize = async () => {
      if (!amendmentId) {
        const response = await ClinicianContractAPI.checkAmendmentCreateAvailable(Number(clinicianContractId));
        if (response.status === RESPONSE_CODES.UNPROCESSABLE_ENTITY) {
          onToastOpen(response.data.error.details, response.data.error.details, ToastType.error);
          return navigate('/clinician_contracts');
        };
      }
    };

    initialize();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(clinicianContract) {
      const active_amendment = getActiveAmendment(clinicianContract);
      setActiveAmendment({
        ...initialFormInformation,
        ...active_amendment,
      });
      setMinimalAvailableEffectiveDate(dayjs(active_amendment.effective_date).add(1, 'day'));
      if (!amendmentId) {
        setAmendentFormInformation({
          payroll_details: {
            ...initialFormInformation,
            ...active_amendment,
            effective_date: '',
            execution_date: '',
          },
        });
      } else {
        const updatingAmendment = clinicianContract.payroll_details.find(detail => detail.id === Number(amendmentId));
        if (updatingAmendment) {
          if(active_amendment.id === updatingAmendment.id) {
            const previous_active_amendment = clinicianContract.payroll_details
              .filter(detail => dayjs(detail.effective_date).isSameOrBefore(dayjs()) || detail.type === 'Initial contract')
              .sort((a, b) => dayjs(b.effective_date).diff(dayjs(a.effective_date)))[1];
            setMinimalAvailableEffectiveDate(dayjs(previous_active_amendment.effective_date).add(1, 'day'));
            setPreviousActiveAmendment(previous_active_amendment);
          }
          setAmendentFormInformation({
            payroll_details: {
              ...updatingAmendment,
              effective_date: dayjs(updatingAmendment.effective_date),
              execution_date: updatingAmendment.execution_date ? dayjs(updatingAmendment.execution_date) : '',
              fte: updatingAmendment.fte || '',
              contract_term: updatingAmendment.contract_term || '',
              staff_category: updatingAmendment.staff_category || '',
              contracted_hours: updatingAmendment.contracted_hours || '',
              weeks_of_service: updatingAmendment.weeks_of_service || '',
              paid_time_off: updatingAmendment.paid_time_off || '',
              annual_rate: updatingAmendment.annual_rate || '',
              hourly_rate: updatingAmendment.hourly_rate || '',
              notes: updatingAmendment.notes || '',
              contracted_frequency: updatingAmendment.contracted_frequency || '',
              payroll_frequency: updatingAmendment.payroll_frequency || '',
              malpractice_deduction: updatingAmendment.malpractice_deduction || '',
              amendment_notes: updatingAmendment.amendment_notes || '',
            },
          });
        };
      }
    };
  }, [clinicianContract]);

  if (!(clinicianContract && activeAmendment)) return null;

  return (
    <Formik
      initialValues={amendentFormInformation}
      enableReinitialize={true}
      validationSchema={() => amendmentValidationSchema(minimalAvailableEffectiveDate)}
      validateOnBlur
      onSubmit={async (values, { setFieldError }) => {
        let data = values.payroll_details;
        data.effective_date = (data.effective_date as Dayjs).format('YYYY-MM-DD');
        data.execution_date = data.execution_date ? (data.execution_date as Dayjs).format('YYYY-MM-DD') : '';
        delete data.id;
        let response;
        if (amendmentId) {
          response = await ClinicianContractAPI.updateAmendment(Number(clinicianContractId), Number(amendmentId), data);
        } else {
          response = await ClinicianContractAPI.createAmendment(Number(clinicianContractId), data);
        }
        handleFormSubmitResponse(response, setFieldError, onToastOpen, navigate, searchParams.get('redirectTo') as string);
      }}
    >
      {({ errors, handleSubmit, dirty, values, setFieldValue }) => (
        <Form>
          <SectionContainer title='Contract Info'>
            <DetailsWrapper>
              <DetailsContainer skipPermissions name='payroll_details.clinician' label='Clinician' width='wide'>
                {`${clinician.first_name} ${clinician.last_name}${clinician.maiden_name ?
                  ` (${clinician.maiden_name}),` :','} ${clinician.title}`}
              </DetailsContainer>
              <DetailsContainer skipPermissions name='payroll_details.facility' label='Facility' width='wide'>
                {clinicianContract.facility.name}
              </DetailsContainer>
              <DetailsContainer skipPermissions name='payroll_details.contract_type' label='Contract Type' width='wide'>
                {contractTypeOptions.find((option) => option.id === clinicianContract.contract_type)?.name}
              </DetailsContainer>
            </DetailsWrapper>
          </SectionContainer>
          <SectionContainer title='Amendment Details'>
            <InputFieldsWrapper>
              <DatePicker
                name='payroll_details.execution_date'
                label='Execution Date'
                width='extra-narrow'
                disableFuture={false}
                skipPermissionsCheck
              />
              <DatePicker
                name='payroll_details.effective_date'
                label='Effective Date'
                width='extra-narrow'
                disableFuture={false}
                skipPermissionsCheck
                required
              />
              <Select
                name='payroll_details.type'
                label='Type'
                options={amendmentTypes}
                allowEmpty={false}
                width='extra-narrow'
                skipPermissionsCheck
              />
              <Input
                name='payroll_details.amendment_notes'
                label='Notes'
                width='full-width'
                skipPermissionsCheck
              />
            </InputFieldsWrapper>
          </SectionContainer>
          <SectionContainer title='Contract Details'>
            <InputFieldsWrapper>
              <Select
                name='payroll_details.engagement'
                label='Engagement'
                options={values.payroll_details.engagement === 'per_diem' ?
                  [{ id: 'per_diem', name: 'Per-Diem'}] :
                  [{ id: 'full_time', name: 'Full-Time'}, { id: 'part_time', name: 'Part-Time'}]
                }
                required
                width='extra-narrow'
                skipPermissionsCheck
                onChange={(engagement: ContractEngagementType) => {
                  if (engagement === 'full_time') {
                    setFieldValue('payroll_details.contracted_hours',
                      calculatedContractedHoursValue(
                        selectedContractedHour as IContractedHour,
                        engagement,
                        Number(values.payroll_details.fte)
                      )
                    );
                    if (Number(activeAmendment.fte) > 0) {
                      setFieldValue('payroll_details.annual_rate',
                        calculatedAnnualRateValue(
                          String(Number(activeAmendment.annual_rate) / Number(activeAmendment.fte)),
                          engagement,
                          Number(values.payroll_details.fte)
                        )
                      );
                    } else {
                      setFieldValue('payroll_details.annual_rate',
                        calculatedAnnualRateValue(
                          activeAmendment.annual_rate,
                          engagement,
                          Number(values.payroll_details.fte)
                        )
                      );
                    }
                  }
                  setFieldValue('payroll_details.fte', '');
                }}
              />
              {values.payroll_details.engagement === 'part_time' &&
                <Input
                  name='payroll_details.fte'
                  label='FTE'
                  type='fte'
                  width='extra-narrow'
                  required
                  skipPermissionsCheck
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setFieldValue('payroll_details.contracted_hours',
                      calculatedContractedHoursValue(
                        selectedContractedHour as IContractedHour,
                        values.payroll_details.engagement,
                        Number(e.target.value)
                      )
                    );
                    if (Number(activeAmendment.fte) > 0) {
                      setFieldValue('payroll_details.annual_rate',
                        calculatedAnnualRateValue(
                          String(Number(activeAmendment.annual_rate) / Number(activeAmendment.fte)),
                          values.payroll_details.engagement,
                          Number(e.target.value)
                        )
                      );
                    } else {
                      setFieldValue('payroll_details.annual_rate',
                        calculatedAnnualRateValue(
                          activeAmendment.annual_rate,
                          values.payroll_details.engagement,
                          Number(e.target.value)
                        )
                      );
                    }
                  }}
                />
              }
              <Input
                name='payroll_details.contract_term'
                label='Contract Term, years'
                type='twoDigits'
                width='extra-narrow'
                skipPermissionsCheck
              />
            </InputFieldsWrapper>
          </SectionContainer>
          <PayrollDetailsSubform
            contract_type={clinicianContract.contract_type as ContractType}
            payroll_details={values.payroll_details}
            selectedContractedHour={selectedContractedHour}
          />
          <SectionContainer title='Change Preview'>
            <DetailsWrapper>
              {Object.entries(
                amendmentObjectsDiff(previousActiveAmendment || activeAmendment,
                  values.payroll_details, clinicianContract.contract_type, selectedContractedHour))
                .map(([key, values], index) => (
                  <ChangesContainer
                    type='amendmentForm'
                    key={index}
                    label={payrollDetailsNamesMapper[key as keyof typeof payrollDetailsNamesMapper]}
                    oldValue={values.oldValue}
                    newValue={values.newValue}
                  />
                ))
              }
            </DetailsWrapper>
          </SectionContainer>
          <ActionsBar>
            <OutlinedButton
              onClick={() => navigate(-1)}
            >
              Cancel
            </OutlinedButton>
            <SubmitButton
              disabled={!dirty || !!Object.values(errors).length}
              onClick={(values: FormEvent<HTMLFormElement>) => handleSubmit(values)}
            >
              Save Amendment
            </SubmitButton>
          </ActionsBar>
        </Form>
      )}
    </Formik>
  );
};

export default AmendmentForm;
