import { AxiosError } from 'axios';
import { ERRORS_PATHS } from 'constants/errors';
import RESPONSE_CODES from 'constants/responseCodes';

export const handleError = (error: AxiosError) => {
  const errorCode = error.response?.status;

  if (!errorCode || [RESPONSE_CODES.UNAUTHENTICATED, RESPONSE_CODES.UNPROCESSABLE_ENTITY].includes(errorCode)) {
    return;
  }

  window.location.href = ERRORS_PATHS[errorCode];
};
