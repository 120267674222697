import React from 'react';

import ReactDOM from 'react-dom/client';
import { ThemeProvider } from '@mui/material';
import * as Sentry from '@sentry/react';
import './styles/index.css';
import theme from 'styles/theme';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ToastState } from 'context/ToastContext';
import { ModalState } from 'context/ModalContext';
import { FieldsPermissionsContextState } from 'context/FieldsPermissionsContext';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from 'authConfig';
import { AuthProvider } from 'context/AuthContext';

if(process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY,
    integrations: [new Sentry.BrowserTracing()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <ModalState>
            <ToastState>
              <FieldsPermissionsContextState>
                <App />
              </FieldsPermissionsContextState>
            </ToastState>
          </ModalState>
        </AuthProvider>
      </ThemeProvider>
    </MsalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
