import { request } from '../instance';
import { IFacility } from 'interfaces/Facility/IFacility';
import { IFilterParams } from 'interfaces/IFilterParams';
import filterObjectKeys from 'helpers/filterObjectKeys';

const convertedphoneNumbers = (formData: IFacility) => {
  return {
    admin1_cell: formData.admin1_cell?.replace(/-/g, ''),
    admin1_office: formData.admin1_office?.replace(/-/g, ''),
    admin2_office: formData.admin2_office?.replace(/-/g, ''),
    admin2_cell: formData.admin2_cell?.replace(/-/g, ''),
    chief_md_cell: formData.chief_md_cell?.replace(/-/g, ''),
    assistant_chief_md_cell: formData.assistant_chief_md_cell?.replace(/-/g, ''),
    chief_crna_cell: formData.chief_crna_cell?.replace(/-/g, ''),
  };
};

export const FacilityAPI = {
  createFacility: async (formData: IFacility, updateAllowedFieldsList: Array<string>) => {
    try {
      const response = await request({
        url: 'v1/facilities',
        method: 'POST',
        data: {
          data: {
            facility: filterObjectKeys({ ...formData,
              ...convertedphoneNumbers(formData),
            }, updateAllowedFieldsList),
          },
        },
      });

      return response;
    } catch (error: any) {
      return error?.response;
    };
  },

  updateFacility: async (facilityId: number, formData: IFacility, updateAllowedFieldsList: Array<string>) => {
    try {
      const response = await request({
        url: `v1/facilities/${facilityId}`,
        method: 'PUT',
        data: {
          data: {
            facility: filterObjectKeys({ ...formData,
              ...convertedphoneNumbers(formData),
            }, updateAllowedFieldsList),
          },
        },
      });

      return response;
    } catch (error: any) {
      return error?.response;
    };
  },

  getAll: async (params: IFilterParams & { relation?: string }) => {
    const response = await request({
      url: 'v1/facilities',
      method: 'GET',
      params,
    });

    return response.data;
  },

  getDetails: async (facilityId: number) => {
    const response = await request({
      url: `v1/facilities/${facilityId}`,
      method: 'GET',
    });

    return response.data.data;
  },

  getAcronyms: async(facility_names?: string[], payroll_company_names?: string[]) => {
    const response = await request({
      url: 'v1/facilities/acronyms',
      method: 'GET',
      params: {
        facility_names,
        payroll_company_names,
      },
    });

    return response.data.data;
  },

  getNames: async(acronyms?: string[], payroll_company_names?: string[]) => {
    const response = await request({
      url: 'v1/facilities/names',
      method: 'GET',
      params: {
        acronyms,
        payroll_company_names,
      },
    });

    return response.data.data;
  },
};
