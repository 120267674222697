/* eslint-disable no-magic-numbers */
import { phoneRegex } from 'constants/fieldsLimits';

const formatPhone = (value: string): string => {
  if (/^[0-9]{10}$/.test(value)) {
    return `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6)}`;
  } else if (phoneRegex.test(value)) {
    return value;
  } else {
    return value.replace(/-/g, '');
  }
};

export default formatPhone;
