import dayjs from 'dayjs';
import { IClinicianContractDetails } from 'interfaces/ClinicianContract/IClinicianContractDetails';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { IPayrollDetails } from 'interfaces/ClinicianContract/IPayrollDetails';

const getActiveAmendment = (clinicianContract: IClinicianContractDetails) => {
  dayjs.extend(isSameOrBefore);

  const amendment = clinicianContract.payroll_details
    .filter(detail => dayjs(detail.effective_date).isSameOrBefore(dayjs()) || detail.type === 'Initial contract')
    .sort((a, b) => dayjs(b.effective_date).diff(dayjs(a.effective_date)))[0];

  return {
    id: amendment.id,
    engagement: amendment.engagement ,
    fte: amendment.fte || '',
    contract_term: amendment.contract_term || '',
    staff_category: amendment.staff_category || '',
    contracted_hours: amendment.contracted_hours || '',
    weeks_of_service: amendment.weeks_of_service || '',
    paid_time_off: amendment.paid_time_off || '',
    annual_rate: amendment.annual_rate || '',
    hourly_rate: amendment.hourly_rate || '',
    notes: amendment.notes || '',
    contracted_frequency: amendment.contracted_frequency || '',
    payroll_frequency: amendment.payroll_frequency || '',
    malpractice_deduction: amendment.malpractice_deduction || '',
    amendment_notes: amendment.amendment_notes || '',
    effective_date: dayjs(amendment.effective_date),
    execution_date: amendment.execution_date ? dayjs(amendment.execution_date) : '',
  } as IPayrollDetails;
};

export default getActiveAmendment;
