import { useState, useEffect } from 'react';
import { PageContainer, ListOfRecords } from 'components/base';
import { SubmitButton } from 'components/UIComponents';

import { ClinicianAPI } from 'api/ClinicianAPI';
import { PermissionsAPI } from 'api/PermissionsAPI';

import { ITableColumn } from 'interfaces/ITableColumn';
import { IClinician } from 'interfaces/Clinician/IClinician';

import formatPhone from 'helpers/formatPhone';
import columnsWithRecalculatedWidth from 'helpers/columnsWithRecalculatedWidth';
import useData from 'hooks/useData';
import useAuth from 'hooks/useAuth';

const ListOfClinicians = () => {
  const { user } = useAuth();
  const editAllowed = user?.allowed_write_resources.includes('clinicians');
  const fieldsPermissionsData = useData(PermissionsAPI.getAllowedAttributes, 'Clinician');

  const [columns, setColumns] = useState<ITableColumn[]>([
    { dataField: 'payroll_id', text: 'Payroll ID', headStyle: { width: '8%' }},
    { dataField: 'first_name', text: 'First Name', headStyle: { width: '13%' }},
    { dataField: 'last_name', text: 'Last Name', headStyle: { width: '15.6%' } },
    { dataField: 'title', text: 'Title', headStyle: { width: '5.2%' } },
    { dataField: 'office', text: 'Office', headStyle: { width: '9.7%' } },
    { dataField: 'work_email', text: 'Work Email', headStyle: { width: '19.3%' } },
    { dataField: 'cell', text: 'Cell', headStyle: { width: '9.7%' } },
    { dataField: 'personal_email', text: 'Personal Email', headStyle: { width: '19.3%' } },
  ]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (fieldsPermissionsData) {
      setColumns(columnsWithRecalculatedWidth(columns, fieldsPermissionsData.read_allowed));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldsPermissionsData]);

  const dataFormatter = (item: IClinician) => ({
    id: item.id,
    payroll_id: item.payroll_id,
    first_name: item.first_name,
    last_name: item.last_name,
    title: item.title,
    office: formatPhone(item.office || ''),
    work_email: item.work_email,
    cell: formatPhone(item.cell || ''),
    personal_email: item.personal_email,
  });

  return(
    <PageContainer type="wide" style={{marginBottom: '20px'}}>
      <ListOfRecords
        tableProps={{
          name: 'clinicians',
          columns: columns as ITableColumn[],
          editAction: editAllowed,
          withActions: editAllowed,
          rowsAsLinks: true,
        }}
        pageTitle='Clinicians'
        barActions={editAllowed && <SubmitButton href='/clinicians/new?redirectTo=/clinicians'>Add Clinician</SubmitButton>}
        dataFormatter={dataFormatter}
        dataProvider={ClinicianAPI.getAll}
        searchProps={{
          allowSearch: true,
          search: search,
          setSearch: setSearch,
        }}
      />
    </PageContainer>
  );
};

export default ListOfClinicians;
