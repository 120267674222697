import { styled } from '@mui/material/styles';
import { colors } from 'styles/globalStyles';

export const StyledContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '60px',
});;

export const StyledBox = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  fontSize: '16px',
  fontWeight: 400,
  color: colors.black,
});

export const SearchIcon = styled('img')({
  width: '83px',
  height: '83px',
  color: colors.primaryBlue,
  marginBottom: '10px',
});

export const HighlightedText = styled('span')({
  fontWeight: 600,
  fontSize: '16px',
  color: colors.primaryBlue,
});

export const StyledQuotation = styled('span')({
  fontWeight: 600,
  fontSize: '16px',
});
