import { RenderLeafProps } from 'slate-react';

enum LeafOptions {
  bold = 'bold',
  code = 'code',
  italic = 'italic',
  underline = 'underline',
}

const Leaf = ({ attributes, children, leaf }: RenderLeafProps) => {
  let element = children;
  const convertedLeaf = leaf as unknown as { [key in LeafOptions]: boolean };
  const codeStyle = {
    fontFamily: 'monospace',
    backgroundColor: '#eee',
    padding: '3px',
  };
  if (convertedLeaf.bold) {
    element = <strong>{element}</strong>;
  }

  if (convertedLeaf.code) {
    element = <code style={codeStyle}>{element}</code>;
  }

  if (convertedLeaf.italic) {
    element = <em>{element}</em>;
  }

  if (convertedLeaf.underline) {
    element = <u>{element}</u>;
  }

  return <span {...attributes}>{element}</span>;
};

export default Leaf;
