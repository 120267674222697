import { styled } from '@mui/material/styles';
import { colors } from 'styles/globalStyles';

export const SidebarContainer = styled('div')(({ visible }: { visible: boolean }) => ({
  width: visible ? '50%' : '0',
  overflowX: 'hidden',
  overflowY: 'hidden',
  marginLeft: visible ? '20px' : '0',
  border: visible ? `1px solid ${colors.tableHeaderGray}` : '0',
  transition: 'width 0.5s ease',
  display: 'flex',
  flexDirection: 'column',
}));

export const SidebarHeader = styled('div')({
  backgroundColor: colors.tableHeaderGray,
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '9px 16px',
  boxSizing: 'border-box',
  alignItems: 'center',
  position: 'sticky',
});

export const SidebarTitle = styled('div')({
  fontSize: '16px',
  fontWeight: '500',
});

export const ContentContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '30px',
  marginLeft: '16px',
  paddingTop: '10px',
  paddingBottom: '10px',
  overflowY: 'auto',
  boxSizing: 'border-box',
});
