import { Typography } from '@mui/material';
import { StyledSectionContainer } from './styledComponents';

interface Props {
  title: string;
  children: React.ReactNode;
}

const SectionContainer = ({ title, children }: Props) => {
  return (
    <StyledSectionContainer>
      <Typography variant='h2'>{title}</Typography>
      {children}
    </StyledSectionContainer>
  );
};

export default SectionContainer;
