import { useFieldsPermissions } from 'context/FieldsPermissionsContext';
import {
  StyledDetailsContainer,
  PropertyName,
  PropertyValue,
} from './styledComponents';

interface ItalicContainerProps {
  name: string;
  children: React.ReactNode;
  width?: 'normal' | 'wide';
  skipPermissions?: boolean;
}

const ItalicContainer = ({
  name,
  children,
  width = 'normal',
  skipPermissions = false,
}: ItalicContainerProps) => {
  const { fieldsPermissions } = useFieldsPermissions();
  return (
    <>
      {(skipPermissions || fieldsPermissions.read_allowed.includes(name)) && (
        <StyledDetailsContainer>
          <PropertyName width={width}>Notes</PropertyName>
          <PropertyValue data-testid={name}>{children}</PropertyValue>
        </StyledDetailsContainer>
      )}
    </>
  );
};

export default ItalicContainer;
