import { CompensationGridAPI } from 'api/CompensationGridAPI';
import { IClinician } from 'interfaces/Clinician/IClinician';
import { ISelectOption } from 'interfaces/ISelectOption';
import { ClinicianTitleType, ContractEngagementType, ContractType } from 'types';
import {
  contractEngagementTypeOptions,
  contractEngagementToCompensationGridEngagementMapper,
  clinicianTitleToCompensationGridClinicianTitleMapper,
} from 'constants/contractsConstants';
import { clinicianTitleOptions, contractTypeOptions } from 'constants/compensationGridsConstants';

const findCompensationGrid = async (
  clinician_id: number | '',
  facility_id: number | '',
  contract_type: ContractType | '',
  engagement: ContractEngagementType | '',
  setFieldTouched: any,
  setFieldValue: any,
  clinicians: IClinician[],
  setCompensationGrid: any,
  facilitiesOptions: ISelectOption[],
) => {
  if (facility_id && clinician_id && contract_type && engagement) {
    const clinician_title = clinicians.find((clinician: IClinician) => clinician.id === clinician_id)?.title as ClinicianTitleType;

    const response = await CompensationGridAPI.findGrid({
      facility_id,
      contract_type,
      clinician_title: clinicianTitleToCompensationGridClinicianTitleMapper[clinician_title],
      engagement: contractEngagementToCompensationGridEngagementMapper[engagement] });
    if (Object.keys(response).length === 0) {
      setFieldTouched('compensation_grid_name', true);
      setFieldValue('compensation_grid_name', '', true);
    } else {
      setCompensationGrid(response);
      const facilityName = facilitiesOptions.find(facility => facility.id === facility_id)?.name;
      const clinicianTitleOption = clinicianTitleOptions.find(option => option.id === response.clinician_title)?.name;
      const contractTypeOption = contractTypeOptions.find(option => option.id === response.contract_type)?.name;
      const engagementOption = contractEngagementTypeOptions.find(option => option.id === response.engagement)?.name;
      setFieldValue('compensation_grid_name', `${facilityName}, ${clinicianTitleOption}, ${contractTypeOption}, ${engagementOption}`);
    }
  };
};

export default findCompensationGrid;
