import { styled } from '@mui/material/styles';
import { colors } from 'styles/globalStyles';

export const TitleWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

export const TitleContainter = styled('div')({
  fontWeight: 500,
  fontSize: '14px',
  color: colors.black,
});

export const CompScaleWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '30px',
});

export const CompRateWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
});

export const ListWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '18px',
});

export const SectionName = styled('div')({
  fontSize: '18px',
  fontWeight: '600',
  marginBottom: '6px',
});
