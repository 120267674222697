import { useContext, useEffect, useState } from 'react';
import { FieldArray, Form, Formik } from 'formik';
import { cloneDeep } from 'lodash';

import { ClinicianContractAPI } from 'api/ClinicianContractAPI';

import useAuth from 'hooks/useAuth';
import { CustomIcon, SearchField, SubmitButton, TextButton } from 'components/UIComponents';
import { PageContainer, PageHeader } from 'components/base';
import { ModalContext } from 'context/ModalContext';
import { reportModalTitle } from 'constants/contractsConstants';
import { filteringOptions as initialFilteringOptions } from 'constants/dropdownOptions';
import {
  requestValuesDependingOnOption,
  dynamicFilteringOptions,
  initialFormValues,
  prepareFilterQuery,
} from 'helpers/ClinicianConracts/filters';

import { IClinicianContractFilterQueryParams } from 'interfaces/ClinicianContract/IClinicianContractFilterQueryParams';
import { IClinicianContractListItem } from 'interfaces/ClinicianContract/IClinicianContractListItem';
import { IClinicianContractFilterFormParams } from 'interfaces/ClinicianContract/IClinicianContractFilterFormParams';

import { ISelectOption } from 'interfaces/ISelectOption';


import Filter from './Filter';
import ReportModal from './ReportModal';
import FilterChips from './FilterChips';
import TableWithSidebar from './TableWithSidebar';

import { TableWrapper, TotalCountBlock, PageWrapper } from './styledComponents';

const perPage = 100;
const defaultPage = 1;

interface DataResponse {
  data: {
    objects: any[];
    total_count: number;
  };
}

const ListOfClinicianContracts = () => {
  const { user } = useAuth();
  const { onModalOpen } = useContext(ModalContext);

  const [rowData, setRowData] = useState<IClinicianContractListItem[]>([]);
  const [search, setSearch] = useState('');
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState<number | undefined>(undefined);
  const [initialized, setInitialized] = useState(false);

  const [filterQuery, setFilterQuery] = useState<IClinicianContractFilterQueryParams>({});
  const [chipsValues, setChipsValues] = useState<IClinicianContractFilterFormParams>(initialFormValues);
  const [valuesOptions, setValuesOptions] = useState<Array<ISelectOption[]>>([[]]);
  const [filteringOptions, setFilteringOptions] = useState<ISelectOption[]>(initialFilteringOptions);
  const [conditionOptions, setConditionOptions] = useState<Array<ISelectOption[]>>([[]]);

  const editAllowed = user?.allowed_write_resources.includes('clinician_contracts');

  const doSearch = (searchValue: string, searchCallback: () => void): void => {
    setSearch(searchValue);
    setRowData([]);
    setHasMore(true);
    setPage(defaultPage);
    getData({ searchValue, newPage: 1 }).then(() => {
      searchCallback();
    });
  };

  const loadMore = () => {
    if (hasMore) {
      getData({ newPage: page + 1 });
    }
  };

  const getData = ({ searchValue, newPage }: { searchValue?: string; newPage?: number }): Promise<void> => {
    if (loading) {
      return new Promise(() => {});
    }

    setLoading(true);
    const result = ClinicianContractAPI.getAll({
      search: searchValue == null ? search.trim() : searchValue.trim(),
      per_page: perPage,
      page: newPage || page,
      ...filterQuery,
    }).then((data: DataResponse) => {
      setCount(data.data.total_count);
      const items = data.data.objects;
      setHasMore(items.length === perPage);
      setRowData((prevState) => prevState.concat(items));
      setLoading(false);
      if (newPage) {
        setPage(newPage);
      }
    });
    return result;
  };

  const removeCondition = async (
    index: number, remove: Function, submitForm: Function, values: IClinicianContractFilterFormParams, setFieldValue: Function
  ) => {
    remove(index);
    let actualValues = cloneDeep(values);
    const removedCondition = actualValues.filters[index];
    actualValues.filters.splice(index, 1);
    const alreadySelectedOptions = actualValues.filters.map(filter => filter.filteringOption);

    let draftValuesOptions = cloneDeep(valuesOptions);
    let draftConditionOptions = cloneDeep(conditionOptions);
    draftValuesOptions.splice(index, 1);
    draftConditionOptions.splice(index, 1);
    const valuesToCheck = actualValues.filters.slice(index);
    for (const value of valuesToCheck) {
      if (dynamicFilteringOptions.includes(value.filteringOption) && dynamicFilteringOptions.includes(removedCondition.filteringOption)) {
        const filteringOptionIndex = actualValues.filters.findIndex((val) => val.filteringOption === value.filteringOption);
        await setFieldValue(`filters[${filteringOptionIndex}].value`, []);
        actualValues.filters[filteringOptionIndex].value = [];
      };
    }
    for (const value of valuesToCheck) {
      const filteringOptionIndex = actualValues.filters.findIndex((val) => val.filteringOption === value.filteringOption);
      setFieldValue(`filters[${filteringOptionIndex}].value`, value.value);
      if (dynamicFilteringOptions.includes(value.filteringOption)) {
        draftValuesOptions[filteringOptionIndex] = await requestValuesDependingOnOption(actualValues, value.filteringOption);
      };
    }
    setConditionOptions(draftConditionOptions);
    setValuesOptions(draftValuesOptions);
    setFilteringOptions((prevValue) =>
      prevValue.map(option => ({...option, disabled: alreadySelectedOptions.includes(option.id as string)}))
    );
    submitForm();
  };

  useEffect(() => {
    if (filterQuery && initialized) {
      setRowData([]);
      setHasMore(true);
      setPage(defaultPage);
      getData({searchValue: search, newPage: 1});
    }
    setInitialized(true);
  }, [filterQuery]);

  useEffect(() => {
    setRowData([]);
    getData({ newPage: defaultPage });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <PageContainer type="wide" style={{ marginBottom: '20px' }}>
      <PageWrapper>
        <Formik
          initialValues={initialFormValues}
          enableReinitialize={true}
          onSubmit={(values) => {
            if (JSON.stringify(prepareFilterQuery(values)) !== JSON.stringify(filterQuery)) {
              setFilterQuery(prepareFilterQuery(values));
            }
            setChipsValues(values);
          }}
        >
          {() => (
            <Form>
              <FieldArray name='filters'>
                {({ remove, push }) => (
                  <>
                    <PageHeader pageTitle='Payroll Data' isSticky={false}>
                      <>
                        <SearchField onChange={doSearch}/>
                        <div style={{ marginRight: '25px' }}>
                          <Filter
                            filteringOptions={filteringOptions}
                            setFilteringOptions={setFilteringOptions}
                            conditionOptions={conditionOptions}
                            setConditionOptions={setConditionOptions}
                            valuesOptions={valuesOptions}
                            setValuesOptions={setValuesOptions}
                            removeCondition={removeCondition}
                            setChipsValues={setChipsValues}
                            removeItem={remove}
                            pushItem={push}
                          />
                        </div>
                        <TextButton
                          color="blue"
                          onClick={() => onModalOpen(reportModalTitle)}
                        >
                          <CustomIcon size='normal' name='clipboard'/>
                          Create Report
                        </TextButton>
                        { editAllowed &&
                          <SubmitButton href="/clinician_contracts/new?redirectTo=/clinician_contracts">
                            Add Contract
                          </SubmitButton>
                        }
                      </>
                    </PageHeader>
                    <FilterChips
                      chipsValues={chipsValues}
                      removeCondition={removeCondition}
                      formikRemove={remove}
                    />
                  </>
                )}
              </FieldArray>
            </Form>
          )}
        </Formik>
        <TableWrapper>
          <TableWithSidebar
            loadMore={loadMore}
            data={rowData}
            search={search}
            loading={loading}
          />
          <TotalCountBlock>
            {`Total: ${count ? `${count} Clinician ${count === 1 ? 'Contract' : 'Contracts'}`: ''}`}
          </TotalCountBlock>
        </TableWrapper>
      </PageWrapper>
      <ReportModal
        search={search}
        filterQuery={filterQuery}
      />
    </PageContainer>
  );
};

export default ListOfClinicianContracts;
